import {useTranslation} from "react-i18next";

import React, {useEffect, useState} from "react";
import {Button, Modal, Tooltip} from "flowbite-react";
import GoogleMapReact from "google-map-react";
import PlaceholderImg from "../../asset/img/placeholder.png";
import LocationImg from "../../asset/img/location.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink, faLocationDot, faMap, faPercent, faPhone, faStar} from "@fortawesome/free-solid-svg-icons";

import iconHouse from '../../asset/iconhouse.svg';
export default function ModalTopic(props) {

    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (props.topic === undefined) return;
        setOpenModal(true);
    }, [props.topic])

    const closeModal =(event) => {
        setOpenModal(false);
        props.setTopicSelected(null);
    }

    const Placeholder = ({ text }) => <img src={PlaceholderImg} style={{ width:32, height: 'auto' , transform: "translate(-50%, -100%)",  position: "absolute"}} />;
    const Location = ({ text }) => <img src={iconHouse} style={{ width:32, height: 'auto' , transform: "translate(-50%, -100%)",  position: "absolute"}} />;

    return (
        props.topic?.uuid !== undefined ?
            <Modal show={openModal}  size={"lg"}  onClose={closeModal} key={props.topic?.uuid}>
                <Modal.Header >
                    <div className={"flex flex-row gap-5 items-center"}>
                        {props.topic?.sub_category_id > 0 ?
                            <>
                                <div className={"rounded p-1 text-xs"}
                                     style={{
                                         backgroundColor:props.guideBook.settings.primaryColor,
                                         color: props.guideBook.settings.secondaryColor
                                     }}
                                >
                                    <i className={props.topic.subCategorys.icon} />
                                </div>

                            </>
                            : <></>}
                        <h1 className={"lowercase first-letter:uppercase text-gray-700 font-semibold"}>{props.topic?.name}</h1>
                    </div>
                    <div className={"text-gray-500 text-sm first-letter:uppercase"}>{props.topic?.subCategorys?.name}</div>
                    <div className={"mt-2"}>
                        {props.topic?.tags.length >0 ?
                            <div className={"flex flex-row flex-wrap gap-2"}>
                                {props.topic?.tags.map((tag) =>

                                    <div className={"text-xs flex flex-row gap-2 items-center rounded p-1"}
                                    style={{
                                        backgroundColor: props.guideBook.settings.primaryColor
                                    }}
                                    >
                                        <i className={tag.icon}  style={{
                                            color: props.guideBook.settings.secondaryColor
                                        }}/>
                                        <span className={"first-letter:uppercase"} style={{
                                            color: props.guideBook.settings.secondaryColor
                                        }}>{tag.name}</span>
                                    </div>

                                )}
                            </div>
                            : <></>
                        }
                    </div>

                </Modal.Header>
                <Modal.Body>
                    <div className="space-y-6" >
                        <div className={"flex flex-col gap-3"}>
                            {props.topic?.distance > 0 ?
                                <div className={"flex flex-row gap-2 text-xs items-center"}>
                                    <FontAwesomeIcon icon={faLocationDot} style={{
                                        color: props.guideBook.settings.secondaryColor
                                    }}/>
                                    <div className={"text-gray-500"}>{props.topic?.distance/1000} km</div>
                                </div>
                                : <></>}
                        </div>

                        {(props.topic?.phoneNumber !== undefined  && props.topic?.phoneNumber !== "") ?
                            <div className={"flex flex-row gap-2 items-center mt-2 text-xs" }>
                                <FontAwesomeIcon icon={faPhone}
                                                 style={{
                                                     color: props.guideBook.settings.secondaryColor
                                                 }}
                                />
                                <div className={"text-gray-600"}>{props.topic?.phoneNumber}</div>
                            </div>: <></> }

                        {props.topic?.address !== undefined ?
                            <div className={"flex flex-row gap-2 items-center mt-2 text-xs"}>
                                <FontAwesomeIcon icon={faLocationDot} style={{
                                    color: props.guideBook.settings.secondaryColor
                                }} />
                                <div className={"text-gray-600"}>{props.topic?.address}</div>
                            </div> : <></> }

                        {props.topic?.uri !== undefined || props.topic?.uri !== "" ?
                            <div className={"flex flex-row gap-2 items-center mt-2 text-xs"}>
                                <FontAwesomeIcon icon={faLink}  style={{
                                    color: props.guideBook.settings.secondaryColor
                                }}/>
                                <a href={props.topic?.uri}  target={"_blank"} className={"text-gray-600 underline"}>{props.topic?.uri}</a>
                            </div> : <></>}

                        {props.topic?.content !== "" ?
                            <p className="text-sm  text-gray-600  rounded  p-2" dangerouslySetInnerHTML={{__html: props.topic?.content}}></p>
                            :
                            <></>}

                        {props.topic?.hasDiscountCode === 1 ?
                            <div className={"rounded  p-5 text-xs border border-dashed"} style={{
                                borderColor: props.guideBook.settings.secondaryColor,
                                color: props.guideBook.settings.secondaryColor
                            }}>
                                {props.topic.discountCode.media?.url !== undefined ?
                                    <img src={global.config.uriAPI+"/"+props.topic.discountCode.media.url} className={"w-full rounded"} />
                                    : <></>}
                                <div dangerouslySetInnerHTML={{__html: props.topic.discountCode.name}}></div>
                            </div>
                            : <></>}
                        <div className={"columns-2 md:columns-2 gap-2 space-y-2"}>
                            {props.topic?.mediaLibrary?.map((media) =>
                                <img src={global.config.uriAPI+"/"+media.url} className={"w-full rounded"}  key={media.id}/>
                            )}
                        </div>
                        {props.topic?.media?.id !== undefined ?
                            <img src={global.config.uriAPI+"/"+props.topic.media.url} className={"w-full rounded"} />
                            : <></>}
                        {props.topic?.type === 'google_place' ?
                            <>
                                <a href={`https://www.google.com/maps/dir/?api=1&origin=${props.guideBook.latitude},${props.guideBook.longitude}&destination=${props.topic.latitude},${props.topic.longitude}`}
                                   className={"text-sm font-semibold"}
                                   style={{
                                       color: props.guideBook.settings.secondaryColor
                                   }}
                                   target={"_blank"}><FontAwesomeIcon icon={faMap} /> {t("Voir l'itinéraire")}</a>
                                <div style={{width:"100%",height:"250px"}} className={"rounded"}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: global.config.apiKeyGoogle }}
                                        center={[props.topic.latitude, props.topic.longitude]} zoom={11}
                                        className={"rounded"}
                                    >
                                        <Placeholder
                                            lat={props.topic.latitude}
                                            lng={props.topic.longitude}
                                            text="My Marker"
                                        />
                                        <Location
                                            lat={props.guideBook.latitude}
                                            lng={props.guideBook.longitude}
                                            text="My Marker"
                                        />
                                    </GoogleMapReact>
                                </div>
                            </>

                            : null }


                        <div  className={"flex flex-col gap-5 mt-5"}>
                            {props.topic?.contentOwner !== "" ?
                                <div className={"bg-gray-50 rounded p-2"}>
                                    <h3 className={"font-semibold text-sm flew items-center gap-2"} style={{
                                        color: props.guideBook.settings.secondaryColor
                                    }}><FontAwesomeIcon icon={faStar} /> {t("Conseils / Astuce de visite")}</h3>
                                    <p className=" leading-relaxed text-sm text-gray-600 mt-2" dangerouslySetInnerHTML={{__html: props.topic?.contentOwner}}></p>
                                </div>
                                :<></>
                            }
                        </div>
                    </div>
                </Modal.Body>

            </Modal>

            : <></>

    )
}