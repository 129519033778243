import Logo from '../asset/img/okurensiologo.svg';
import imgHero from '../asset/img/imghero3.png';
import airbnb from '../asset/img/airbnb.svg';
import booking from '../asset/img/bookingcom-1.svg';
import abritel from '../asset/img/abritel-fr-logo-vector.svg';
import Loginimg from '../asset/img/loginimg.png';
import { Form } from "react-router-dom";
import Error from "../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Card, Navbar} from 'flowbite-react';
import Lang from '../component/lang.jsx'
import  Meta  from "../component/Meta";

import DetectLang from "./detectLang";
import Img1 from '../asset/img/architecture-2260836_640.jpg';
import Img2 from '../asset/img/hot-air-balloons-4561267_640.jpg';
import Img3 from '../asset/img/language-2345801_640.jpg';
import Img4 from '../asset/img/qrcode.jpg';
import Demo1 from '../asset/img/demo1.png';
import Demo2 from '../asset/img/demo2.png';
import Visit from '../asset/img/visit.png';
import Coeur from '../asset/img/coeur.png';
import CookieConsent from "react-cookie-consent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRight, faBars,
    faCheck,
    faCircle,
    faCircleArrowRight, faCircleCheck, faFlag, faGaugeSimpleHigh, faGlobe,
    faInfo, faLeaf, faLocationDot, faMap, faQrcode,
    faQuestion,
    faTree, faUsers, faWarning
} from "@fortawesome/free-solid-svg-icons";

import mixpanel from "mixpanel-browser/src/loader-module";
import ReactPixel from 'react-facebook-pixel';
import { useLocation } from "react-router-dom"
import i18n from "../component/i18n";
import { usePostHog } from 'posthog-js/react'
import TextTruncate from "react-text-truncate";


export default function Root() {

    const { t } = useTranslation();
    const posthog = usePostHog()
    const trackAutomaticEvents = false;
    const lng = i18n.language;
    const font = {
        fontFamily: "'Noto Sans', sans-serif",
    }

    const advancedMatching = { }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    };

    const meta = {
        title: t('Livret Digital d\'accueil  un outil de bord incontournable'),
        description: t('Élevez l\'expérience de vos nouveaux arrivants à un autre niveau avec notre livret digital d\'accueil'),
        image: Demo1,
        font: "Noto+Sans:ital,wght@0,300;0,900;1,400"
    }

    const [langages, setLangage] = useState([]);
    const [article, setArticle] = useState(null);
    useEffect(() => {


           var sc = document.createElement("script");
            sc.setAttribute("defer",true);
            sc.setAttribute("src","https://dbwx2z9xa7qt9.cloudfront.net/bundle.js?cachebust=1707741612420");
            sc.setAttribute("theme","dark");
            sc.setAttribute("footer", "false");
            sc.setAttribute("widget-type","carousel");
            sc.setAttribute("token","65b892009e2a72f323300085");
            sc.setAttribute('apiurl', "https://server.onlinereviews.tech/api/v0.0.9");
            sc.setAttribute('stats',"true");
            sc.setAttribute('addReview',"false");
            sc.setAttribute('profile-pic',"true");
            sc.setAttribute('review-name',"true");
            sc.setAttribute('wl', "true");
            sc.setAttribute('wlndig', "https://go.gestion-avis.fr/okurensio");
            sc.setAttribute('lang', "us");
            sc.setAttribute('brandStyle', "%7B%22sidebar_background%22%3A%22%23004AAD%22%2C%22sidebar_text%22%3A%22white%22%2C%22brand_button_text_color%22%3A%22white%22%2C%22brand_main_color%22%3A%22%23000000%22%2C%22brand_button_border_radius%22%3A%2220px%22%2C%22brand_button_hover%22%3A%22rgb(0%2C%200%2C%200)%22%2C%22brand_button_active%22%3A%22rgb(0%2C%200%2C%200)%22%2C%22brand_main_color_opacity%22%3A%22%230000001a%22%2C%22brand_font%22%3A%22Montserrat%22%2C%22star_color%22%3A%22%23128c7e%22%2C%22brand_main_background%22%3A%22%23F6F8F7%22%2C%22brand_card_background%22%3A%22white%22%2C%22poweredByLink%22%3A%22https%3A%2F%2Fgestion-avis.fr%22%2C%22poweredicon%22%3A%22https%3A%2F%2Frecensioni-io-static-folder.s3.eu-central-1.amazonaws.com%2Fpublic_onlinereviews%2Fapp.gestion-avis.fr%2Ftopbar.png%22%7D");
           // document.getElementById("wid_1707741612420").appendChild(sc);


        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+global.config.strapi)

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(global.config.uriStrapi+'/articles?locale='+lng+'&populate=*&sort[1]=id:Desc&pagination[page]=1&pagination[pageSize]=4', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setArticle(data.data);

            })
            .catch((err) => {
            });


        fetch(global.config.uriAPI+'/langage')
            .then((response) => response.json())
            .then((data) => {
                setLangage(data);
            })
            .catch((err) => {
            });

        posthog.capture(
            'front page accueil',
            {
                $set: { referer: document.referrer }
            }
        )
    }, [lng]);

    const openMobileMenu = () => {
        const mobileMenu = document.getElementById('mobile-menu-2');
        if (mobileMenu.style.display === 'block') {
            mobileMenu.style.display = 'none';
        } else {
            mobileMenu.style.display = 'block';
        }
    }

    return (
    <>
        <header>
            <nav class="bg-white border-gray-200 dark:bg-gray-900 fixed w-full shadow">
                <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 ">
                    <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                        <img src={Logo} className="h-8" alt="okurensio Logo"/>
                        <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white text-gray-700">Okurensio</span>
                    </a>
                    <button data-collapse-toggle="mobile-menu-2" type="button" onClick={openMobileMenu}
                            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                            aria-controls="navbar-default" aria-expanded="false">
                        <span class="sr-only">Open main menu</span>
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                    <div class="hidden w-full md:block md:w-auto" id="mobile-menu-2">
                        <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                            <li>
                                <a href="/"
                                   className="block py-2 px-3 text-white bg-cyan-700 rounded md:bg-transparent md:text-cyan-700 md:p-0 dark:text-white md:dark:text-cyan-500"
                                   aria-current="page">{t('Accueil')}</a>
                            </li>
                            <li>
                                <a href="/tarif"
                                   className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cyan-700 md:p-0 dark:text-white md:dark:hover:text-cyan-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">{t('Tarif')}</a>
                            </li>
                            <li>
                                <a href="/login"
                                   className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cyan-700 md:p-0 dark:text-white md:dark:hover:text-cyan-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">{t('Mon compte')}</a>
                            </li>
                            <li>
                                <a href="/register"
                                   className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cyan-700 md:p-0 dark:text-white md:dark:hover:text-cyan-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">{t('Inscription')}</a>
                            </li>
                            <li>
                                <a href="/articles"
                                   className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cyan-700 md:p-0 dark:text-white md:dark:hover:text-cyan-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">{t('Guides')}</a>
                            </li>
                            <li>
                                <Lang/>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>

        <Meta meta={meta} />
        <DetectLang />



        <section class="bg-white dark:bg-gray-900  min-h-screen  justify-center items-center flex flex-col gap-5" >
            <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
                <div class="mr-auto place-self-center lg:col-span-7 flex flex-col justify-start gap-5 ">
                    <h1 class="mt-20 max-w-2xl mb-4 text-xl font-extrabold tracking-tight leading-none  lg:text-4xl text-gray-800 text-left flex flex-col gap-5">
                        <span>{t('Moins de questions, plus de satisfactions')}</span>
                        <span class={"text-cyan-700"}>{t('Offrez un accueil 5 étoiles grâce à votre livret digital personnalisé !')}</span></h1>

                    <div className={"flex  items-center gap-2 text-lg"}><FontAwesomeIcon icon={faCircleCheck} className={"text-cyan-600"}/> <span>{t('Gagnez du temps')}</span></div>
                        <div className={"flex  items-center gap-2 text-lg"}><FontAwesomeIcon icon={faCircleCheck} className={"text-cyan-600"}/> <span>{t('Améliorez l\'expérience des voyageurs')}</span></div>
                    <div className={"flex  items-center gap-2 text-lg"}><FontAwesomeIcon icon={faCircleCheck} className={"text-cyan-600"}/> <span>{t('Simplifié votre communication')}</span></div>

                    <div className={"flex justify-center items-center mt-5 flex-row flex-wrap gap-2"}>
                        <a href="/register"
                           className="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-lg text-gray-700 bg-gray-100 rounded-full dark:bg-gray-800 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
                           role="alert">
                            <span class="text-lg bg-cyan-600 rounded-full text-white px-4 py-1.5 mr-3">{t('Nouveaux')}</span>
                            <span class="text-xl font-medium">{t('Créer mon livret')}</span>
                            <svg class="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                      clip-rule="evenodd"></path>
                            </svg>
                        </a>
                        <a href="https://okurens.io/guidebook/ecc6e646-9628-443b-ba53-50d9e90c8c61" target={"_blank"}
                           className="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-lg text-gray-700 bg-gray-100 rounded-full dark:bg-gray-800 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
                           role="alert">
                            <span class="text-lg font-medium px-4 py-1.5 mr-3">{t('Démo')}</span>
                            <svg class="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                      clip-rule="evenodd"></path>
                            </svg>
                        </a>
                    </div>
                    <div className={"flex flex-col  gap-1  justify-center items-stretch mt-5"}>
                        <div className={"flex items-center justify-center text-gray-600"}>
                            {t('Compatible avec tous les plateformes de location')}
                        </div>
                        <div className={"flex flex-row flex-wrap gap-6  justify-center items-stretch mt-5"}>
                            <img src={airbnb}  alt={t("airbnb livret accueil numerique")}  width={"20%"}/>
                            <img src={booking} alt={t("booking livret accueil numerique")} width={"10%"}/>
                            <img src={abritel} alt={t("abritel livret accueil numerique")} width={"15%"}/>
                        </div>

                    </div>

                </div>
                <div class="hidden lg:mt-0 lg:col-span-5 lg:flex items-center justify-center">
                    <img src={imgHero} alt={t("okurensio livet accueil digital")}  className={""}/>
                </div>
            </div>
            <div >
                <section class="bg-white dark:bg-gray-900">
                    <div class="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
                        <dl class="grid max-w-screen-md gap-8 mx-auto text-gray-900 sm:grid-cols-3 dark:text-white">
                            <div class="flex items-center justify-center gap-5">
                                <div class="relative size-[50px] shrink-0">
                                    <svg class="w-full h-full stroke-gray-300 fill-gray-100" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
			18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
			39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                        </path>
                                        <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
			18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
			39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                        </path>
                                    </svg>
                                    <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4">
                                        <FontAwesomeIcon icon={faUsers} className={"text-gray-500"}/>
                                    </div>
                                </div>
                                <div>
                                    <dt class="text-lg font-light text-gray-700">+ 300 </dt>
                                    <dd class="font-light text-gray-700 dark:text-gray-400">{t('livrets')}</dd>
                                </div>

                            </div>
                            <div class="flex  items-center justify-center gap-5">
                                <div class="relative size-[50px] shrink-0">
                                    <svg class="w-full h-full stroke-gray-300 fill-gray-100" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
			18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
			39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                        </path>
                                        <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
			18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
			39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                        </path>
                                    </svg>
                                    <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4">
                                        <FontAwesomeIcon icon={faGlobe} className={"text-gray-500"}/>
                                    </div>
                                </div>
                                <div>
                                    <dt class="text-lg font-light text-gray-700">+10 000</dt>
                                    <dd class="font-light text-gray-700 dark:text-gray-400">{t('Voyageurs')}</dd>
                                </div>

                            </div>
                            <div class="flex  items-center justify-center gap-5">
                                <div class="relative size-[50px] shrink-0">
                                    <svg class="w-full h-full stroke-gray-300 fill-gray-100" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
			18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
			39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                        </path>
                                        <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
			18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
			39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                        </path>
                                    </svg>
                                    <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4">
                                        <FontAwesomeIcon icon={faLocationDot} className={"text-gray-500"}/>
                                    </div>
                                </div>
                                <div>
                                    <dt class="text-lg font-light text-gray-700">+41 000</dt>
                                    <dd class="font-light text-gray-700 dark:text-gray-400">{t('Lieux partagés')}</dd>
                                </div>

                            </div>
                        </dl>
                    </div>
                </section>
            </div>
        </section>
    <section  className="bg-hray-100  flex flex-col  m-0 container mx-auto ">
            <div
                className="flex rounded-2xl  p-5 items-center flex-col container mt-10 mb-10">
                <div className={""}>
                    <div className="flex flex-row flex-wrap  jmt-10  md:flex-nowrap lg:flex-nowrap">
                        <div className={"items-center flex justify-center"}>
                            <img src={Visit}  className={"rounded rounded "} />
                        </div>
                        <div className={"flex flex-col justify-start items-start  p-5 gap-4"}>
                            <div>
                                <h2 style={{
                                    fontFamily: "'Noto Sans', sans-serif",
                                    fontWeight: 900
                                }}  className={"font-bold m-5 text-2xl text-gray-700"}>{t('Transformez votre accueil en une expérience inoubliable grâce à notre plateforme ! En quelques clics ')}</h2>
                                <div style={{
                                    fontWeight: 400
                                }} className={"text-justify m-5 text-xl text-gray-600 flex flex-col gap-2"}>
                                    <span class={"font-extrabold mb-2"}>{t('Diffusez facilement votre livret d\'accueil')}</span>
                                    <span>{t('Envoyez un lien unique à vos invités avant leur arrivée')}. <br /> {t('Pas besoin d\'imprimer ou de jongler avec des fichiers compliqués')}</span>

                                </div>
                                <div style={{
                                    fontWeight: 400
                                }} className={"text-justify m-5 text-xl text-gray-600 flex flex-col gap-2"}>
                                    <span class={"font-extrabold mt-2"}>{t('Offrez un accès instantané aux informations clés ')} </span>
                                    <span>{t('Votre livret d\'accueil rassemble tout ce qu’il faut – depuis les consignes de la maison jusqu\'aux meilleures adresses locales.')}</span>
                                    </div>

                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap  justify-center items-center mt-10 mb-10 m:flex-nowrap md:flex-nowrap lg:flex-nowrap">
                        <div className={"flex flex-col"}>
                            <div>
                                <h2 style={{
                                    fontFamily: "'Noto Sans', sans-serif",
                                    fontWeight: 900
                                }}  className={"font-bold m-5 text-2xl text-gray-700"}>{t('Partagez Vos coups de coeur et vos astuces')}</h2>
                                <div style={{
                                    fontWeight: 400
                                }} className={"text-justify m-5 text-xl text-gray-600 flex  flex-col gap-2"}>
                                    <span class={"font-extrabold"}>{t('Ajoutez vos recommandations')} </span>
                                    <span>{t('Enrichissez le livret d’accueil en partageant vos endroits favoris')}</span>
                                </div>
                                <div style={{
                                    fontWeight: 400
                                }} className={"text-justify m-5 text-xl text-gray-600 flex flex-col gap-2"}>
                                    <span class={"font-extrabold"}>{t('Donnez vos astuces de pro')} </span>
                                    <span>{t('Que ce soit pour éviter la foule, trouver le meilleur spot photo ou déguster les spécialités locales, vos conseils sont précieux.')}</span></div>

                            </div>
                        </div>
                        <div className={"items-center flex justify-center"}>
                            <img src={Coeur}  className={"rounded "}/>
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap  justify-center items-center mt-10 m:flex-nowrap md:flex-nowrap lg:flex-nowrap">
                        <div className={"items-center flex justify-center"}>
                            <img src={Img3}  className={"rounded "}/>
                        </div>
                        <div className={"flex flex-col"}>
                            <div>
                                <h2 style={{
                                    fontFamily: "'Noto Sans', sans-serif",
                                    fontWeight: 900
                                }}  className={"font-bold m-5 text-2xl text-gray-700"}>{t(' Traduction Automatique en plusieurs langues')}</h2>
                                <div style={{
                                    fontWeight: 400
                                }} className={"text-justify m-5 text-xl text-gray-600 flex flex-col gap-2"}>
                                    <span class={"font-extrabold"}>{t('Accessible à tous')} </span>
                                    <span>{t('Votre livret d’accueil est traduit instantanément dans plusieurs langues pour vos invités internationaux.')}</span>
                                </div>
                                <div style={{
                                    fontWeight: 400
                                }} className={"text-justify m-5 text-xl text-gray-600 flex flex-col gap-2"}>
                                    <span class={"font-extrabold"}>{t('Gain de temps')} </span>
                                    <span>{t('Plus besoin de créer plusieurs versions, nous nous occupons de tout.')}</span>
                                </div>

                                <div style={{
                                    fontWeight: 400
                                }} className={"flex flex-col sm:flex-row gap-5 m-5 text-xl text-gray-600"}>
                                    {t('Actuellement disponible dans les langues suivante')} : <br/>
                                    {langages.map((langage) =>
                                            <img src={global.config.uriAPI+"/"+langage.flag} className={"w-4 h-4 rounded-full shadow-lg  m-1"} />
                                    )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap  justify-center items-center mt-10 m:flex-nowrap md:flex-nowrap lg:flex-nowrap">
                        <div className={"flex flex-col"}>
                            <div>
                                <h2 style={{
                                    fontFamily: "'Noto Sans', sans-serif",
                                    fontWeight: 900
                                }}  className={"font-bold m-5 text-2xl text-gray-700"}>{t('QR Codes Intelligents La Clé d’un Accès Rapide')}</h2>
                                <div style={{
                                    fontWeight: 400
                                }} className={"text-justify m-5 text-xl text-gray-600 flex flex-col gap-2"}>
                                    <span class={"font-extrabold"}>{t('Accès instantané')} </span>
                                    <span>{t('Scannez le QR Code pour ouvrir directement le livret d’accueil numérique, sans aucune installation requise.')}</span>
                                </div>
                                <div style={{
                                    fontWeight: 400
                                }} className={"text-justify m-5 text-xl text-gray-600 flex flex-col gap-2"}>

                                    <span class={"font-extrabold"}>{t('Pratique et moderne')} </span>
                                    <span>{t('Placez-les où vous voulez – dans l’appartement, sur la clé, ou dans vos emails de bienvenue.')}</span>
                                </div>

                            </div>
                        </div>
                        <div className={"items-center flex justify-center"}>
                            <img src={Img4}  className={"rounded "}/>
                        </div>
                    </div>
                    <div>
                        <div className="flex flex-row flex-wrap  justify-center items-center mt-10 m:flex-nowrap md:flex-nowrap lg:flex-nowrap">
                            <div className={"flex flex-col"}>
                                <div>
                                    <h2 style={{
                                        fontFamily: "'Noto Sans', sans-serif",
                                        fontWeight: 900
                                    }} className={"font-bold m-5 text-2xl text-gray-700"}>{t('Affiche A4 de Bienvenue  L\'Accueil Parfait en Un Coup d\'Œil')}</h2>
                                    <div style={{
                                        fontWeight: 400
                                    }} className={"text-justify m-5 text-xl text-gray-600 flex flex-col gap-2"}>
                                        {t('Faites une excellente première impression grâce à une affiche élégante et informative, spécialement conçue pour vos invités')}</div>
                                    <div style={{
                                        fontWeight: 400
                                    }} className={"text-justify m-5 text-xl text-gray-600 flex flex-col gap-2"}>
                                        <span class={"font-extrabold"}>{t('Un design soigné ')} </span> {t('Une affiche personnalisée qui reflète votre style et l’ambiance de votre logement.')}</div>
                                    <div style={{
                                        fontWeight: 400
                                    }} className={"text-justify m-5 text-xl text-gray-600 flex flex-col gap-2"}>
                                        <span class={"font-extrabold"}>{t('QR Code intégré')} </span> {t('Vos invités n’ont qu’à scanner le code pour accéder instantanément à votre livret d’accueil numérique.')}</div>
                                    <div style={{
                                        fontWeight: 400
                                    }} className={"text-justify m-5 text-xl text-gray-600 flex flex-col gap-2"}>
                                        <span class={"font-extrabold"}>{t('Prêt à l’emploi')} </span>{t('Téléchargez et imprimez en quelques clics pour l’afficher dans l’entrée, sur la table ou près de la porte.')}</div>

                                </div>
                            </div>
                            <div className={"items-center flex justify-center"}>
                                <img src={Demo2}  className={"rounded"}/>
                            </div>
                        </div>
                    </div>
                    <div class={"flex flex-row gap-5 items-stretch flex-wrap justify-center"}>
                    {
                        article?.map((item, index) =>
                    <Card className="max-w-sm mt-5" imgSrc={global.config.urlStrapi+item.attributes.image.data[0].attributes.formats.thumbnail.url} horizontal>
                        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            {item.attributes.title}
                        </h5>
                        <p className="font-normal text-gray-700 dark:text-gray-400">
                            <TextTruncate
                                line={3}
                                element="span"
                                truncateText="…"
                                text={item.attributes.descriptionCourte}
                                textTruncateChild={<a href={"/article/"+item.id+'/'+item.attributes.slug} className={"text-cyan-700 hover:text-cyan-600"}>{t('Lire la suite')}</a>}
                            />
                        </p>
                    </Card>
                    )}
                    </div>

                </div>
            </div>


        <div class="max-w-4xl mx-auto p-6   ">
            <h2 class="text-3xl font-bold text-gray-800 text-center mb-6">{t('FAQ - Okurensio')}</h2>
            <div class="space-y-6" itemscope itemtype="https://schema.org/FAQPage">
                <div class="border-b border-gray-200 pb-4" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h2 class="text-xl font-semibold text-gray-700" itemprop="name">{t("Qu'est-ce qu'Okurensio et comment fonctionne-t-il ?")}</h2>
                    <p class="text-gray-600 mt-2" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <span itemprop="text">{t("Okurensio est un outil en ligne qui permet de créer des livrets d'accueil numériques pour vos locations")}.{t("Il simplifie le partage des informations importantes avec vos voyageurs grâce à un livret professionnel et intuitif")}.</span>
                    </p>
                </div>


                <div class="border-b border-gray-200 pb-4" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h2 class="text-xl font-semibold text-gray-700" itemprop="name">{t("Comment créer un livret d'accueil numérique pour ma location ?")}</h2>
                    <p class="text-gray-600 mt-2" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <span itemprop="text">{t("Inscrivez-vous sur Okurensio, renseignez les informations nécessaires et personnalisez votre livret via l’éditeur")}.{t("Vous obtenez ensuite un lien ou QR code à partager avec vos voyageurs")}.</span>
                    </p>
                </div>


                <div class="border-b border-gray-200 pb-4" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h2 class="text-xl font-semibold text-gray-700" itemprop="name">{t("Quels types d'informations puis-je inclure dans mon livret d'accueil ?")}</h2>
                    <p class="text-gray-600 mt-2" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <span itemprop="text">{t("Ajoutez des instructions d'arrivée, les règles de la maison, le Wi-Fi, des recommandations locales, et même des photos ou vidéos")}.{t("Tout pour garantir un séjour agréable à vos voyageurs")}.</span>
                    </p>
                </div>


                <div class="border-b border-gray-200 pb-4" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h2 class="text-xl font-semibold text-gray-700" itemprop="name">{t("Mon livret d'accueil est-il personnalisable selon le style de ma location ?")}</h2>
                    <p class="text-gray-600 mt-2" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <span itemprop="text">{t("Oui, vous pouvez choisir un thème de couleur prédéfini et ajouter des photos de votre location pour créer un livret unique et reflétant votre style")}.</span>
                    </p>
                </div>


                <div class="border-b border-gray-200 pb-4" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h2 class="text-xl font-semibold text-gray-700" itemprop="name">{t("Comment les voyageurs peuvent-ils accéder au livret d'accueil ?")}</h2>
                    <p class="text-gray-600 mt-2" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <span itemprop="text">{t("Les voyageurs accèdent au livret via un lien ou un QR code que vous partagez")}. {t("Ils peuvent le consulter depuis n’importe quel appareil connecté à Internet")}.</span>
                    </p>
                </div>


                <div class="border-b border-gray-200 pb-4" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h2 class="text-xl font-semibold text-gray-700" itemprop="name">{t("Combien coûte l'utilisation d'Okurensio et quels sont les plans disponibles ?")}</h2>
                    <p class="text-gray-600 mt-2" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <span itemprop="text">{t("Okurensio applique un tarif à l'usage basé sur le nombre de livrets créés")}. {t("Consultez la page tarif plus de détails sur les prix et les options disponibles")}.</span>
                    </p>
                </div>

            </div>
        </div>

        <CookieConsent
                location="bottom"
                buttonText={t('Accepter')}
                cookieName="okurensio"
                style={{ background: "#2B373B" }}
                buttonStyle={{ background: "#0097B2" , color: "#ffffff", fontSize: "13px" }}
                expires={150}
            >
                {t('Nous utilitons des cookies pour améliorer votre expérience d\'utilisation')}.{" "}
                <span style={{ fontSize: "10px" }}></span>
            </CookieConsent>
        </section>
        <div className={"bg-cyan-700 w-full mt-5"}>
            <div className={"container mx-auto justify-center items-center flex p-5 flex-col gap-5"}>
                <span className={"text-white font-semibold text-xl"}>Okurensio</span>

                <a href="/cgv" className="hover:underline me-4 md:me-6 text-white">{t('Mentions légales')}</a>
                <span class="block text-sm text-white sm:text-center ">© 2025 <a
                    href="/" className="hover:underline">Fyher™</a>. All Rights Reserved.</span>
            </div>
        </div>
        </>
    );
}