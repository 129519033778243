import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faEdit,
    faHome,
    faCircleInfo, faLocationDot, faSearch, faAdd, faSquarePlus, faCalendar
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {Form, useParams} from "react-router-dom";
import i18n from "../../../component/i18n";
import  Meta  from "../../../component/Meta";
import {FileInput, Label} from "flowbite-react";
import FontPicker from "react-fontpicker-ts";
import {TwitterPicker} from "react-color";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import MyCustomUploadAdapterPlugin from "../../../component/PluginUploader";
import TopicEditContent from "./topic/topicEditContent";
import TopicEditWifi from "./topic/topicEditWifi";
import TopicEditPlace from "./topic/topicEditPlace";
import TopicEditElectrical from "./topic/topicEditElectrical";
import { Breadcrumb } from 'flowbite-react';
import BreadCrumb from "./BreadCrumb";
import Layout from "../Layout";
import UploadFile from "./uploadFile";
import BgNew from "../../../asset/img/bg-4.png";

export default function EditTopic(props) {
    const MAX_FILE_SIZE = 5120 // 5MB
    let { guideBookUuid, uuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const [contentType, setContentType] = useState('');
    const [topic, setTopic] = useState([]);

    const { t } = useTranslation();
    const meta = {
        title: t('Editer un topic')
    }

    const[reload, setReload] = useState(1);
    const  contentUploadFile = (event) => {
        setUpLoad(1);
        setEventUpload(event);
    }
    const fileRefUpload = React.createRef()
    const [upload, setUpLoad] = useState(0);
    const [eventUpload, setEventUpload] = useState(null);
    const [tags, setTags] = useState([]);
    const changeRefUpload = () => {
        fileRefUpload.current.click();
    }
    const langId = localStorage.getItem('langId');
    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/topic/show/'+uuid, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setTopic(data);
                setContentType(data.type);
            })
            .catch((err) => {
            });

        fetch(global.config.uriAPI + '/app/tags/'+langId, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setTags(data)
            })
            .catch((err) => {
            });
    }, []);

    return (
        upload === 1 ?
            <UploadFile event={eventUpload}  setUpload = {setUpLoad}  setReload={setReload}  setGuideBook={setTopic} guideBook={topic}  title={t('Image du contenu')}  url={"uploadContentImg"}/>
            :
        <Layout>
            <div class="container mx-auto">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900 first-letter:uppercase">
                            {topic.name}
                        </h1>
                        <div class="flex items-center gap-2 text-sm font-normal text-gray-700">
                            <BreadCrumb links={[{name: t("Revenir en arrière"), url: document.referrer}]} />
                        </div>
                    </div>
                </div>
            </div>

            <section className={"container mx-auto"}>
                <Meta meta={meta} />
                <div className=" mt-5 shadow rounded border border-gray-200 w-full bg-white p-5 ">
                {contentType === 'content' ? <TopicEditContent tags={tags} topic={topic} guideBookUuid = {guideBookUuid} setTopic={setTopic} fileRefUpload={fileRefUpload} contentUploadFile={contentUploadFile} changeRefUpload={changeRefUpload}/>: <></>}
                {contentType === 'google_place' ? <TopicEditPlace tags={tags} topic={topic} guideBookUuid = {guideBookUuid} setTopic={setTopic} fileRefUpload={fileRefUpload} contentUploadFile={contentUploadFile} changeRefUpload={changeRefUpload}/>: <></>}
                {contentType === 'electrical_appliance' ? <TopicEditElectrical tags={tags} topic={topic} guideBookUuid = {guideBookUuid}  setTopic={setTopic} fileRefUpload={fileRefUpload} contentUploadFile={contentUploadFile} changeRefUpload={changeRefUpload}/>: <></>}
                </div>


            </section>
        </Layout>

    )

}