import Error from "../../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../../asset/img/loginimg.png';
import Lang from '../../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faEdit,
    faHome,
    faCircleInfo, faLocationDot, faSearch, faAdd, faImage
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {Form, useParams} from "react-router-dom";
import i18n from "../../../../component/i18n";
import  Meta  from "../../../../component/Meta";
import {FileInput, Label} from "flowbite-react";
import FontPicker from "react-fontpicker-ts";
import {TwitterPicker} from "react-color";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import MyCustomUploadAdapterPlugin from "../../../../component/PluginUploader";
import DeleteMedia from "../function/DeleteMedia";
import BgNew from "../../../../asset/img/bg-4.png";



export default function DiscountCodeNew(props) {
    const MAX_FILE_SIZE = 5120 // 5MB
    const topicUuid = props.topicUuid;
    const guideBookUuid = props.guideBookUuid;
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const [contentType, setContentType] = useState('');

    const { t } = useTranslation();
    const meta = {
        title: t('Ajouter un code de promotion')
    }
    const [discountCodeMode, setDiscountCodeMode] = useState('');
    const [discountCode, setDiscountCode] = useState([]);
    const [inputs, setInputs] =  useState({ logo: '', ssid: '', password: '', name: ''});
    const [loadData, setLoadData] = useState(false);
    const [button, setButton] = useState({disabled : '', loader: ''});
    const [summary, setSummary] = useState('');
    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { errors },
    } = useForm();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }
    const [iconButton, setIconButton] = useState({icon : <FontAwesomeIcon icon={faAdd} />});
    const onSubmitContent = (data) => {

        setButton(previousState => {
            return { ...previousState, disabled: 'disabled' }
        });
        setIconButton(previousState => {
            return { ...previousState, icon:  <FontAwesomeIcon icon={faSpinner} spin/>}
        });

        const myHeaders = new Headers();
        //myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);
        //myHeaders.append('Content-Type', 'Multipart/form-data');

        let formData = new FormData();

        /*let fileLogo = document.querySelector('#logo').files[0];
        if (fileLogo) {
            const fileSizeKiloBytesLogo = fileLogo.size / 1024
            const extensionLogo = fileLogo.type;


            if (['image/png', 'image/jpg', 'image/jpeg'].includes(extensionLogo) === false) {
                setError('logo', { type: 'error', message: t('Le fichier doit être au format png, jpg ou jpeg') });
                return;
            }

            if(fileSizeKiloBytesLogo > MAX_FILE_SIZE){
                setError('logo', { type: 'size', message: t('Le fichier est trop volumineux') });
                return
            }
        }*/

        setLoadData(false);

        //formData.append('discountcode', fileLogo);
        formData.append('topicUuid', topicUuid);
        formData.append('content', summary);
        formData.append('mediaId', props.discountCodeMedia?.media.id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI + '/app/discountcode/create', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                window.location.href = document.referrer;
                return;
            })
            .catch((err) => {
            });
    }


    const validLogo = () => {
        let fileData = document.querySelector('#logo').files[0];
        const extension = fileData.type;

        if (['image/png', 'image/jpg', 'image/jpeg'].includes(extension) === false) {
            setError('logo', { type: 'error', message: t('Le fichier doit être au format png, jpg ou jpeg') });
            return
        }
        setError('logo', null);
    }

    return (
        <Form action="" method="post" onSubmit={handleSubmit(onSubmitContent)} noValidate id="formCreate" className={"flex flex-col gap-5 mt-5"}>
            <b>{t('Ajouter un code de promotion')} : <span className={"first-letter:uppercase"}>{inputs.name}</span> </b>
            <div id="fileUpload" className="w-full">
                <div className="mb-2 block">
                    <Label htmlFor="file" value={t('Télécharger un bon de reduction')} />
                </div>
                {props.discountCodeMedia.media.url !== undefined ?
                    <div className={"relative"}>
                        <img src={global.config.uriAPI+"/"+props.discountCodeMedia.media.url} className={"w-full h-full object-cover rounded"} />
                        <div className={"absolute top-5 right-5"}>
                            <DeleteMedia  setSetter={props.setDiscountCodeMedia}  setter={props.discountCodeMedia} uuid={props.discountCodeMedia.media.uuid} />
                        </div>
                    </div>
                    :
                    <div id="fileUpload" className="w-full">
                        <input type={"file"} hidden={true} ref={props.fileRefUpload} onChange={props.contentUploadFile} />
                        <div onClick={props.changeRefUpload} className={"shadow rounded border border-dashed border-green-400 w-full bg-white p-5  h-full flex flex-col items-center justify-center cursor-pointer"}
                             style={{
                                 backgroundImage: `url(${BgNew})`,
                                 backgroundSize: 'cover',
                             }}>
                            <div class="relative size-[50px] shrink-0">
                                <svg class="w-full h-full stroke-1 stroke-green-600 fill-green-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                    </path>
                                    <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                    </path>
                                </svg>
                                <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4">
                                    <FontAwesomeIcon icon={faImage} className={"text-green-600"} />
                                </div>
                            </div>
                            <div className={"first-letter:uppercase text-lg font-medium text-gray-900 hover:text-primary-active mb-px mt-5"}>
                                {t('Ajouter une image')}
                            </div>
                            <span class="text-2sm text-gray-700">
                                        {t(' minimun 800*400px format PNG, JPG , HEIC')}
                                   </span>
                        </div>
                    </div>
                }
            </div>
            <div className="mt-3">
                <label className={ errors.summary ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2"}>{t('Les informations sur le code de promotion')}</label>
                <CKEditor
                    editor={ ClassicEditor }
                    config = {{
                        extraPlugins: [ MyCustomUploadAdapterPlugin ],
                        placeholder: t('Code de promotion'),

                    }}
                    type="text"
                    name="summary"
                    onChange={ ( event, editor ) => { setSummary(editor.getData())} }
                />
                {errors.summary && errors.summary.type === "required" && (
                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                )}
            </div>
            <button type="submit" disabled={button.disabled}
                    className="border-2 border-cyan-700 bg-cyan-500 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-cyan-700 font-semibold">
                {iconButton.icon}&nbsp;&nbsp; {t('Enregistrer')}
            </button>
        </Form>
    )
}