import {useTranslation} from "react-i18next";

import React, {useEffect, useState} from "react";
import {Button, Modal, Tooltip} from "flowbite-react";
import GoogleMapReact from "google-map-react";
import PlaceholderImg from "../../asset/img/placeholder.png";
import LocationImg from "../../asset/img/location.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDays, faLink, faLocationDot, faPercent, faPhone, faStar} from "@fortawesome/free-solid-svg-icons";

import iconHouse from '../../asset/iconhouse.svg';
export default function ModalEvent(props) {

    const {t} = useTranslation();
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (props.event === undefined) return;
        setOpenModal(true);
    }, [props.event])


    const Location = ({ text }) => <img src={iconHouse} style={{ width:32, height: 'auto' , transform: "translate(-50%, -100%)",  position: "absolute"}} />;

    return (
        props.event?.uuid !== undefined ?
        <Modal show={openModal}  size={"lg"}  onClose={() => setOpenModal(false)} key={props.event?.uuid}>
            <Modal.Header >
                <div className={"flex flex-row gap-5 items-center"}>
                    <h1 className={"lowercase first-letter:uppercase text-gray-700 font-semibold"}>{props.event.name}</h1>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className={"flex flex-col gap-2 w-full"}>
                    <img src={global.config.uriAPI+"/"+props.event.media.url} className={"w-full rounded"}/>
                    <p className="text-sm  text-gray-600  rounded  p-2" dangerouslySetInnerHTML={{__html: props.event?.content}}></p>
                    <span className={"text-xs text-gray-600 flex flex-row gap-1 items-center"}>
                                        <FontAwesomeIcon icon={faCalendarDays}  style={{
                                            color: props.guideBook.settings.secondaryColor
                                        }}/>
                                        <span className={"first-letter:uppercase"}>{t('De')}</span>
                                        <span className={"text-gray-700"}>{props.event.hourStart.replace(":","H")}</span>
                                        <span>{t('a')}</span>
                                        <span className={"text-gray-700"}>{props.event.hourEnd.replace(':','H')}</span>
                                    </span>
                    <span className={"text-sm text-gray-600 flex flex-row gap-2 items-center"}>
                                        <FontAwesomeIcon icon={faLocationDot} style={{
                                            color: props.guideBook.settings.secondaryColor
                                        }}/>
                        {props.event?.topic?.name != undefined ?
                                <>
                                    <span className={"text-gray-700 text-sm font-semibold"}>{props.event.topic.name}</span>
                                </>:
                            <></>}
                                        <span>{props.event.address}</span>
                                    </span>
                    <div className={"columns-2 md:columns-2 gap-2 space-y-2"}>
                        {props.event?.topic?.mediaLibrary?.map((media) =>
                            <img src={global.config.uriAPI+"/"+media.url} className={"w-full rounded"}  key={media.id}/>
                        )}
                    </div>

                    {props.event?.topic?.content !== "" ?
                        <p className="text-sm  text-gray-600  rounded  p-2" dangerouslySetInnerHTML={{__html: props.event?.topic?.content}}></p>
                        :
                        <></>}

                    {props.event?.topic?.uri !== "" ?
                        <div className={"flex flex-row gap-2 items-center mt-2 text-xs"}>
                            <FontAwesomeIcon icon={faLink}  style={{
                                color: props.guideBook.settings.secondaryColor
                            }}/>
                            <a href={props.topic?.uri}  target={"_blank"} className={"text-gray-600 underline"}>{props.event?.topic?.uri}</a>
                        </div> : <></>}
                    <div style={{width:"100%",height:"250px"}} className={"rounded"}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: global.config.apiKeyGoogle }}
                            center={[props.event.latitude, props.event.longitude]} zoom={11}
                            className={"rounded"}
                        >
                            <Location
                                lat={props.event.latitude}
                                lng={props.event.longitude}
                                text="My Marker"
                            />
                        </GoogleMapReact>
                    </div>
                </div>
            </Modal.Body>

        </Modal>: null
    )

}