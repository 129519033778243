import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faArrowRightToBracket, faCogs, faDownload, faEdit, faAdd, faTrash, faCheck, faPlusSquare, faEye, faSearch
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import Meta from "../../../component/Meta";
import GoogleMapReact from 'google-map-react';
import {Breadcrumb, Button, Card, Modal} from 'flowbite-react';
import SubCategory from './SubCategory';
import { Tooltip, Avatar } from 'flowbite-react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { Datepicker } from 'flowbite-react';
import i18n from "../../../component/i18n";
import Layout from "../Layout";
import BreadCrumb from "./BreadCrumb";
export default function Stats(props) {

    let { uuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const lng = i18n.language;
    const { t } = useTranslation();
    const meta = {
        title: t('Mon livret d\'accueil statistiques')
    }

    const [stats, setStats] = useState([]);
    const [xAxis, setXAxis] = useState([]);
    const [yAxis, setYAxis] = useState([]);
    const [dataset, setDataset] = useState([]);
    const [guidebook, setGuidebook] = useState([]);
    let dateStart = new Date("2025-01-01");
    let dateEnd = new Date("2025-12-31");

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(global.config.uriAPI+'/app/guidebook/show/'+uuid, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setGuidebook(data)
            });


        var raw = JSON.stringify({"guideBookUUID": uuid,"dateStart": dateStart,
            "dateEnd" : dateEnd});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(global.config.uriAPI+'/app/guidebook/stats', requestOptions)
            .then((response) => response.json())
            .then((data) => {

              setXAxis(data.x);
              setYAxis(data.y);

            })
            .catch((err) => {
            });

    }, []);

    return (
        <Layout>
            <div class="container mx-auto">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <div class="flex items-center gap-2 text-sm font-normal text-gray-700">
                            <BreadCrumb links={[{name: guidebook.name, url: null}]} />
                        </div>
                    </div>
                </div>
            </div>
            <section className={"container mx-auto mt-5 animate-fade delay-0 duration-150"}>
                <Meta meta={meta} />
                    <div className={"mt-5 shadow rounded border border-gray-200 w-full bg-white p-5"}>
                        <div className={"font-bold"}>{t('Suivi des visites sur le livret')} : {guidebook.name}</div>
                        <div className={"flex flex-row gap-2 justify-center items-center m-5"}>
                            <span>{t('Entre')}</span>
                            <Datepicker  defaultDate= {new Date(dateStart)}
                                         language={lng}
                                         id={"dateStartPicker"}
                            />
                            <span>{t('et le')} </span>
                            <Datepicker defaultDate={new Date(dateEnd)}
                                        language={lng}
                                        id={"dateEndPicker"}
                            />
                            <Button className={" text-center  col-span-1 border-cyan-700 text-cyan-500  py-1 rounded-md hover:bg-cyan-700  hover:text-white font-semibold p-2 text-xs" }
                                    onClick={() => {

                                        const dst =new Date(document.getElementById("dateStartPicker").value+" UTC");
                                        const det =new Date(document.getElementById("dateEndPicker").value+" UTC");
                                        dateStart = dst.toISOString();
                                        dateEnd = det.toISOString();
                                        var raw = JSON.stringify({"guideBookUUID": uuid,"dateStart": dateStart,
                                            "dateEnd" : dateEnd});
                                        const myHeaders = new Headers();
                                        myHeaders.append("Content-Type", "application/json");
                                        myHeaders.append('Authorization', 'Bearer '+token)

                                        var requestOptions = {
                                            method: 'POST',
                                            headers: myHeaders,
                                            body: raw,
                                            redirect: 'follow'
                                        };
                                        fetch(global.config.uriAPI+'/app/guidebook/stats', requestOptions)
                                            .then((response) => response.json())
                                            .then((data) => {

                                                setXAxis(data.x);
                                                setYAxis(data.y);

                                            })
                                            .catch((err) => {
                                            });
                                    }
                                    }
                            ><FontAwesomeIcon icon={faSearch} /></Button>
                        </div>
                        <div className={"w-full h-1/2"}>
                            <Line
                                datasetIdKey='id'
                                className={"w-full"}
                                data={{
                                    labels: xAxis,
                                    datasets: [
                                        {
                                            id: 1,
                                            label: t('Nombre de vues'),
                                            data: yAxis,
                                            fill: true,
                                            borderColor: '#3498db',
                                            fillColor: '#3498db',
                                        }
                                    ],
                                }}
                            />
                        </div>
                    </div>
            </section>
        </Layout>

    )

}