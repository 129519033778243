import { Form, useParams } from "react-router-dom";
import Error from "../../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Avatar, Tooltip} from "flowbite-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot, faPercent} from "@fortawesome/free-solid-svg-icons";

export default function TopicView(props) {
    const { t } = useTranslation();
    const [topicSelected, setTopicSelected] = useState();

    const regex = new RegExp("<\\/?p>", "g");

    return (
        <div className=" shadow rounded border border-gray-200 w-full bg-white p-2 flex flex-row gap-5 items-stretch" >
            {props.topic.type === "google_place" ?
                <>
                    {props.topic?.mediaLibrary[0] !== undefined ?
                        <div className={" w-32 rounded"} style={{
                            backgroundImage: `url(${global.config.uriAPI+'/'+props.topic.mediaLibrary[0].url})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}>
                        </div>
                        : <Avatar  size="lg"></Avatar> }
                </>
                :
                <div className={" w-32 rounded"} style={{
                    backgroundImage: `url(${global.config.uriAPI+'/'+props.topic.media.url})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}>
                </div>
            }
            <div className={"flex flex-row justify-between w-full"}>
                <div className={"flex flex-col gap-2 w-full"}>
                    <div className={"flex flew-row gap-2 items-center"}>
                        {props.topic.sub_category_id > 0 ?
                            <>
                                <Tooltip content={props.topic.subCategorys.name} className={"first-letter:uppercase"}>
                                    <div className={"rounded p-1 text-xs"}
                                         style={{
                                             backgroundColor:props.guideBook.settings.primaryColor,
                                             color: props.guideBook.settings.secondaryColor
                                         }}
                                    >
                                        <i className={props.topic.subCategorys.icon} />
                                    </div>
                                </Tooltip>
                            </>
                            : <></>}
                        {props.topic.hasDiscountCode === 1 ?
                            <>
                                <FontAwesomeIcon icon={faPercent} style={{
                                    color: props.guideBook.settings.primaryColor
                                }} />
                            </>
                            : <></>}
                        <h3 className={"text-gray-700 font-semibold text-sm lowercase first-letter:uppercase oswald-font"}>{props.topic.name}</h3>

                    </div>
                    {props.topic.distance > 0 ?
                        <div className={"flex flew-row gap-2 text-xs items-center"}>
                            <FontAwesomeIcon icon={faLocationDot} style={{
                                color: props.guideBook.settings.secondaryColor
                            }}/>
                            <span className={"text-gray-500"}>{props.topic.distance/1000} km</span>
                        </div>
                        : <></>}
                    <p className="text-sm text-gray-600  lator-ragular h-fit"  dangerouslySetInnerHTML={{__html: props.topic?.content.replace(regex, "")}}
                        style={{
                                display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden"
                        }}
                    ></p>
                    <div className={"flex flew-row justify-between w-full items-center"}>
                        <div className={"flex flex-row gap-2"}>
                            {props.topic.tags.map((tag) =>
                                <Tooltip content={tag.name}  className={"first-letter:uppercase"}>
                                                            <span className={"text-xs"}>
                                                                <i className={tag.icon}  style={{
                                                                    color: props.guideBook.settings.secondaryColor
                                                                }}/>
                                                            </span>
                                </Tooltip>
                            )}
                        </div>
                        <div onClick={() => props.setTopicSelected(props.topic)} className={"flex justify-end hover:cursor-pointer items-end"}
                             style={{
                                 color: props.guideBook.settings.secondaryColor,
                                 borderColor: props.guideBook.settings.secondaryColor,
                             }}

                             className={"text-xs border p-1 rounded hover:cursor-pointer lato-regular"}>
                            {t('En savoir plus')}</div>
                    </div>

                </div>

            </div>



        </div>
    )
}