import Logo from '../asset/img/okurensiologo.svg';
import Loginimg from '../asset/img/loginimg.png';
import { Form } from "react-router-dom";
import Error from "../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Accordion, Card, Navbar} from 'flowbite-react';

import  Meta  from "../component/Meta";


import Img1 from '../asset/img/architecture-2260836_640.jpg';
import Img2 from '../asset/img/hot-air-balloons-4561267_640.jpg';
import Img3 from '../asset/img/language-2345801_640.jpg';
import Img4 from '../asset/img/qrcode.jpg';
import Demo1 from '../asset/img/demo1.png';
import Demo2 from '../asset/img/demo2.png';
import i18n from "../component/i18n";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faCircleArrowRight, faSpinner} from "@fortawesome/free-solid-svg-icons";
import TextTruncate from 'react-text-truncate';
import { Pagination } from 'flowbite-react';
import Lang from '../component/lang.jsx'
import CookieConsent from "react-cookie-consent";
export default function Cgv() {

    const { t } = useTranslation();

    const font = {
        fontFamily: 'Inter',
    }

    const meta = {
        title: t('Okurensio  | Conditions générales de vente'),
        description: t('Conditions générales de vente'),
        image: Demo1
    }
    const lng = i18n.language;
    const [article, setArticle] = useState([]);
    const [loading, setLoading] = useState(false);




    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+global.config.strapi)

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(global.config.uriStrapi+'/cgvokr?locale='+lng, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setArticle(data.data);
                setLoading(true)

            })
            .catch((err) => {
            });


    }, [lng]);

    const openMobileMenu = () => {
        const mobileMenu = document.getElementById('mobile-menu-2');
        if (mobileMenu.style.display === 'block') {
            mobileMenu.style.display = 'none';
        } else {
            mobileMenu.style.display = 'block';
        }
    }

    return (
        <section  className="bg-hray-100 min-h-screen items-center  flex flex-col ">
            <Meta meta={meta} />

            <header>
                <nav class="bg-white border-gray-200 dark:bg-gray-900 fixed w-full shadow">
                    <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 ">
                        <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                            <img src={Logo} className="h-8" alt="okurensio Logo"/>
                            <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white text-gray-700">Okurensio</span>
                        </a>
                        <button data-collapse-toggle="mobile-menu-2" type="button" onClick={openMobileMenu}
                                className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                                aria-controls="navbar-default" aria-expanded="false">
                            <span class="sr-only">Open main menu</span>
                            <FontAwesomeIcon icon={faBars} />
                        </button>
                        <div class="hidden w-full md:block md:w-auto" id="mobile-menu-2">
                            <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                                <li>
                                    <a href="/"
                                       className="block py-2 px-3 text-white bg-cyan-700 rounded md:bg-transparent md:text-cyan-700 md:p-0 dark:text-white md:dark:text-cyan-500"
                                       aria-current="page">{t('Accueil')}</a>
                                </li>
                                <li>
                                    <a href="/tarif"
                                       className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cyan-700 md:p-0 dark:text-white md:dark:hover:text-cyan-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">{t('Tarif')}</a>
                                </li>
                                <li>
                                    <a href="/login"
                                       className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cyan-700 md:p-0 dark:text-white md:dark:hover:text-cyan-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">{t('Mon compte')}</a>
                                </li>
                                <li>
                                    <a href="/register"
                                       className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cyan-700 md:p-0 dark:text-white md:dark:hover:text-cyan-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">{t('Inscription')}</a>
                                </li>
                                <li>
                                    <a href="/articles"
                                       className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cyan-700 md:p-0 dark:text-white md:dark:hover:text-cyan-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">{t('Guides')}</a>
                                </li>
                                <li>
                                    <Lang/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>


            <div
                className="flex rounded-2xl  p-5 items-center flex-col container mt-10 mb-10">
                <div>

                    {loading ?
                            <div>
                                <h1 className={"font-bold text-3xl mt-10"}>{article.attributes.title}</h1>
                                <p className={"mt-10"}>
                                    {article.attributes.desc.map((desc) =>
                                        (
                                            desc.children.map((child) =>
                                                <>{child.text}</>
                                            )
                                        )
                                    )}
                                </p>
                            </div>
                        : <FontAwesomeIcon icon={faSpinner} spin={true} />}

                </div>
            </div>
            <CookieConsent
                location="bottom"
                buttonText={t('Accepter')}
                cookieName="okurensio"
                style={{ background: "#2B373B" }}
                buttonStyle={{ background: "#0097B2" , color: "#ffffff", fontSize: "13px" }}
                expires={150}
            >
                {t('Nous utilitons des cookies pour améliorer votre expérience d\'utilisation')}.{" "}
                <span style={{ fontSize: "10px" }}></span>
            </CookieConsent>
        </section>
    );
}