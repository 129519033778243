import { Form, useParams } from "react-router-dom";
import Error from "../../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../asset/img/okurensiologo.svg';
import Loginimg from '../../asset/img/loginimg.png';
import Lang from '../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowAltCircleDown,
    faCircleExclamation,
    faCircleInfo,
    faHome,
    faLocationDot,
    faTags,
    faSearch, faWifi, faArrowRightArrowLeft, faPercent, faTruckMedical, faHeart
} from "@fortawesome/free-solid-svg-icons";
import {faArrowRightToBracket} from "@fortawesome/free-solid-svg-icons";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import {Navigate} from "react-router-dom";
import Meta from "../../component/Meta";
import LangFront from "./langFront";
import { Avatar } from 'flowbite-react';
import PowerBy from "./powerby";
import LogoAnime from "../../asset/img/logoanime.gif";


function adjust(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

export default function NBar(props) {

    const [hoveredItems, setHoveredItems] = useState([]);
    const { t } = useTranslation();

    const handleHover = (index) => {
        return () => {
            setHoveredItems(previousState => {
                return {...previousState, [index]: !previousState[index]}
            })
        }
    };

    return (
        <div className={"flex justify-center"}>
        <div className={"bottom-[+15px] fixed  z-50 rounded-full border-2 container pr-5 pl-5 w-fit  flex flex-row gap-2 items-stretch pt-2 pb-2 m-4 justify-center text-white shadow "}
            style={{
                backgroundColor: props.styles.secondaryColor,
                borderColor: props.styles.primaryColor,
            }}>
            <a href={"/guidebook/"+props.guideBook.uuid} className={"rounded-full p-2 text-xl border-2 border-white font-bold"} >
                <FontAwesomeIcon icon={faHome} />
            </a>
            <a href={"/guidebook/subcategory/"+props.guideBook.logementUuid+"/"+props.guideBook.uuid+"#wifi"} className={"rounded-full p-2 text-xl border-2 border-white"} >
                <FontAwesomeIcon icon={faWifi} />
            </a>
            <a href={"/guidebook/tagscode/"+props.guideBook.uuid} className={"rounded-full p-2 text-xl border-2 border-white"} >
                <FontAwesomeIcon icon={faHeart} />
            </a>
            <a href={"/guidebook/subcategory/"+props.guideBook.emergencyUuid+"/"+props.guideBook.uuid} className={"rounded-full p-2 text-xl border-2 border-white font-bold"} >
                <FontAwesomeIcon icon={faTruckMedical} />
            </a>
            <a href={"/guidebook/carte/"+props.guideBook.uuid} className={"rounded-full p-2 text-xl border-2 border-white"} >
                <FontAwesomeIcon icon={faLocationDot} />
            </a>
            <a href={"/guidebook/discountcode/"+props.guideBook.uuid} className={"rounded-full p-2 text-xl border-2 border-white"} >
                <FontAwesomeIcon icon={faPercent} />
            </a>
            <a href={"/guidebook/subcategory/"+props.guideBook.logementUuid+"/"+props.guideBook.uuid} className={"rounded-full p-2 text-xl border-2 "} style={{
                color: props.styles.secondaryColor,
                borderColor: props.styles.primaryColor,
                backgroundColor: props.styles.primaryColor
            }}>
                <FontAwesomeIcon icon={faArrowRightArrowLeft} />
            </a>

        </div>
        </div>
    )

}