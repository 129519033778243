
import React, {useState, useEffect, createRef} from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Layout from '../Layout';
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import BgNew from '../../../asset/img/bg-4.png';
import iconHouse from '../../../asset/iconhouse.svg';
import {
    faCircleExclamation,
    faArrowsUpDown,
    faForward,
    faBook,
    faSpinner,
    faArrowRightToBracket,
    faCogs,
    faDownload,
    faEdit,
    faAdd,
    faTrash,
    faCheck,
    faPlusSquare,
    faEye,
    faCaretDown,
    faHospital,
    faPhone,
    faAt,
    faClock,
    faArrowRightArrowLeft,
    faImage,
    faSquarePlus,
    faClose,
    faCalendar,
    faLink,
    faCopy
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import Meta from "../../../component/Meta";
import GoogleMapReact from 'google-map-react';
import {Button, Card, Modal, FileInput, Label, Toast} from 'flowbite-react';
import SubCategory from './SubCategory';
import { Tooltip, Avatar } from 'flowbite-react';
import BreadCrumb from "./BreadCrumb";
import Cropper from 'react-easy-crop'
import UploadFile from "./uploadFile";
import DeleteMedia from './function/DeleteMedia';
import DeletePath from "./function/DeleteByPath";

export default function ShowGuideBook(props) {

    const [defaultMap, setDefaultMap] = useState([]);
    const [page, setPage] = useState(0);
    const fileRefUpload = React.createRef()
    const [upload, setUpLoad] = useState(0);
    const [eventUpload, setEventUpload] = useState(null);
    const  contentUploadFile = (event) => {
        setUpLoad(1);
        setEventUpload(event);
    }

    let { uuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';

    const { t } = useTranslation();
    const meta = {
        title: t('Mon livret d\'accueil')
    }
    const [openModal, setOpenModal] = useState(false);
    const [guidebook, setGuidebook] = useState([]);
    const [categorys, setCategorys] = useState([]);
    const [limit, setLimit] = useState(50);
    const [orderBy, setOrderBy] = useState([]);
    const [searchName, setSearchName] = useState('');
    const [pathDelete, setPathDelete] = useState('');
    const [inDelete, setInDelete] = useState(0);
    const[reload, setReload] = useState(1);
    const [reloadBtnUrgence, setReloadBtnUrgence] = useState(0);
    const [event, setEvent] = useState([]);

    useEffect(() => {

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/guidebook/show/'+uuid, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setGuidebook(data)

                setDefaultMap({
                    "center": {
                        "lat": data.latitude,
                        "lng": data.longitude
                    },
                    "zoom": 11
                })


                var raw = JSON.stringify({"page": page,"limit": limit, "orderBy": orderBy, 'name': searchName});
                var requestOptions = {
                    method: 'POST',
                    body: raw,
                    headers: myHeaders,
                    redirect: 'follow'
                };

                fetch(global.config.uriAPI+'/app/category/'+uuid, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        setCategorys(data)
                        setReload(0);
                    })
                    .catch((err) => {
                    });



            })
            .catch((err) => {
                if (err.status === 401) {
                    window.location.href = '/login'
                }
            });


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI + '/app/event/view/'+uuid+"/guidebook", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setEvent(data);
            })
            .catch((err) => {
            });

    }, [reload]);



    //https://medium.com/nerd-for-tech/simple-drag-and-drop-in-react-without-an-external-library-ebf1c1b809e


    const deleteCall = () => {
        const path = pathDelete;
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)
        setInDelete(1);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+path, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setInDelete(0);
                setOpenModal(false);
                setReload(1);
            })
            .catch((err) => {
            });
    }

    const Marker = ({ show, place }) => {
        const markerStyle = {
            cursor: 'pointer',
            zIndex: 10,
        };

        return (
            <>
                <div style={markerStyle} >
                    <img src={iconHouse} style={{ width:32, height: 'auto'}} />
                </div>
            </>
        );
    };

    const changeRefUpload = () => {
        fileRefUpload.current.click();
    }

    const handleCopy = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText("https://okurens.io/guidebook/"+guidebook.uuid);
        alert(t('Lien copié'));
    }

    return (
            upload === 1 ?

                <UploadFile event={eventUpload} setUpload={setUpLoad} setReload={setReload}
                                    setGuideBook={setGuidebook} guideBook={guidebook}
                                    title={t('Image de votre logement')} uuid={uuid} url={"uploadGuideBook"}/>

                :
                <Layout>
                    <div class="container mx-auto">
                        <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                            <div class="flex flex-col justify-center gap-2">
                                <div class="flex items-center gap-2 text-sm font-normal text-gray-700">
                                    <BreadCrumb links={[{name: guidebook.name, url: null}]} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className={"container mx-auto mt-5 animate-fade delay-0 duration-150"}>
                        <Meta meta={meta} />
                        <div>

                            {reload === 0 ?
                                guidebook.id != undefined ?
                                    (
                                        <>
                                            <div className="mt-5 shadow rounded border border-gray-200 w-full bg-white p-5">
                                            <div className={"flex justify-end gap-2 mb-2"}>
                                                {guidebook.wifi.ssid !== undefined ?
                                                <Tooltip content={t('Modifier')}>
                                                    <a href={"/app/guidebook/edit/"+guidebook.uuid}   className={"p-2 rounded bg-gray-50 text-gray-700 text-xs flex gap-2 items-center border border-gay-600"}>
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </a>
                                                </Tooltip> : <></> }
                                                <Tooltip content={t('Contenu')}>
                                                    <a href={"/app/guidebook/catlist/"+guidebook.uuid}  className={"p-2 rounded bg-gray-50 text-gray-700 text-xs flex gap-2 items-center border border-gay-600"}>
                                                        <FontAwesomeIcon icon={faCogs} />
                                                    </a>
                                                </Tooltip>

                                                <Tooltip content={t('Apercu')}>
                                                    <a href={"/guidebook/"+guidebook.uuid}  target={"_blank"}  className={"p-2 rounded bg-gray-50 text-gray-700 text-xs flex gap-2 items-center border border-gay-600"}>
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </a>
                                                </Tooltip>
                                            </div>
                                            <div className={"grid grid-cols-1 lg:grid-cols-2 gap-5 items-stretch"}>
                                                <div className={"flex justify-start items-center p-5 flex-col gap-5 items-stretch" } style={{
                                                    backgroundImage: `url(${BgNew})`,
                                                    backgroundPosition: 'center',
                                                    backgroundSize: 'cover',
                                                }}>

                                                        <span className={"first-letter:uppercase text-3xl text-gray-700 font-semibold"}>{guidebook.name}</span>



                                                        <div className={"first-letter:uppercase text-xs text-gray-600"}>
                                                            {guidebook.address}
                                                        </div>



                                                    <div className={"flex flex-row gap-2 items-center text-gray-600 text-xs"}>
                                                        <FontAwesomeIcon icon={faPhone} />
                                                        <span>{guidebook.contactPhone}</span>
                                                    </div>


                                                    <div className={"flex flex-row gap-2 items-center text-gray-600 text-xs"}>
                                                        <FontAwesomeIcon icon={faAt} />
                                                        <span>{guidebook.contactEmail}</span>
                                                    </div>
                                                    <div className={"flex flex-row gap-2 items-center text-gray-600 text-xs"}>
                                                        <FontAwesomeIcon icon={faLink} />

                                                            <span>https://okurens.io/guidebook/{guidebook.uuid}</span>


                                                        <span><FontAwesomeIcon icon={faCopy}  className={"hover:cursor-pointer text-cyan-600"} onClick={handleCopy}/></span>
                                                    </div>

                                                    <div className={"flex justify-end w-full gap-5"}>

                                                    </div>
                                                </div>
                                                <div>
                                                    <div style={{width:"100%",height:"250px", borderRadius: "10px;"}} className={"rounded"}>
                                                        <GoogleMapReact
                                                            bootstrapURLKeys={{ key: global.config.apiKeyGoogle }}
                                                            center={[guidebook.latitude, guidebook.longitude]} zoom={14}
                                                        >
                                                            <Marker
                                                                key="home"
                                                                lat={guidebook.latitude}
                                                                lng={guidebook.longitude}
                                                            />
                                                        </GoogleMapReact>
                                                    </div>
                                                </div>
                                            </div>
                                                <h2 className={"text-gray-700 text-lg font-bold "}>{t('Image de présentation')}</h2>

                                            <div className={"mt-5 grid lg:grid-cols-2 grid-cols-1 gap-2"}>
                                                <input type={"file"} hidden={true} ref={fileRefUpload} onChange={contentUploadFile} />
                                                <div onClick={changeRefUpload} className={"shadow rounded border border-dashed border-green-400 w-full bg-white p-5  h-full flex flex-col items-center justify-center cursor-pointer"}
                                                     style={{
                                                         backgroundImage: `url(${BgNew})`,
                                                         backgroundSize: 'cover',
                                                     }}>
                                                    <div class="relative size-[50px] shrink-0">
                                                        <svg class="stroke-1 stroke-green-600 fill-green-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
			18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
			39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                            </path>
                                                            <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
			18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
			39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                            </path>
                                                        </svg>
                                                        <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4">
                                                            <FontAwesomeIcon icon={faImage} className={"text-green-600"} />
                                                        </div>
                                                    </div>
                                                    <div className={"first-letter:uppercase text-lg font-medium text-gray-900 hover:text-primary-active mb-px mt-5"}>
                                                        {guidebook.media.url !== undefined ?
                                                                    t('Modifier l\'image')
                                                                    :
                                                                    t('Ajouter une image') }

                                                    </div>
                                                    <span class="text-2sm text-gray-700">
                                    {t(' minimun 800*400px format PNG, JPG , HEIC')}
                               </span>
                                                </div>
                                                <div className={""}>
                                                    {guidebook.media.url !== undefined ?
                                                        <div className={"relative"}>
                                                            <img src={global.config.uriAPI+"/"+guidebook.media.url} className={"w-full h-full object-cover rounded"} />
                                                            <div className={"absolute top-5 right-5"}>
                                                               <DeleteMedia  setSetter={setGuidebook}  setter={guidebook} uuid={guidebook.media.uuid} />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className={"shadow rounded border-gray-400 bg-gray-50  w-full   h-full flex flex-col items-center justify-center cursor-pointer "}
                                                             style={{
                                                                 backgroundImage: `url(${BgNew})`,
                                                                 backgroundSize: 'cover',
                                                             }}
                                                        >
                                                            <div class="relative size-[50px] shrink-0">
                                                                <svg class=" stroke-1 stroke-gray-600 fill-gray-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
			18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
			39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                                    </path>
                                                                    <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
			18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
			39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                                    </path>
                                                                </svg>
                                                                <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4">
                                                                    <FontAwesomeIcon icon={faImage} className={"text-gray-600"} />
                                                                </div>
                                                            </div>
                                                    </div>
                                                    }

                                                </div>
                                            </div>

                                            </div>
                                            <div className="mt-5 shadow rounded border border-gray-200 w-full bg-white p-5">

                                                         <h2 className={"text-gray-700 text-lg font-bold"}>{t('Réglement Intérieur')}</h2>


                                                <div className={"mt-5"}>
                                                    <div className={"grid lg:grid-cols-2 grid-cols-1 gap-5"}>
                                                        {guidebook.ruleInterior.map((rule) =>
                                                            <div className={"flex flex-col gap-2 rounded  p-2  bg-gray-50"}>
                                                                <div className={"flex gap-2 items-center"}>
                                                                    <div className={rule.isCheck === true ? "relative size-[50px] shrink-0 text-green-700": "relative size-[50px] shrink-0 text-red-700"}>
                                                                        <svg className={rule.isCheck === true ?"stroke-1 stroke-green-700 fill-green-50 text-green-700": "stroke-1 stroke-red-700 fill-red-50 text-red-700"} fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                            18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                            39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                                            </path>
                                                                            <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                            18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                            39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                                            </path>
                                                                        </svg>
                                                                        <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 ">
                                                                            {rule.icon === "" ? <i className={"fa-solid fa-circle-question text-md"}></i>: <i className={rule.icon+" text-md"}></i>}

                                                                        </div>
                                                                    </div>
                                                                    <div className={"flex flex-col gap-2"}>
                                                                <span className={"first-letter:uppercase font-semibold text-xs text-gray-700"}>
                                                            {rule.name}</span>
                                                                        <span className={"text-gray-600 text-xs"}> {rule.isCheck === true ? rule.description: rule.descNotCheck }</span>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="mt-5 shadow rounded border border-gray-200 w-full bg-white p-5">

                                                <h2 className={"text-gray-700 text-lg font-bold"}>{t('Informations de départ et d\'arrivé')}</h2>
                                                <div className={"mt-5 flex flex-col gap-2"}>
                                                    <p className={"text-xs text-gray-500 mt-5"}><span class={"font-semibold"}>{t("Les voyageurs peuvent arrivé après")} </span>: {guidebook.clockIn} h</p>
                                                    <p className={"text-xs text-gray-500"}><span class={"font-semibold"}>{t("Le code de la boite à clef est")} </span>: {guidebook.keyDoor} </p>
                                                    <p className={"p-4 my-4 bg-gray-50 border-l-4 border-gray-300 dark:border-gray-500 dark:bg-gray-800  text-xs text-gray-700"}>{ guidebook.kd.content }</p>
                                                    <p className={"text-xs text-gray-500"}><span class={"font-semibold"}>{t("Le départ doit s'effectué avant")} </span>: {guidebook.clockOut} h</p>
                                                    <p className={"p-4 my-4 bg-gray-50 border-l-4 border-gray-300 dark:border-gray-500 dark:bg-gray-800  text-xs text-gray-700"}>{guidebook?.ws?.content}</p>
                                                </div>
                                                <div className={"mt-5"}>
                                                    <div className={"grid lg:grid-cols-2 grid-cols-1 gap-5"}>
                                                        {guidebook.ruleCheckout.map((rule) =>
                                                            <div className={"flex flex-col gap-2 rounded  p-2  bg-gray-50"}>
                                                                <div className={"flex gap-2 items-center"}>
                                                                    <div className={ "relative size-[50px] shrink-0 text-gray-700"}>
                                                                        <svg className={"stroke-1 stroke-gray-700 fill-gray-50 text-gray-700"} fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                            18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                            39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                                            </path>
                                                                            <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                            18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                            39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                                            </path>
                                                                        </svg>
                                                                        <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 ">
                                                                            {rule.icon === ""? <FontAwesomeIcon  icon={faArrowRightArrowLeft} className={"text-md"}/> :  <i className={rule.icon+" text-md"}></i>}

                                                                        </div>
                                                                    </div>
                                                                    <div className={"flex flex-col gap-2"}>
                                                                <span className={"first-letter:uppercase font-semibold text-xs text-gray-700"}>
                                                            {rule.name}</span>
                                                                        <span className={"text-gray-600 text-xs"}> {rule.description }</span>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            {guidebook.wifi.ssid !== undefined ?
                                                <><div className="mt-5 shadow rounded border border-gray-200 w-full bg-white p-5">

                                                         <h2 className={"text-gray-700 text-lg font-bold"}>{t('Wifi')}</h2>

                                                    <div className={"mt-5"}>
                                                        <p className={"text-xs text-gray-500 mt-5"}><span class={"font-semibold"}>{t("Nom")}</span> : {guidebook.wifi.ssid} </p>
                                                        <p className={"text-xs text-gray-500 mt-5"}><span class={"font-semibold"}>{t("Mot de passe")} </span>: {guidebook.wifi.password} </p>
                                                        <p className={"text-xs text-gray-500 mt-5"}><span class={"font-semibold"}>{t("Portail captif")} </span>: {guidebook.wifi.portailCaptif === 0 ? <><FontAwesomeIcon icon={faCheck} /></> : <><FontAwesomeIcon icon={faClose} /></>} </p>
                                                        <p className={"p-4 my-4 bg-gray-50 border-l-4 border-gray-300 dark:border-gray-500 dark:bg-gray-800  text-xs text-gray-700"}>{ guidebook.wifi.content}</p>
                                                    </div>
                                                </div>
                                                </>
                                                : <></>}


                                            {guidebook.hasDuplicate === true ? null :
                                            <>
                                                <div className={"mt-5"}>
                                                    <a href={"/app/guidebook/addevent/guidebook/"+guidebook.uuid+"/"+guidebook.uuid}
                                                       className={"shadow rounded border border-dashed border-cyan-400 w-full bg-white p-5  h-full flex flex-col items-center justify-center"}
                                                       style={{
                                                           backgroundImage: `url(${BgNew})`,
                                                           backgroundSize: 'cover',
                                                           backgroundPosition: 'center',
                                                       }}>
                                                        <div
                                                            className="relative size-[50px] shrink-0">
                                                            <svg class="stroke-1 stroke-cyan-600 fill-cyan-50" fill="none"
                                                                 height="48" viewBox="0 0 44 48" width="44"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
			18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
			39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z"
                                                                      fill="">
                                                                </path>
                                                                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
			18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
			39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z"
                                                                      stroke="">
                                                                </path>
                                                            </svg>
                                                            <div
                                                                class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 cursor-pointer">
                                                                <FontAwesomeIcon icon={faCalendar} className={"text-cyan-600"}/>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={"first-letter:uppercase text-lg font-medium text-gray-600 hover:text-primary-active mb-px mt-5"}>
                                                            {t('Ajouter un évènement')}
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className={"mt-5"}>
                                                    {event.map((item) =>
                                                        <div className={"mt-2 shadow rounded border border-gray-200 w-full bg-white p-5 flex flex-row justify-between gap-2"}>
                                                            <div className={"flex flex-col gap-2"}>
                                                                <div className={"flex flex-row gap-2 items-center"}>
                                                                    <Tooltip content={ t('Modifier')}>
                                                                        <a href={"/app/guidebook/updateEvent/"+item.uuid+"/"+guidebook.uuid} className={"bg-gray-200 text-gray-700 text-sm p-2 rounded"}><FontAwesomeIcon icon={faEdit} /></a>
                                                                    </Tooltip>
                                                                    <h2 className={"font-bold text-gray-700 text-sm first-letter:uppercase"}>{item.name}</h2>
                                                                </div>

                                                                <div className={"flex flex-row gap-2 text-gray-600 text-xs items-center"}>
                                                                    <span className={"text-gray-600 text-sm"}>{item.dateStart}</span> / <span className={"text-gray-600 text-sm"}>{item.dateEnd}</span>
                                                                </div>
                                                            </div>

                                                            <DeletePath  path={"/app/event/delete/"+item.uuid} setter={setEvent}  uuid={item.uuid}/>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                            }

                                        </>
                                    ): (<FontAwesomeIcon icon={faSpinner} spin={true} /> )
                                : <FontAwesomeIcon icon={faSpinner} spin={true} />}
                        </div>
                    </section>
                </Layout>
    )
}