import { Form, useParams } from "react-router-dom";
import Error from "../../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../asset/img/okurensiologo.svg';
import Loginimg from '../../asset/img/loginimg.png';
import Lang from '../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowAltCircleDown, faArrowLeft, faArrowRight, faArrowRightArrowLeft, faBook, faChartLine, faChevronLeft,
    faCircleExclamation,
    faCircleInfo, faEllipsisVertical, faEnvelope, faEye,
    faHome,
    faLocationDot, faPercent,
    faSearch, faTrash
} from "@fortawesome/free-solid-svg-icons";
import {faArrowRightToBracket} from "@fortawesome/free-solid-svg-icons";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import {Navigate} from "react-router-dom";
import Meta from "../../component/Meta";
import LangFront from "./langFront";
import {Avatar, Dropdown, Tooltip} from 'flowbite-react';
import PowerBy from "./powerby";
import LogoAnime from "../../asset/img/logoanime.gif";
import NBar from "./nbar";
import ModalTopic from "./modalTopic";
import {faFilter} from "@fortawesome/free-solid-svg-icons/faFilter";
import TopicView from "./topicView";

function adjust(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

function hexToRGB(hex, alpha) {
    console.log(hex);
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export default function DiscountCodeFront(props) {
    let { uuid, categoryUuid } = useParams();

    const [categorys, setCategorys] = useState([]);
    const [loadData, setLoadData] = useState(false);
    const { t } = useTranslation();
    const [guideBook, setGuideBook] = useState([]);
    const [langage, setLangage] = useState([]);
    const langId = localStorage.getItem('langId');
    const [styles, setStyles] = useState({overlay: '', item: '', itemActive: '', navigationBar: ''});
    const [meta, setMeta] = useState({title: '', font: '', description: '', image: ''});

    const [hoveredItems, setHoveredItems] = useState([]);
    const [topicList, setTopicList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [nbView, setNbView] = useState(7);

    const [topicViewList, setTopicViewList] = useState([]);
    const [topicTmpList, setTopicTmpList] = useState([]);
    const [filterSubCategory, setFilterSubCategory] = useState([]);
    const [filterTag, setFilterTag] = useState([]);
    const [loadFilter, setLoadFilter] = useState(false);
    const [topicSelected, setTopicSelected] = useState();
    const [listTag, setListTag] = useState([]);

    useEffect(() => {

        fetch(global.config.uriAPI+'/langage')
            .then((response) => response.json())
            .then((data) => {
                setLangage(data);
            })
            .catch((err) => {
            });

        fetch(global.config.uriAPI+'/guidebook/'+uuid+"/"+langId, )
            .then((response) => response.json())
            .then((data) => {
                setGuideBook(data);


                const meta = {
                    title: data.name,
                    font: data.settings.mainFont,
                    description: data.name,
                    image: global.config.uriAPI+'/'+data.media.url
                }
                setMeta(meta);
                /*updateStyles.item[':hover']= {
                    backgroundColor: adjust(data.settings.navigationBarColor, -40)
                }*/
                fetch(global.config.uriAPI+'/guidebook/discountcode/'+uuid+"/"+langId , )
                    .then((response) => response.json())
                    .then((data) => {
                        setLoadData(true);
                        setTopicList(data.topicList)
                        setTopicTmpList(data.topicList);
                        setTopicViewList(data.topicList.slice(0, nbView));
                        setCategorys(data);
                        //setTopicList([...topicList, data.subCategorys.topics]);

                    })
                    .catch((err) => {
                    });
            })
            .catch((err) => {
            });
    }, [langId]);

    const handleHover = (index) => {
        return () => {
            setHoveredItems(previousState => {
                return {...previousState, [index]: !previousState[index]}
            })
        }
    };


    const handleScroll = () => {
        if (document.body.scrollHeight - 300 < window.scrollY + window.innerHeight) {
            setLoading(true);
        }
    };

    useEffect(() => {
        if (loading == true) {
            setNbView((nbView) => nbView + 7);
            setTopicViewList(topicTmpList.slice(0, nbView));
            setLoading(false);
        }
    }, [loading]);

    function debounce(func, delay) {
        let timeoutId;
        return function (...args) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    // adding debounce to the eventListner
    window.addEventListener("scroll", debounce(handleScroll, 500));

    const clickSubCategory = (subCategoryId) => {

        //const subCategoryId = parseInt(event.currentTarget.dataset.id);
        if(filterSubCategory.includes(subCategoryId)){
            setFilterSubCategory(filterSubCategory.filter(item => item !== subCategoryId));
        }else{
            setFilterSubCategory([...filterSubCategory, subCategoryId]);
        }
        setLoadFilter(true);
    }

    const clickFilterTag = (tagCode) => {
        if (filterTag.includes(tagCode)) {
            setFilterTag(filterTag.filter(item => item !== tagCode));
        } else {
            setFilterTag([...filterTag, tagCode]);
        }
        setLoadFilter(true);
    }

    useEffect(() => {
        const filteredList = topicList.filter(topic => {
            return filterSubCategory.includes(topic.sub_category_id);
        });
        // on doit metre à jour topicViewList avec les
        setTopicTmpList(filteredList);
        if (filterSubCategory.length === 0) {
            setTopicTmpList(topicList);
        }

        if (filterTag.length > 0) {
            const filteredListTag = topicTmpList.filter(topic => {
                return Array.isArray(topic.tagsCode) ? topic.tagsCode.some(tag => filterTag.includes(tag)) : false;
            });
            setTopicTmpList(filteredListTag);
        }


        // on doit aussi regarder pour les tags
    }, [filterSubCategory, filterTag]);

    useEffect(() => {
        setNbView(7);

        setTopicViewList(topicTmpList.slice(0, nbView));
        setLoadFilter(false)
    }, [topicTmpList]);


    return (
        <div className={"min-h-screen"} style={{
            backgroundColor: "#F5F5F5",
        }}>
            {loadData ? <>
                    <div  className={"container  max-w-screen-md mx-auto  flex flex-col gap-5"} >
                        <Meta meta={{
                            title: guideBook.name,
                            font: guideBook.settings.mainFont,
                            description: guideBook.name,
                            image: global.config.uriAPI+'/'+guideBook.media.url,
                            themeColor: guideBook.settings.secondaryColor
                        }} />
                        <header className={"flex flex-col justify-between  w-full min-h-[250px] rounded-b-2xl shadow-lg"}
                                style={{
                                    backgroundImage:  `linear-gradient(
                             rgba(255, 255, 255, 0.2),
                            rgba(255, 255, 255, 0.2)
                        ), url(${global.config.uriAPI+'/'+guideBook.media.url})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}
                        >
                            <div className={"flex flex-row justify-between items-start"}>
                                <img src={"/themelogo/"+guideBook.settings.code+".svg"}  width={"10%"} className={"m-5"}/>
                                <LangFront langage={langage} settings={guideBook.settings} />
                            </div>

                        </header>
                        <div className={" flex justify-between items-center pr-5 pl-5"}>
                            <div className={"flex flex-col gap-2"}>
                                <div className={"flex flex-row gap-2"}>
                                    <a href={document.referrer} className={"rounded text-sm p-1 w-5 flex justify-center items-center"}
                                       style = {{
                                           backgroundColor: guideBook.settings.secondaryColor,
                                           color: guideBook.settings.primaryColor
                                       }}
                                    ><FontAwesomeIcon icon={faChevronLeft} /></a>
                                    <h1 class={"text-xl text-gray-700 font-bold first-letter:uppercase oswald-font"}>{t('Code promo')}</h1>
                                </div>
                                <p className={"text-gray-500 text-sm lato-regular"}>{t('Vous trouverez ci dessous la liste de nos partnaires avec lequel vous aurez des réductions grâce à votre séjour')}</p>
                            </div>


                            <div className={"flex flex-row gap-5"}>

                                <Dropdown
                                    arrowIcon={false}
                                    inline
                                    label={<FontAwesomeIcon icon={faFilter} className={"text-gray-400 rounded p-2"}/>}
                                    placement="left"

                                >

                                    {categorys?.subCategorys.map((subCategory, index) =>
                                        <Dropdown.Item
                                            data-id={subCategory.id}
                                            onClick={()=> clickSubCategory(subCategory.id)}
                                            className={"flex flex-row gap-2 items-center"}
                                            style = {{
                                                backgroundColor: filterSubCategory.includes(subCategory.id) ? guideBook.settings.primaryColor : ""
                                            }}
                                        >
                                            <i className={subCategory.icon} style={{
                                                color: guideBook.settings.secondaryColor,

                                            }} />
                                            <span className={"first-letter:uppercase"}>{subCategory.name}</span>
                                        </Dropdown.Item>
                                    )}
                                    <Dropdown.Divider />

                                    {categorys.tags.map((tag, index) =>
                                        <Dropdown.Item
                                            onClick={() => clickFilterTag(tag.code)}
                                            className={"flex flex-row gap-2 items-center"}
                                            style = {{
                                                backgroundColor: filterTag.includes(tag.code) ? guideBook.settings.primaryColor : ""
                                            }}
                                        >
                                            <i className={tag.icon} style={{
                                                color: guideBook.settings.secondaryColor,
                                            }} />
                                            <span className={"first-letter:uppercase"}>{tag.name}</span>
                                        </Dropdown.Item>
                                    )}
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <div className={"container max-w-screen-md mx-auto p-5 md:p-0 lg:p-0 mt-5"}>
                        {loadFilter === false ?
                            <div className={"flex flex-col gap-2 items-stretch"}>
                                {topicViewList.map((topic, index) =>
                                    <TopicView guideBook={guideBook} topic={topic} setTopicSelected={setTopicSelected} />
                                )}
                                {loading && <h1 className={"mt-5 text-gray-600 font-semibold"}>{t('Chargement...')}</h1>}
                                <ModalTopic topic={topicSelected} guideBook={guideBook} />
                            </div>
                            : <>
                                <FontAwesomeIcon icon={faSpinner} spin={true} />
                            </> }

                    </div>
                    <div className={"text-gray-600  container max-w-screen-md mx-auto p-5  justify-center items-center flex text-xs mb-20 gap-5"}>
                        <span> @{t('Copyright by Okurensio')}</span> <a href={"https://okurens.io"} target={"_blank"}>https://okurens.io</a>
                    </div>
                    <NBar styles={guideBook.settings} guideBook={guideBook}  active={"accueil"}/>
                </>
                :
                <img src={LogoAnime}
                     style={{
                         width: 80,
                         $height: 80
                     }}
                     className={"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"}/>
            }

        </div>
    )
}