import React,{Component}  from 'react';
import { Helmet } from 'react-helmet-async';
import Error from "./Error";
function Meta (props) {

    const meta = props.meta;


    return (
        <Helmet>
            <title data-react-helmet="true">{meta.title}</title>
            <meta name="description" content={meta.description}  data-react-helmet="true"/>
            <meta name="keywords" content={meta.keywords} data-react-helmet="true" />
            <meta name={"theme-color"} content={meta.themeColor} data-react-helmet="true" />
            <meta name="author" content={meta.author} />
            <meta property="og:title" content={meta.title} data-react-helmet="true" />
            <meta property="og:description" content={meta.description} data-react-helmet="true" />
            <meta property="og:image" content={meta.image}  data-react-helmet="true"/>
            <meta property="og:url" content={meta.canonical} data-react-helmet="true" />
            <meta name="twitter:title" content={meta.title} />
            <meta name="twitter:description" content={meta.description} />
            <meta name="twitter:image" content={meta.image} />
            <meta name="twitter:card" content={meta.image} />
        </Helmet>
    )
}

export default Meta;