import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import BgNew from '../../../asset/img/bg-4.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faEdit,
    faHome,
    faCircleInfo, faLocationDot, faSearch, faAdd, faImage
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {Form, useParams} from "react-router-dom";
import i18n from "../../../component/i18n";
import  Meta  from "../../../component/Meta";
import {Breadcrumb, Checkbox, Datepicker, FileInput, Label, Select} from "flowbite-react";
import FontPicker from "react-fontpicker-ts";
import {TwitterPicker} from "react-color";
import Layout from '../Layout';
import BreadCrumb from "./BreadCrumb";
import UploadFile from "./uploadFile";
import DeleteMedia from "./function/DeleteMedia";


export default function UpdateEvent(props) {
    const { t } = useTranslation();
    let { eventUuid, guideBookUuid } = useParams();
    const meta = {
        title: t('Ajouter un évènement')
    }

    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';

    const changeRefUpload = () => {
        fileRefUpload.current.click();
    }
    const lng = i18n.language;
    const fileRefUpload = React.createRef()
    const [upload, setUpLoad] = useState(0);
    const [eventUpload, setEventUpload] = useState(null);
    const[reload, setReload] = useState(1);
    const [category, setCategory] = useState({media : {}});
    const  contentUploadFile = (event) => {
        setUpLoad(1);
        setEventUpload(event);
    }
    const [button, setButton] = useState({disabled : '', loader: ''});
    const [iconButton, setIconButton] = useState({icon : <FontAwesomeIcon icon={faAdd} />});
    const [topic , setTopic] = useState([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');
    const [link, setLink] = useState('');
    const [days, setDays] = useState([]);
    const [hourStart, setHourStart] = useState('');
    const [hourEnd, setHourEnd] = useState('');
    const [minuteStart, setMinuteStart] = useState('');
    const [minuteEnd, setMinuteEnd] = useState('');
    const [event, setEvent] = useState([]);
    const [infoPlace, setInfoPlace] = useState(null);

    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { errors },
    } = useForm();

    useEffect(() => {

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/event/show/'+eventUuid, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setEvent(data);
                setDateStart(data.dateStart);
                setDateEnd(data.dateEnd);
                setHourEnd(data.hourEnd);
                setHourStart(data.hourStart);
                setName(data.name);
                setDescription(data.content)
                setCategory(data);
                setLink(data.link);
                setDays(data.days)


            })
            .catch((err) => {
            });
    }, [] );

    const onSubmit = (data) => {

        setButton(previousState => {
            return { ...previousState, disabled: 'disabled' }
        });
        setIconButton(previousState => {
            return { ...previousState, icon:  <FontAwesomeIcon icon={faSpinner} spin/>}
        });

        const myHeaders = new Headers();
        //myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);
        //myHeaders.append('Content-Type', 'Multipart/form-data');

        let formData = new FormData()

        let latAddress = '';
        let lngAddress = '';
        let label = "";
        if (event.type ==="guidebook") {

            if (infoPlace != null) {
                label = infoPlace.label;
            } else {
                label= event.address;
            }


            geocodeByAddress(label)
                .then(results => getLatLng(results[0]))
                .then(({ lat, lng }) => {
                    latAddress = lat;
                    lngAddress = lng;
                    formData.append('address', label);
                    formData.append('longitude', lngAddress);
                    formData.append('latitude', latAddress);
                    formData.append('name', name);
                    formData.append('content', description);
                    formData.append('dateStart', new Date(dateStart).toString());
                    formData.append('dateEnd', new Date(dateEnd).toString());
                    formData.append('days', days);
                    formData.append('guideBookUuid', guideBookUuid);
                    if (days.length >0) {
                        formData.append('isRecurring', 1);
                    } else {
                        formData.append('isRecurring', 0);
                    }
                    formData.append('link', link);
                    formData.append('type', event.type);
                    formData.append('hourStart', hourStart);
                    formData.append('hourEnd', hourEnd);
                    formData.append("mediaId", category.media.id);
                    // formData.append('categoryOverrideUUID', data.overrideUuid==undefined?'':data.overrideUuid);
                    formData.append('eventUuid', eventUuid);
                    formData.append("nodeUuid", event.nodeUuid);


                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formData,
                        redirect: 'follow'
                    };

                    fetch(global.config.uriAPI + '/app/event/update', requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            window.location.href = document.referrer;
                            return;
                        })
                        .catch((err) => {
                        });
                })
        } else {
            formData.append('name', name);
            formData.append('content', description);
            formData.append('dateStart', new Date(dateStart).toString());
            formData.append('dateEnd', new Date(dateEnd).toString());
            formData.append('days', days);
            formData.append('guideBookUuid', guideBookUuid);
            if (days.length >0) {
                formData.append('isRecurring', 1);
            } else {
                formData.append('isRecurring', 0);
            }
            formData.append('link', link);
            formData.append('type', event.type);
            formData.append('hourStart', hourStart);
            formData.append('hourEnd', hourEnd);
            formData.append("mediaId", category.media.id);
            // formData.append('categoryOverrideUUID', data.overrideUuid==undefined?'':data.overrideUuid);
            formData.append('eventUuid', eventUuid);
            formData.append("nodeUuid", event.nodeUuid);


            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formData,
                redirect: 'follow'
            };

            fetch(global.config.uriAPI + '/app/event/update', requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    window.location.href = document.referrer;
                    return;
                })
                .catch((err) => {
                });
        }
    }

    const addDays = (day) => {
        if(days.includes(day)){
            setDays(days.filter(item => item !== day));
        }else{
            setDays([...days, day]);
        }

        console.log(days);
    }
    return(
        upload === 1 ?
            <UploadFile event={eventUpload}  setUpload = {setUpLoad}  setReload={setReload}  setGuideBook={setCategory} guideBook={category}  title={t('Image de l\'évènement')}  url={"uploadContentImg"}/>
            :
            <Layout>
                <div class="container mx-auto">
                    <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                        <div class="flex flex-col justify-center gap-2">
                            <h1 class="text-xl font-semibold leading-none text-gray-900">
                                {t('Evènement')} <span className={"first-letter:uppercase"}>{event.name}</span>
                            </h1>
                            <div class="flex items-center gap-2 text-sm font-normal text-gray-700">
                                <BreadCrumb links={[{name: t('Revenir au contenu'), url: document.referrer} ]} />
                            </div>
                        </div>
                    </div>
                </div>

                <section className={"container mx-auto"}>
                    <Meta meta={meta} />
                    <form action="" method="post" onSubmit={handleSubmit(onSubmit)} noValidate className=" mt-5 shadow rounded border border-gray-200 w-full bg-white p-5 ">
                        {category?.media.url !== undefined ?
                            <div className={"relative"}>
                                <img src={global.config.uriAPI+"/"+category.media.url} className={"w-full h-full object-cover rounded"} />
                                <div className={"absolute top-5 right-5"}>
                                    <DeleteMedia  setSetter={setCategory}  setter={category} uuid={category.media.uuid} />
                                </div>
                            </div>
                            :
                            <div id="fileUpload" className="w-full">
                                <input type={"file"} hidden={true} ref={fileRefUpload} onChange={contentUploadFile} />
                                <div onClick={changeRefUpload} className={"shadow rounded border border-dashed border-green-400 w-full bg-white p-5  h-full flex flex-col items-center justify-center cursor-pointer"}
                                     style={{
                                         backgroundImage: `url(${BgNew})`,
                                         backgroundSize: 'cover',
                                     }}>
                                    <div class="relative size-[50px] shrink-0">
                                        <svg class="w-full h-full stroke-1 stroke-green-600 fill-green-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                            </path>
                                            <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                            </path>
                                        </svg>
                                        <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4">
                                            <FontAwesomeIcon icon={faImage} className={"text-green-600"} />
                                        </div>
                                    </div>
                                    <div className={"first-letter:uppercase text-lg font-medium text-gray-900 hover:text-primary-active mb-px mt-5"}>
                                        {t('Ajouter une image')}
                                    </div>
                                    <span class="text-2sm text-gray-700">
                                        {t(' minimun 800*400px format PNG, JPG , HEIC')}
                                   </span>
                                </div>
                            </div>
                        }
                        <div class={"flex flex-row gap-5 mt-5"}>
                            <div class={"flex flex-col gap-2"}>
                                <label className={"text-gray-700 font-semibold oswald-font"}>{t('Date de début')}</label>
                                <Datepicker value={dateStart}
                                    className={"w-52"} onSelectedDateChanged={(date) => setDateStart(date)}/>
                            </div>
                            <div class={"flex flex-col gap-2"}>
                                <label className={"text-gray-700 font-semibold oswald-font"}>{t('Heure de début')}</label>
                                <input type="time" id="appt" name="appt" min="00:00" max="23:59" className={"rounded bg-gray-50 text-gray-600" }  value={hourStart} onChange={(e) => setHourStart(e.target.value)} />
                            </div>
                        </div>
                        <div class={"flex flex-row  gap-5 mt-5"}>
                            <div class={"flex flex-col gap-2"}>
                                <label className={"text-gray-700 font-semibold oswald-font "}>{t('Date de fin')}</label>
                                <Datepicker value={dateEnd} className={"w-52"} onSelectedDateChanged={(date) => setDateEnd(date)}/>
                            </div>
                            <div class={"flex flex-col gap-2"}>
                                <label className={"text-gray-700 font-semibold oswald-font"}>{t('Heure de fin')}</label>


                                <input type="time" id="appt" name="appt" min="00:00" max="23:59" className={"rounded bg-gray-50 text-gray-600" } value={hourEnd}  onChange={(e) => setHourEnd(e.target.value)} />
                            </div>
                        </div>
                        <div className={"mt-5"}>
                            <label htmlFor="name" className={  "block text-sm mb-2  text-gray-700 font-semibold first-letter:uppercase"}>{t('Nom')}</label>
                            <input type="text" id="name"
                                   name ="name"
                                   value={name}
                                   onChange={(e)=>setName(e.target.value)}
                                   className= {
                                       "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600 w-full"
                                   }
                                   placeholder={t("Nom")}/>
                        </div>
                        {event.type === "guidebook" ?
                            <div className={"mt-5 flex flex-col gap-2"}>
                                <label htmlFor="adresse" className={  "text-md font-semibold text-gray-700 lg:max-w-[25%] w-full"}>
                                    {t('L\'adresse de l\'évènement')}</label>
                                <span className={"text-gray-600 text-sm"}>{event.address}</span>
                                <GooglePlacesAutocomplete apiKey={global.config.apiKeyGoogle}
                                                          value = {event.address}
                                                          apiOptions={{ language: lng }}
                                                          className={"border rounded  h-10  bg-gray-50 border-gray-300  text-gray-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 pr-4 pl-10"}
                                                          selectProps={{
                                                              infoPlace,
                                                              onChange: setInfoPlace,
                                                              initialValue: event.address
                                                          }}
                                />
                            </div>
                            : <></>}
                        <div className={"mt-5"}>
                            <label htmlFor="name" className={  "block text-sm mb-2  text-gray-700 font-semibold first-letter:uppercase"}>{t('Lien')}</label>
                            <input type="text" id="name"
                                   name ="link"
                                    value={link}
                                   onChange={(e)=>setLink(e.target.value)}
                                   className= {
                                       "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600 w-full"
                                   }
                                   placeholder={t("www.lien.fr")}/>
                        </div>
                        <div className={"mt-5"}>
                            <label htmlFor="name" className={  "block text-sm mb-2  text-gray-700 font-semibold first-letter:uppercase"}>{t('Description')}</label>
                            <textarea
                                value={description}
                                rows={5}
                                onChange={(e)=>setDescription(e.target.value)}
                                className={"border rounded text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600 w-full text-gray-600"}
                                placeholder={t('Description de l\évènement')}>{description}</textarea>
                        </div>
                        <div className={"mt-5 bg-gray-50 rounded p-5"}>
                            <div>
                                <div className={"flex flex-col gap-2 "}>
                                    <div className={"flex flex-row gap-5 items-center"}>
                                        <FontAwesomeIcon icon={faCircleInfo} className={"text-gray-600"}/>
                                        <p className={"text-gray-600 text-sm lato-regular"}>{t('Si vous avez choisis une période vous pouvez indiquer les jours de l\'évènement')}</p>
                                    </div>
                                    <p><span className={"font-semibold text-gray-700 oswald-font text-sm text-gray-600" }>{t('Exemple')}</span> :
                                        <span className={"text-gray-600 lato-regular text-sm"}>{t('Un évènement qui se déroule tous les lundis et mercredi de juillet à septembre')}</span>
                                    </p>

                                </div>

                                <div className="flex items-center gap-2 mt-5">
                                    {days.includes(1)?
                                        <Checkbox id="lundi" onClick={() => addDays(1)}   defaultChecked />
                                        :
                                        <Checkbox id="lundi" onClick={() => addDays(1)}   />}

                                    <Label htmlFor="lundi" className={"first-letter:uppercase text-gray-600 text-sm"}>{t('lundi')}</Label>
                                </div>
                                <div className="flex items-center gap-2 mt-2">
                                    <Checkbox id="mardi"  onClick={() => addDays(2)}/>
                                    <Label htmlFor="mardi" className={"first-letter:uppercase text-gray-600 text-sm"}>{t('mardi')}</Label>
                                </div>
                                <div className="flex items-center gap-2 mt-2">
                                    <Checkbox id="mercredi"  onClick={() => addDays(3)}/>
                                    <Label htmlFor="mercredi" className={"first-letter:uppercase text-gray-600 text-sm"}>{t('mercredi')}</Label>
                                </div>
                                <div className="flex items-center gap-2 mt-2">
                                    <Checkbox id="jeudi"  onClick={() => addDays(4)}/>
                                    <Label htmlFor="jeudi" className={"first-letter:uppercase text-gray-600 text-sm"}>{t('jeudi')}</Label>
                                </div>
                                <div className="flex items-center gap-2 mt-2">
                                    <Checkbox id="vendredi" onClick={() => addDays(5)}/>
                                    <Label htmlFor="vendredi" className={"first-letter:uppercase text-gray-600 text-sm"}>{t('vendredi')}</Label>
                                </div>
                                <div className="flex items-center gap-2 mt-2">
                                    <Checkbox id="samedi" onClick={() => addDays(6)}/>
                                    <Label htmlFor="samedi" className={"first-letter:uppercase text-gray-600 text-sm"}>{t('samedi')}</Label>
                                </div>
                                <div className="flex items-center gap-2 mt-2">
                                    <Checkbox id="dimanche" onClick={() => addDays(7)}/>
                                    <Label htmlFor="dimanche" className={"first-letter:uppercase text-gray-600 text-sm"}>{t('dimanche')}</Label>
                                </div>
                            </div>
                        </div>
                        {event.type === "guidebook" ?
                            <>
                                {(category.media.id != undefined &&  (infoPlace != null || event.address != "")) ?
                                    <button type="submit" disabled={button.disabled}
                                            className="border-2 border-cyan-700 bg-cyan-500 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-cyan-700 font-semibold mt-5">
                                        {iconButton.icon}&nbsp;&nbsp; {t('Modifier')}
                                    </button>
                                    :<div
                                        className="border-2 border-gray-700 bg-gray-500 text-white py-1 w-full rounded-md flex items-center justify-center p-5 mt-5">
                                        {iconButton.icon}&nbsp;&nbsp; {t('Modifier')}
                                    </div> }</>
                            :
                            <>
                                {category.media.id != undefined ?
                                    <button type="submit" disabled={button.disabled}
                                            className="border-2 border-cyan-700 bg-cyan-500 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-cyan-700 font-semibold mt-5">
                                        {iconButton.icon}&nbsp;&nbsp; {t('Modifier')}
                                    </button>
                                    :<div
                                        className="border-2 border-gray-700 bg-gray-500 text-white py-1 w-full rounded-md flex items-center justify-center p-5 mt-5">
                                        {iconButton.icon}&nbsp;&nbsp; {t('Modifier')}
                                    </div> }
                            </>
                        }

                    </form>
                </section>
            </Layout>
    )
}