import React, {useEffect, useState} from 'react';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRightArrowLeft,
    faArrows,
    faCheck, faCircleDown, faClose, faDownload,
    faEdit, faPercentage,
    faSquarePlus,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "flowbite-react";
import {useTranslation} from "react-i18next";
import DeleteByPath from "./function/DeleteByPath";
import Layout from "../Layout";
import BreadCrumb from "./BreadCrumb";
import Meta from "../../../component/Meta";
import {
    closestCorners,
    DndContext,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import CategoryShow from "./categoryShow";
import BgNew from "../../../asset/img/bg-4.png";
import {useParams} from "react-router-dom";

export default function  CatSubList(props) {
    let { guideBookUuid, uuid } = useParams();
    const { t } = useTranslation();
    const meta = {
        title: t('Liste des sous catégories / contenus')
    }
    const [subCategory, setSubCategory] = useState(null);
    const [category, setCategory] = useState([]);
    // faut aller chzercher les info  pour cetet categorie
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const [guideBook, setGuideBook] = useState([]);
    const [nbSubCategorys, setNbSubCategorys] = useState(0)
    const [topics, setTopics] = useState([])
    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token)

        var requestOptionsGet = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/guidebook/show/'+guideBookUuid, requestOptionsGet)
            .then((response) => response.json())
            .then((data) => {
                setGuideBook(data)
                }
            );

        fetch(global.config.uriAPI+'/app/category/show/'+uuid, requestOptionsGet)
            .then((response) => response.json())
            .then((data) => {
                    setCategory(data)
                }
            );

        var raw = JSON.stringify({"guideBookUuid":guideBookUuid});
        var requestOptionsGet = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(global.config.uriAPI+'/app/subcategory/'+uuid, requestOptionsGet)
            .then((response) => response.json())
            .then((data) => {
                setSubCategory(data.subCategorys)
                setNbSubCategorys(data.nbSubCategorys)
                setTopics(data.topics)
                }
            );

        // faut les infos sur le guidebook
    }, []);




    return (
        <Layout>
            <div class="container mx-auto">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900 first-letter:uppercase">
                            {guideBook.name}
                        </h1>
                        <div class="flex items-center gap-2 text-sm font-normal text-gray-700">
                            <BreadCrumb links={[{name: t("Liste des Categories"), url: "/app/guidebook/catList/"+guideBookUuid}, {name : category.name, url: null}]} />
                        </div>
                    </div>
                </div>
            </div>

            <section className={"container mx-auto"}>
                <Meta meta={meta} />
                    {category.module_name === "logement" ?
                        <>
                        <div className=" mt-5 shadow rounded border border-gray-200 w-full bg-white p-5 ">

                                <h2 class={"font-bold text-gray-700 text-lg"}
                                >{t('Réglement intérieur')}</h2>
                                <div className={"grid lg:grid-cols-2 grid-cols-1 gap-5 mt-5"}>
                                    {guideBook.ruleInterior?.map((rule) =>
                                        <div className={"flex flex-col gap-2 rounded  p-2  bg-gray-50"}>
                                            <div className={"flex gap-2 items-center"}>
                                                <div className={rule.isCheck === true ? "relative size-[50px] shrink-0 text-green-700": "relative size-[50px] shrink-0 text-red-700"}>
                                                    <svg className={rule.isCheck === true ?"stroke-1 stroke-green-700 fill-green-50 text-green-700": "stroke-1 stroke-red-700 fill-red-50 text-red-700"} fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                                18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                                39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                        </path>
                                                        <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                                18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                                39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                        </path>
                                                    </svg>
                                                    <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 ">
                                                        {rule.icon === "" ? <i className={"fa-solid fa-cogs text-md"}></i>: <i className={rule.icon+" text-md"}></i>}

                                                    </div>
                                                </div>
                                                <div className={"flex flex-col gap-2"}>
                                                                    <span className={"first-letter:uppercase font-semibold text-xs text-gray-700"}>
                                                                {rule.name}</span>
                                                    <span className={"text-gray-600 text-xs"}> {rule.isCheck === true ? rule.description: rule.descNotCheck }</span>
                                                </div>

                                            </div>

                                        </div>
                                    )}
                                </div>

                        </div>
                        <div className=" mt-5 shadow rounded border border-gray-200 w-full bg-white p-5 ">

                                <h2 className={"font-bold text-gray-700 text-lg"}>{t('Informations de départ et d\'arrivé')}</h2>
                                <div className={"mt-2 flex flex-col gap-2"}>
                                    <p className={"text-sm text-gray-500 mt-5"}>{t("Vous pouvez arriver à partir de")} <span class={"font-semibold"}>{guideBook.clockIn} h</span>
                                        {guideBook.keyDoor !== "" ?<span className={"ml-1"}>{t("et le code de la boite à clef est")} : <span class={"font-semibold"}>{guideBook.keyDoor}</span></span> : <></> }
                                    </p>
                                    {guideBook.kd?.content !== "" ? <>
                                        <p className={"p-4 my-4 bg-gray-50 border-l-4 border-gray-300 dark:border-gray-500 dark:bg-gray-800  text-sm text-gray-700"}>{ guideBook.kd?.content }</p>
                                    </> : <></> }

                                    <p className={"text-sm text-gray-500"}>{t("Le départ doit s'effectué avant")} : <span class={"font-semibold"}>{guideBook.clockOut} h</span>

                                    </p>
                                    <p className={"text-sm text-gray-500"}>{t('Avant votre départ n\'oubliez pas de')}</p>
                                </div>
                                <div className={"mt-5"}>
                                    <div className={"grid lg:grid-cols-2 grid-cols-1 gap-5"}>
                                        {guideBook.ruleCheckout?.map((rule) =>
                                            <div className={"flex flex-col gap-2 rounded  p-2  bg-gray-50"}>
                                                <div className={"flex gap-2 items-center"}>
                                                    <div className={ "relative size-[50px] shrink-0 text-gray-700"}>
                                                        <svg className={"stroke-1 stroke-gray-700 fill-gray-50 text-gray-700"} fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                            18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                            39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                            </path>
                                                            <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                            18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                            39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                            </path>
                                                        </svg>
                                                        <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 ">
                                                            {rule.icon === ""? <FontAwesomeIcon  icon={faArrowRightArrowLeft} className={"text-md"}/> :  <i className={rule.icon+" text-md"}></i>}

                                                        </div>
                                                    </div>
                                                    <div className={"flex flex-col gap-2"}>
                                                                <span className={"first-letter:uppercase font-semibold text-xs text-gray-700"}>
                                                            {rule.name}</span>
                                                        <span className={"text-gray-600 text-xs"}> {rule.description }</span>
                                                    </div>

                                                </div>

                                            </div>
                                        )}
                                    </div>
                                </div>

                        </div>
                            <div className=" mt-5 shadow rounded border border-gray-200 w-full bg-white p-5 ">

                                <h2 className={"font-bold text-gray-700 text-lg"}>{t('Wifi')}</h2>
                                {guideBook.wifi?.ssid !== undefined ?
                                    <>
                                        <div className={"mt-2"}>
                                            <p className={"text-sm text-gray-500 mt-5"}>{t("Nom du réseaux")}: <span class={"font-semibold"}>{guideBook.wifi.ssid} </span> </p>
                                            {guideBook.wifi.portailCaptif === 0 ?
                                                <p className={"text-sm text-gray-500 mt-5"}>{t("Mot de passe")}  <span class={"font-semibold"}>{guideBook.wifi.password} </span>:</p> : <></>}
                                            {guideBook.wifi?.content !== "" ?
                                                <p className={"p-4 my-4 bg-gray-50 border-l-4 border-gray-300 dark:border-gray-500 dark:bg-gray-800  text-sm text-gray-700"}>{ guideBook.wifi.content}</p>
                                                : <></> }
                                        </div>

                                    </>
                                    : <></>}

                            </div>


                        </>
                        : <></>}



                <div className={"grid lg:grid-cols-4 gap-5 items-stretch mt-5"}>
                    {subCategory?.map((sub, index) =>
                                <div className={"p-2  text-gray-600 shadow rounded border  flex flex-col justify-start items-center gap-1 border-gray-200 bg-white "}
                                >
                                    <div class={"flex justify-between items-center w-full"}>
                                        <div className={"flex flex-row gap-2 items-stretch"}>
                                            <Tooltip content={t('Modifier')}>
                                                <a href={"/app/guidebook/editsubcategory/"+guideBook.uuid+"/"+uuid+"/"+sub.uuid} className={"rounded p-2 bg-gray-100 text-gray-600 text-xs"}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </a>
                                            </Tooltip>
                                            <Tooltip content={t('Ajouter un contenu')}>
                                                <a href={"/app/guidebook/addtopic/"+guideBook.uuid+"/"+sub.uuid} className={"rounded p-2 bg-cyan-100 text-xs"}>
                                                    <FontAwesomeIcon icon={faSquarePlus} className={"text-cyan-600 "}/>
                                                </a>
                                            </Tooltip>
                                        </div>

                                        {sub.moduleName != null ?<></>: <DeleteByPath path={"/app/subcategory/remove/"+sub.uuid} setter={setSubCategory}  uuid={sub.uuid}></DeleteByPath>}

                                    </div>
                                    <div class={"rounded p-5 text-gray-500"}>
                                        <i class={sub.icon +" fa-2x"} />
                                    </div>
                                    <div className={"first-letter:uppercase text-gray-600 font-semibold flex flex-row gap-10 justify-evenly items-center"}>
                                        <span>{sub.name} <span className={"text-gray-400 text-xs"}>{sub.nbTopics}</span></span>
                                    </div>
                                </div>
                            )
                    }

                </div>



                {topics?.map((topic) =>
                    <div className=" mt-5 shadow rounded border border-gray-200 w-full bg-white p-5 flex flex-col gap-2 ">
                        <h2 className={"font-bold text-gray-700 text-lg first-letter:uppercase"}>{topic.name} </h2>
                        <div className={"flex flex-row gap-2"}>
                            {topic.media?.url !== undefined ?<>
                                <img src={global.config.uriAPI+"/"+topic.media.url} className={"w-10 h-10 object-cover rounded"} />
                            </> :<></> }
                            {topic.type === "electrical_appliance" ?
                                <Tooltip content={t("Télécharger le QRCode")}>
                                    <a href={global.config.uriAPI+'/qrcode/electrical-'+topic.uuid+'.png'} target={"_blank"} className={"h-10 w-10 flex justify-center items-center"} style={{
                                        backgroundImage: `url(${global.config.uriAPI+'/qrcode/electrical-'+topic.uuid+'.png'})`,
                                        backgroundColor: 'rgba(255,255,255,0.5)',
                                        backgroundBlendMode: 'overlay',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center'
                                    }}>
                                        <FontAwesomeIcon icon={faCircleDown}  className={"text-gray-700"}/>
                                    </a>
                                </Tooltip>


                                : <></> }
                        </div>

                        {topic?.mediaLibrary !== undefined ? <div className={"flex gap-2 flex-row"}>
                            {topic?.mediaLibrary.map((media) =>
                            <>
                                <img src={global.config.uriAPI+"/"+media.url} className={"w-10 h-10 object-cover rounded"} />
                            </>
                            )}

                        </div>: <></>}
                        <div className={"flex justify-between items-center mt-5 items-stretch"}>
                            <div className={"flex flex-row justify-start items-stretch gap-2"}>
                                <Tooltip content={t('Modifier')}>
                                    <a href={"/app/guidebook/edittopic/"+guideBook.uuid+"/"+topic.uuid} className={"rounded text-sm p-2 bg-gray-200 text-gray-700"}><FontAwesomeIcon icon={faEdit} /></a>
                                </Tooltip>
                                <Tooltip content={t('Code de promotion')}>
                                    {topic.discountCode === true ?
                                        <a href={"/app/guidebook/discountcodetopic/"+guideBook.uuid+"/"+topic.uuid} className={"rounded text-sm p-2 bg-cyan-200 text-cyan-700"}><FontAwesomeIcon icon={faPercentage} /></a>
                                        :
                                        <a href={"/app/guidebook/discountcodetopic/"+guideBook.uuid+"/"+topic.uuid} className={"rounded text-sm p-2 bg-gray-200 text-gray-700"}><FontAwesomeIcon icon={faPercentage} /></a>
                                    }

                                </Tooltip>
                            </div>


                            <DeleteByPath path={"/app/topic/remove/"+topic.uuid} setter={setTopics}  uuid={topic.uuid}></DeleteByPath>
                        </div>
                    </div>
                )}
                <div className={"grid lg:grid-cols-2 gap-5 items-stretch mt-5"}>
                    <a href={"/app/guidebook/addtopic/"+guideBook.uuid+"/"+category.uuid}
                       className={"shadow rounded border border-dashed border-cyan-400 w-full bg-white p-5  h-full flex flex-col items-center justify-center"}
                       style={{
                           backgroundImage: `url(${BgNew})`,
                           backgroundSize: 'cover',
                           backgroundPosition: 'center',
                       }}>
                        <div
                            className="relative size-[50px] shrink-0">
                            <svg class="w-full h-full stroke-1 stroke-cyan-600 fill-cyan-50" fill="none"
                                 height="48" viewBox="0 0 44 48" width="44"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
			18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
			39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z"
                                      fill="">
                                </path>
                                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
			18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
			39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z"
                                      stroke="">
                                </path>
                            </svg>
                            <div
                                class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 cursor-pointer">
                                <FontAwesomeIcon icon={faSquarePlus} className={"text-cyan-600"}/>
                            </div>
                        </div>
                        <div
                            className={"first-letter:uppercase text-lg font-medium text-gray-600 hover:text-primary-active mb-px mt-5"}>
                            {t('Ajouter un contenu')}
                        </div>
                    </a>

                <div
                    className={"shadow rounded border border-dashed border-green-400 w-full bg-white p-5  h-full flex flex-col items-center justify-center"}
                    style={{
                        backgroundImage: `url(${BgNew})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                    <a href={"/app/guidebook/addsubcategory/"+guideBook.uuid+"/" + uuid}
                       className="relative size-[50px] shrink-0">
                        <svg class="w-full h-full stroke-1 stroke-green-600 fill-green-50" fill="none"
                             height="48" viewBox="0 0 44 48" width="44"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
			18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
			39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z"
                                  fill="">
                            </path>
                            <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
			18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
			39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z"
                                  stroke="">
                            </path>
                        </svg>
                        <div
                            class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 cursor-pointer">
                            <FontAwesomeIcon icon={faSquarePlus} className={"text-green-600"}/>
                        </div>
                    </a>
                    <div
                        className={"first-letter:uppercase text-lg font-medium text-gray-600 hover:text-primary-active mb-px mt-5"}>
                        {t('Ajouter une sous catégorie')}
                    </div>
                </div>
                </div>


            </section>

        </Layout>
    )
}