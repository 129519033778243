import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";



export default function BreadCrumb(props) {
    const { t } = useTranslation();

    const lastIndex = props.links.length - 1;

    return (
        <div className={"flex flex-row gap-2 justify-start items-center mt-5 bg-gray-100 p-2"}>
            <a href={"/app/home"} className={"text-gray-600 text-xs  flex flex-row gap-2 items-center justify-start"}><FontAwesomeIcon icon={faHome} /> {t('Accueil')}</a>
            {props.links.map((link, index) =>
             <>
                <span className={"text-gray-600 text-xs"}> > </span>
                { index === lastIndex  && link.url === null? <span className={"text-gray-400 text-xs first-letter:uppercase"}>{link.name}</span> :
                <a href={link.url} className={"text-gray-600 text-xs first-letter:uppercase cursor-pointer"}>{link.name}</a> }
             </>
            )}

        </div>
    )
}