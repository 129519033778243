import {useParams} from "react-router-dom";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Layout from '../Layout';
import i18n from "../../../component/i18n";
import BreadCrumb from "./BreadCrumb";
import Meta from "../../../component/Meta";
import BgNew from "../../../asset/img/bg-4.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrows, faSpinner, faSquarePlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "flowbite-react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {
    DndContext,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    MouseSensor,
    TouchSensor,
    closestCorners
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates, SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import CategoryShow from "./categoryShow";

export default function CatList(props) {
    let { uuid } = useParams();

    const [categorys, setCategorys] = useState([]);
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const { t } = useTranslation();
    const meta = {
        title: t('Liste des catégories')
    }
    const LangId = localStorage.getItem('langId');
    const [guideBook, setGuideBook] = useState([]);

    const sensors = useSensors(
        useSensor(MouseSensor,{
            activationConstraint: {
                distance: 5,
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 300,
                tolerance: 8,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const getCategorysPos = (id) => categorys.findIndex((category) => category.id === id);

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id === over.id) return;
        setCategorys((categorys) => {
            const originalPos = getCategorysPos(active.id);
            const newPos = getCategorysPos(over.id);

            const updatedCategorys = arrayMove(categorys, originalPos, newPos);

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append('Authorization', 'Bearer ' + token)

            var requestOptionsGet = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            updatedCategorys.map((category, index) => {
                fetch(global.config.uriAPI+'/app/category/setposition/'+category.uuid+'/'+index, requestOptionsGet)
                    .then((response) => response.json())
                    .then((data) => {

                        }
                    );
            })

            return updatedCategorys;
        });

    };

    useEffect(() => {

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token)

        var requestOptionsGet = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/guidebook/show/'+uuid, requestOptionsGet)
            .then((response) => response.json())
            .then((data) => {
                setGuideBook(data)

                    var raw = JSON.stringify({"guideBookUuid":uuid});
                    var requestOptionsGet = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                    };

                    fetch(global.config.uriAPI+'/app/category/'+uuid, requestOptionsGet)
                        .then((response) => response.json())
                        .then((data) => {
                            setCategorys(data)

                        })
                        .catch((err) => {
                        });
            }
            );

        // faut les infos sur le guidebook



    },[LangId]);




    return (
        <Layout>
            <div class="container mx-auto">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            {t('Liste des catégories')}
                        </h1>
                        <div class="flex items-center gap-2 text-sm font-normal text-gray-700">
                            <BreadCrumb links={[{name: guideBook.name, url: "/app/guidebook/show/"+guideBook.uuid}, {name : t("Liste des Categories"), url: null}]} />
                        </div>
                    </div>
                </div>
            </div>

            <section className={"container mx-auto"}>
                <Meta meta={meta} />
                <div className=" mt-5 shadow rounded border border-gray-200 w-full bg-white p-5 ">
                    {categorys.length < 5  ?
                        <>
                            <div className={"text-yellow-600 bg-yellow-200 rounded p-5 m-5 "}><FontAwesomeIcon icon={faSpinner} spin={"true"} /> {t('Le Livret est en cours de création, merci de patienter le temps de récupérer les informations')}</div>
                        </>
                        : <></>}
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCorners}
                        onDragEnd={handleDragEnd}
                    >
                    <div className={"grid lg:grid-cols-2 gap-5 items-stretch"}>
                            <SortableContext
                                items={categorys}
                                sortingStrategy={verticalListSortingStrategy}
                            >
                                {categorys.map((category, index) =>
                                    <CategoryShow key={category.id} id={category.id} category={category}  guideBook={guideBook}  setter={setCategorys} index={index} categorys={categorys}/>
                                )}
                            </SortableContext>



                        <div className={"shadow rounded border border-dashed border-green-400 w-full bg-white p-5  h-full flex flex-col items-center justify-center"}
                             style={{
                                 backgroundImage: `url(${BgNew})`,
                                 backgroundSize: 'cover',
                                 backgroundPosition: 'center',
                             }}>
                            <a href={"/app/guidebook/addcategory/"+uuid} class="relative size-[50px] shrink-0">
                                <svg class="w-full h-full stroke-1 stroke-green-600 fill-green-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
			18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
			39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                    </path>
                                    <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
			18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
			39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                    </path>
                                </svg>
                                <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 cursor-pointer">
                                    <FontAwesomeIcon icon={faSquarePlus} className={"text-green-600"} />
                                </div>
                            </a>
                            <div className={"first-letter:uppercase text-lg font-medium text-gray-600 hover:text-primary-active mb-px mt-5"}>
                                {t('Ajouter une catégorie')}
                            </div>

                        </div>

                    </div>
                    </DndContext>
                </div>
            </section>

        </Layout>
    );
}