import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import i18n from "../../../component/i18n";
import Layout from "../Layout";
import {useParams} from "react-router-dom";
import BreadCrumb from "./BreadCrumb";
import Meta from "../../../component/Meta";
import UploadFile from "./uploadFile";
import UploadFileViewer from "./uploadFileViewer";
import DeleteMedia from "./function/DeleteMedia";
import BgNew from "../../../asset/img/bg-4.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage} from "@fortawesome/free-solid-svg-icons";


function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export default function Viewer() {

    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const lng = i18n.language;
    const { t } = useTranslation();
    const meta = {
        title: t('Génération pdf')
    }

    const [guideBooks, setGuideBooks] = useState([]);
    const [guideBookSelected, setGuideBookSelected] = useState(null);
    const [guideBookUuid, setGuideBookUuid] = useState(null);
    const [guideBookSelectedName, setGuideBookSelectedName] = useState(null);
    const [textPdf, setTextPdf] = useState(null);

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)

        var raw = JSON.stringify({"page":0,"limit": 100, "orderBy": [], 'name': ''});
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/guidebook/', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setGuideBooks(data);
            })
            .catch((err) => {
            });
    }, [] );

    useEffect(() => {
        // faut le garder en memoire

        if (guideBookUuid != null) {

            const info = guideBookUuid.split(":");
            setGuideBookSelectedName(info[1]);
            setTextPdf(guideBookSelectedName);
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append('Authorization', 'Bearer '+token)
            // on doit aller chercher les infos pdf de celui selectioner
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(global.config.uriAPI+'/app/viewerbuigdebook/show/'+info[0], requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    setGuideBookSelected(data)
                    if (data.link !== undefined) {
                        setLinkPdf(data.link);
                    } else {
                        setLinkPdf(null);
                    }

                    if (data.media !== null) {
                        setImageViewer(data);
                    }
                })
                .catch((err) => {
                });
        }
        //viewerbuigdebook
    }, [guideBookUuid] );

    const generatePdf = () => {
        const imageId = imageViewer.media.id;

        setLinkPdf(null);

        // $postData['guideBookUuid'],
        //             $postData['mediaId'],
        //             $postData['logoId'],
        //             $postData['title']
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)
        // on doit aller chercher les infos pdf de celui selectioner
        var raw = JSON.stringify({"guideBookUuid":guideBookUuid,"mediaId": imageId, "logoId": null, 'title': null});
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/viewerbuigdebook/create', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                // on propose le lien pour telecharger le pdf
                setLinkPdf(data.link)
            })
            .catch((err) => {
            });
    }

    const fileRefUpload = React.createRef()
    const [upload, setUpLoad] = useState(0);
    const [eventUpload, setEventUpload] = useState(null);
    const[reload, setReload] = useState(1);
    const [imageViewer, setImageViewer] = useState({media : {}});
    const [linkPdf, setLinkPdf] = useState(null);


    const  fileRefUploadLogo = React.createRef();
    const [uploadLogo, setUpLoadLogo] = useState(0);
    const [eventUploadLogo, setEventUploadLogo] = useState(null);
    const [logoViewer, setLogoViewer] = useState({media:{}});
    const[reloadLogo, setReloadLogo] = useState(1);

    const [overlay, setOverlay] = useState(60);
    const  contentUploadFile = (event) => {
        setUpLoad(1);
        setEventUpload(event);
    }

    const  contentUploadFileLogo = (event) => {
        setUpLoadLogo(1);
        setEventUploadLogo(event);
    }

    const changeRefUpload = () => {
        fileRefUpload.current.click();
    }

    const changeRefUploadLogo = () => {
        fileRefUploadLogo.current.click();
    }



    return (
        upload === 1 ?
            <UploadFileViewer event={eventUpload}  setUpload = {setUpLoad}  setReload={setReload}  setGuideBook={setImageViewer} guideBook={imageViewer} width={1280} height={600} ratio={16/9} title={t('Image de fond')}  url={"uploadContentImg"}/>
            :
        <Layout>
            <div class="container mx-auto">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <div class="flex items-center gap-2 text-sm font-normal text-gray-700">
                            <BreadCrumb links={[{name: t("Génération PDF"), url: null}]} />
                        </div>
                    </div>
                </div>
            </div>
            <section className={"container mx-auto mt-5 animate-fade delay-0 duration-150"}>
                <Meta meta={meta} />
                <div className={"mt-5 shadow rounded border border-gray-200 w-full bg-white p-5"}>
                    <div class={"flex w-full justify-center items-start"}>
                        <div className={"flex flex-col gap-5 w-full"}>
                            <select className={"rounded w-1/4 border border-gray-200 bg-gray-100 text-gray-700 font-semibold p-2 text-sm"} onChange={(e) => setGuideBookUuid(e.currentTarget.value)}>
                                <option value={""}>{t('Choisir un livret')}</option>
                                {guideBooks?.guideBook?.map((guideBook) => {
                                    return (
                                        guideBook.uuid === guideBookUuid ?
                                            <option value={guideBook.uuid} selected={"selected"}>{guideBook.name}</option>
                                            :
                                            <option value={guideBook.uuid} >{guideBook.name}</option>
                                    )
                                })}
                            </select>
                            {guideBookSelected != null ?<>
                                <div>

                                            {imageViewer?.media?.url !== undefined ?
                                                <div className={"relative  "}   >
                                                    <img src={global.config.uriAPI+"/"+imageViewer.media.url} className={"w-full h-full object-cover rounded"} />
                                                    <div className={"absolute top-5 right-5"}>
                                                        <DeleteMedia  setSetter={setImageViewer}  setter={imageViewer} uuid={imageViewer.media.uuid} />
                                                    </div>
                                                </div>
                                                :
                                                <div id="fileUpload" className="w-full">
                                                    <input type={"file"} hidden={true} ref={fileRefUpload} onChange={contentUploadFile} />
                                                    <div onClick={changeRefUpload} className={"shadow rounded border border-dashed border-green-400 w-full bg-white p-5  h-full flex flex-col items-center justify-center cursor-pointer"}
                                                         style={{
                                                             backgroundImage: `url(${BgNew})`,
                                                             backgroundSize: 'cover',
                                                         }}>
                                                        <div class="relative size-[50px] shrink-0">
                                                            <svg class="w-full h-full stroke-1 stroke-green-600 fill-green-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                                </path>
                                                                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                                </path>
                                                            </svg>
                                                            <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4">
                                                                <FontAwesomeIcon icon={faImage} className={"text-green-600"} />
                                                            </div>
                                                        </div>
                                                        <div className={"first-letter:uppercase text-lg font-medium text-gray-900 hover:text-primary-active mb-px mt-5"}>
                                                            {t('Ajouter une image de fond pour le PDF')}
                                                        </div>
                                                        <span class="text-2sm text-gray-700">
                                        {t('minimun 1280*600px format PNG, JPG , HEIC')}
                                   </span>
                                                    </div>
                                                </div>
                                            }

                                    {imageViewer?.media?.url !== undefined ?
                                            <div onClick={generatePdf}
                                                className="bg-cyan-700 text-white py-1 w-full rounded-md flex items-center justify-center p-5 mt-5 hover:cursor-pointer">
                                                {t('Générer le PDF')}
                                            </div>: <div
                                            className="border-2 border-gray-300 bg-gray-200 text-gray-700 py-1 w-full rounded-md flex items-center justify-center p-5 mt-5 hover:cursor-not-allowed">
                                            {t('Générer le PDF')}
                                        </div>}

                                    {linkPdf !== null ? <>
                                        <a href={global.config.uriAPI+linkPdf} target={"_blank"}  className="bg-green-500 text-white py-1 w-full rounded-md flex items-center justify-center p-5  hover:cursor-pointer mt-5">
                                            {t('Telecharger le pdf')}</a>
                                    </> :<></>}



                                </div>
                            </> : <></>}

                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}