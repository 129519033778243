import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faCircleCheck,
    faSquarePlus, faFileInvoice
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { Form } from "react-router-dom";
import i18n from "../../../component/i18n";
import  Meta  from "../../../component/Meta";
import * as amplitude from '@amplitude/analytics-browser';
import LogoAnime from "../../../asset/img/logoanime.gif";
import Layout from "../Layout";
import BreadCrumb from "./BreadCrumb";
import BgNew from "../../../asset/img/bg-4.png";
import CookieConsent from "react-cookie-consent";

export default function Abonnement(props) {
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';

    const { t } = useTranslation();
    const [user, setUser] = useState([]);
    const [plan, setPlan] = useState([]);
    const meta = {
        title: t('Abonnement Okurensio')
    }

    const authResult = new URLSearchParams(window.location.search);
    const abo = authResult.get('abo')

    const linkPortal = (event) => {


        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/plan/generatelink', requestOptions)
            .then((response) => {
                if(response.status != 200) throw new Error(response.status);
                else return response.json();
            })
            .then((data) => {
                window.location.href = data.url;
            })
            .catch((err) => {
            });

    }
    useEffect(() => {


        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/user/profile', requestOptions)
            .then((response) => {
                if(response.status != 200) throw new Error(response.status);
                else return response.json();
            })
            .then((data) => {
                setUser(data);
            })
            .catch((err) => {
            });


        fetch(global.config.uriAPI+'/app/plan/show', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setPlan(data);

               /* var sc = document.createElement("script");
                sc.setAttribute("defer", true);
                sc.setAttribute("src", "https://js.stripe.com/v3/pricing-table.js");
                document.getElementById("stripe").appendChild(sc);*/
            })
            .catch((err) => {
            });
    },[]);

    const onCalculprice = (event) => {
        var quantity = event.currentTarget.value;

        // Vérification de l'entrée
        if (quantity <= 0) {

            document.getElementById("calculPricing").innerHTML = 0;
        }

        // Déterminer le prix par tranche
        let pricePerUnit;

        if (quantity >= 1 && quantity <= 4) {
            pricePerUnit = 5.00;
        } else if (quantity >= 5 && quantity <= 8) {
            pricePerUnit = 4.80;
        } else if (quantity >= 9 && quantity <= 15) {
            pricePerUnit = 4.50;
        } else if (quantity >= 16 && quantity <= 40) {
            pricePerUnit = 4.10;
        } else if (quantity >= 41 && quantity <= 70) {
            pricePerUnit = 3.90;
        } else if (quantity >= 71 && quantity <= 100) {
            pricePerUnit = 3.70;
        } else if (quantity >= 101) {
            pricePerUnit = 3.40;
        } else {
            quantity = 0;
            pricePerUnit = 5;
            //document.getElementById("calculPricing").innerHTML = 0;
        }

        // Calculer le prix total
        const totalPrice = quantity * pricePerUnit;

        document.getElementById("calculPricing").innerHTML = pricePerUnit;

        var total =  totalPrice.toFixed(2); // Retourner le prix avec deux décimales
        document.getElementById("totalPricing").innerHTML = total + " € ht / "+t("mois");

    }

    const generateLinkAbo = (event) => {
            event.preventDefault();
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/plan/generatelinksession', requestOptions)
            .then((response) => {
                if(response.status != 200) throw new Error(response.status);
                else return response.json();
            })
            .then((data) => {
                window.location.href = data.url;
            })
            .catch((err) => {
            });
    }

    const sendInvit = (event) => {

        event.preventDefault();
        var emailInvit = document.getElementById("emailInvit").value;
        document.getElementById("buttonInvit").classList.add("hidden");
        document.getElementById("thanksInvit").classList.add("hidden");

        if (emailInvit !== "") {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append('Authorization', 'Bearer '+token)

            var requestOptions = {
                method: 'POST',
                body: JSON.stringify({"email": emailInvit}),
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(global.config.uriAPI+'/app/guidebook/affiliateuser', requestOptions)
                .then((response) => {
                    if(response.status != 200) throw new Error(response.status);
                    else return response.json();
                })
                .then((data) => {
                    document.getElementById("buttonInvit").classList.remove("hidden");
                    document.getElementById("thanksInvit").classList.remove("hidden");
                })
                .catch((err) => {
                });
        }

    }

    return (
        <Layout>
            <div class="container mx-auto">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            {t('Mon abonnement')}
                        </h1>
                        <div class="flex items-center gap-2 text-sm font-normal text-gray-700">
                            <BreadCrumb links={[{name: t('Abonnement'), url: ""} ]} />
                        </div>
                    </div>
                </div>
            </div>
            <section className={"container mx-auto"}>
                <Meta meta={meta} />

                {abo != undefined ? <>
                    <div className={"mt-4 p-5 bg-green-500 text-white rounded"}>{t('Féliciations votre abonnement à bien été pris en compte')}</div>
                </>: null}


                    {plan?.endPlan === 1 ?
                        <div class={"mt-5 shadow rounded border border-gray-200 w-full bg-white p-5 flex flex-row justify-start items-center gap-2"}>
                            <span><a href={"#"} onClick={linkPortal}  className={"text-cyan-600 underline text-sm"}>{t('Voir les factures / Modifier mon abonnement')}</a> </span>
                        </div>
                        :
                        (
                            plan?.isBuy === true ?
                                <><div class={"mt-5 shadow rounded border border-gray-200 w-full bg-white p-5 flex flex-row justify-start items-center gap-2"}
                                       style={{
                                           backgroundImage: `url(${BgNew})`,
                                           backgroundSize: 'contain',
                                           backgroundPosition: 'right',
                                           backgroundRepeat: 'no-repeat'
                                       }}
                                >
                                    <div>
                                        <div class="relative size-[50px] shrink-0">
                                            <svg class="w-full h-full stroke-1 stroke-blue-600 fill-blue-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
			18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
			39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                </path>
                                                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
			18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
			39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                </path>
                                            </svg>
                                            <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4">
                                                <FontAwesomeIcon icon={faFileInvoice} className={"text-blue-600"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"flex flex-col gap-2 w-full"}>
                                        <span className={"text-gray-700 font-semibold text-lg"}>{t('Vous avez un abonnement en cours')} </span>
                                        <div className={"flex flex-row gap-2 text-gray-600 items-center text-sm"}>
                                            <span className={"text-gray-600 text-sm"}>{t('Du')}</span> <span>{plan.dateStart}</span>
                                            <span>{t('Au')}</span><span>{plan.dateEnd}</span>
                                        </div>

                                        <span className={"text-gray-600 text-sm"}>{plan?.upcoming?.lines?.data[0]?.quantity} {t('livrets facturé au prix de ')} {plan?.upcoming?.lines?.data[0]?.unit_amount_excluding_tax/100}  {plan.currency} Ht </span>
                                        <span className={"text-gray-600 text-sm"}>{t('Prochain facture le')} {plan?.nextInvoice?.dateEnd}</span>
                                        <span><a href={"#"} onClick={linkPortal}  className={"text-cyan-600 underline text-sm"}>{t('Voir les factures / Modifier mon abonnement')}</a> </span>
                                    </div>
                                    <div className={"text-gray-600 text-xl font-bold w-64 flex items-center justify-center "}> {plan?.upcoming?.amount_due/100} {plan.currency} Ht </div>
                                </div>
                                </>
                                :<><div class={"mt-5 shadow rounded border border-gray-200 w-full bg-white p-5 flex flex-row justify-start items-center gap-2"}
                                        style={{
                                            backgroundImage: `url(${BgNew})`,
                                            backgroundSize: 'contain',
                                            backgroundPosition: 'right',
                                            backgroundRepeat: 'no-repeat'
                                        }}
                                >
                                    <div>
                                        <div class="relative size-[50px] shrink-0">
                                            <svg class="w-full h-full stroke-1 stroke-blue-600 fill-blue-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
			18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
			39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                </path>
                                                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
			18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
			39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                </path>
                                            </svg>
                                            <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4">
                                                <FontAwesomeIcon icon={faFileInvoice} className={"text-blue-600"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"flex flex-col gap-2 w-full"}>
                                        <span className={"text-gray-700 font-semibold text-lg"}>{t('Vous avez un abonnement gratuit en cours')} </span>
                                        <div className={"flex flex-row gap-2 text-gray-600 items-center text-sm"}>
                                            <span className={"text-gray-600 text-sm"}>{t('Du')}</span> <span>{plan.dateStart}</span>
                                            <span>{t('Au')}</span><span>{plan.dateEnd}</span>
                                        </div>

                                        <span className={"text-gray-600 text-sm"}>{plan.nbGuidebook} {t('livrets disponible par mois')}</span>
                                        <span className={"text-gray-600 text-sm text-cyan-600"}>{t('Souscrivez à un abonnement pour créer plus de livrets')}</span>

                                    </div>

                                </div>
                                </>
                        )


                    }
                    <>
                        <br />
                        <div className={"mt-5 shadow rounded border border-gray-200 w-full bg-white p-5  flex flex-col gap-5 "}>
                            <h2 className={"text-gray-700 font-semibold"}>{t('Obtenez des livrets gratuit en invitant vos amis')}</h2>
                            <p className={"text-gray-500 "}>{t("Chaque fois qu'un de vos invités souscrit et crée un livret, vous recevez 1 livret gratuit à vie")}</p>
                            <div className={"flex flex-col items-center justify-center w-full gap-4"}>
                                <input type={"email"} name={"emailInvit"}  id={"emailInvit"} className={"shadow border-gray-200 border rounded p-2 text-lg text-gray-500 w-1/2"} placeholder={t("Email")}/>
                                <button onClick={sendInvit} className={"rounded text-white bg-cyan-700 text-sm p-3"} id={"buttonInvit"}>{t('Envoyer une invitation')}</button>
                                <span className={"bg-green-500 p-5 text-white hidden rounded"} id={"thanksInvit"}>{t('Invitation envoyé')}</span>
                            </div>
                            {plan.nbFree > 0 ?
                                <div className={"text-gray-600"}>
                                    {t('Vous avez actuellement')} <span className={"font-semibold text-cyan-600"}>{plan.nbFree}</span> {t('livrets gratuit')}
                                </div>
                                : <></> }

                        </div>
                        <br />
                        <div className={"mt-5 shadow rounded border border-gray-200 w-full bg-white p-5  "}>
                            <section class="bg-white dark:bg-gray-900  min-h-screen  justify-center items-center flex flex-col gap-5">
                                <section class="bg-white dark:bg-gray-900">
                                    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                                        <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                                            <h1 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                                                {t('Vous payer uniquement le nombre de livrets créez ou actif chaque mois')}
                                            </h1>
                                        </div>
                                        <div className={"flex flex-row flex-wrap gap-10 items-center justify-center"}>
                                            <div className={"flex flex-col gap-2 justify-center items-center"}>
                                                <ul role="list" class="mb-8 space-y-4 text-left">
                                                    <li className="flex items-center space-x-3 text-xl text-gray-700">

                                                        <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                                        <span>{t('Génération automatique')}</span>
                                                    </li>
                                                    <li className="flex items-center space-x-3 text-xl text-gray-700">
                                                        <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                                        <span className={"text-green-500"}>{t('Régles d\entrée et de sortie')}</span>
                                                    </li>
                                                    <li className="flex items-center space-x-3 text-xl text-gray-700">
                                                        <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                                        <span>{t('Wifi et portail Captif')}</span>
                                                    </li>
                                                    <li className="flex items-center space-x-3 text-xl text-gray-700">
                                                        <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                                        <span>{t('Code de la boîte à clef')}</span>
                                                    </li>
                                                    <li className="flex items-center space-x-3 text-xl text-gray-700">



                                                        <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                                        <span className={"text-green-500"}>{t('Génération affiche A4 QRCode')}</span>
                                                    </li>
                                                    <li className="flex items-center space-x-3 text-xl text-gray-700">


                                                        <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                                        <span>{t('Catégories en illimitées')}</span>
                                                    </li>
                                                    <li className="flex items-center space-x-3 text-xl text-gray-700">


                                                        <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                                        <span>{t('Contenu illimités')}</span>
                                                    </li>
                                                    <li className="flex items-center space-x-3 text-xl text-gray-700">


                                                        <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                                        <span>{t('Statistiques')}</span>
                                                    </li>
                                                    <li className="flex items-center space-x-3 text-xl text-gray-700">


                                                        <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                                        <span>{t('QrCode pour les notices techniques')}</span>
                                                    </li>
                                                    <li className="flex items-center space-x-3 text-xl text-gray-700">


                                                        <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                                        <span className={"text-green-500"}>{t('Dupliquer un livret')}</span>
                                                    </li>
                                                    <li className="flex items-center space-x-3 text-xl text-gray-700">


                                                        <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                                        <span>{t('Livret avec theme couleur')}</span>
                                                    </li>
                                                    <li className="flex items-center space-x-3 text-xl text-gray-700">


                                                        <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                                        <span className={"text-green-500"}>{t('Traduction en plusieurs langues')}</span>
                                                    </li>
                                                    <li className="flex items-center space-x-3 text-xl text-gray-700">


                                                        <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                                        <span>{t('Voyageurs illimités')}</span>
                                                    </li>
                                                    <li className="flex items-center space-x-3 text-xl text-gray-700">


                                                        <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                                        <span>{t('Météo')}</span>
                                                    </li>

                                                </ul>

                                            </div>
                                            <div className={"flex flex-col gap-2 justify-center items-center"}>
                                                <div class={"flex flex-row items-center justify-center text-2xl text-gray-700 font-extrabold"}>
                                                    <span className={"text-7xl"} id={"calculPricing"}>5</span> € ht / {t('mois')} {t('le livret')} <sup className={"text-gray-600"}>*</sup>
                                                </div>
                                                <div className={"text-gray-500 font-semibold flex items-center justify-center text-xl mt-5"}>
                                                    {t(" Tarif dégressif selon le nombre de livrets")}
                                                </div>
                                                <div className={"flex flex-col gap-5 items-center justify-center bg-gray-100 rounded p-5"}>
                                                    <span className={"text-gray-600 text-xl"}>{t('Estimez votre tarif en fonction de vos besoins et utlisation')}</span>
                                                    <input  name={"nbLivret"} onChange={onCalculprice} type={"number"} step={1}  defaultValue={1} className={" shadow border-gray-200 border rounded p-5 text-2xl text-gray-500"} placeholder={"Nombre de livrets"}/>
                                                    <span className={"text-gray-600 text-2xl font-extrabold"} id={"totalPricing"}></span>
                                                    <a href="" onClick={generateLinkAbo}
                                                       className="text-white bg-cyan-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-2xl px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900">
                                                        {t('Souscrire à un abonnement')}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"text-gray-500 mt-5 container mx-auto  flex flex-col gap-5"}>
                                        <div>
                                            <sup>*</sup>{t('Tarification en fonction des livrets créer au cour du mois et eaxistant chaque mois')}<br/>
                                        </div>

                                        <table className="table-auto w-full border-collapse border border-gray-300">
                                            <thead>
                                            <tr class="bg-gray-200">
                                                <th className="border border-gray-300 px-4 py-2 text-left text-gray-700">{t("Tranche de Quantité")}
                                                </th>
                                                <th className="border border-gray-300 px-4 py-2 text-left text-gray-700">{t("Prix par livret (€) Ht")}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr class="odd:bg-white even:bg-gray-50">
                                                <td className="border border-gray-300 px-4 py-2">1 - 4</td>
                                                <td className="border border-gray-300 px-4 py-2">5,00 €</td>
                                            </tr>
                                            <tr class="odd:bg-white even:bg-gray-50">
                                                <td className="border border-gray-300 px-4 py-2">5 - 8</td>
                                                <td className="border border-gray-300 px-4 py-2">4,80 €</td>
                                            </tr>
                                            <tr class="odd:bg-white even:bg-gray-50">
                                                <td className="border border-gray-300 px-4 py-2">9 - 15</td>
                                                <td className="border border-gray-300 px-4 py-2">4,50 €</td>
                                            </tr>
                                            <tr class="odd:bg-white even:bg-gray-50">
                                                <td className="border border-gray-300 px-4 py-2">16 - 40</td>
                                                <td className="border border-gray-300 px-4 py-2">4,10 €</td>
                                            </tr>
                                            <tr class="odd:bg-white even:bg-gray-50">
                                                <td className="border border-gray-300 px-4 py-2">41 - 70</td>
                                                <td className="border border-gray-300 px-4 py-2">3,90 €</td>
                                            </tr>
                                            <tr class="odd:bg-white even:bg-gray-50">
                                                <td className="border border-gray-300 px-4 py-2">71 - 100</td>
                                                <td className="border border-gray-300 px-4 py-2">3,70 €</td>
                                            </tr>
                                            <tr class="odd:bg-white even:bg-gray-50">
                                                <td className="border border-gray-300 px-4 py-2">101 ou plus</td>
                                                <td className="border border-gray-300 px-4 py-2">3,40 €</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </section>
                                <CookieConsent
                                    location="bottom"
                                    buttonText={t('Accepter')}
                                    cookieName="okurensio"
                                    style={{ background: "#2B373B" }}
                                    buttonStyle={{ background: "#0097B2" , color: "#ffffff", fontSize: "13px" }}
                                    expires={150}
                                >
                                    {t('Nous utilisons des cookies pour améliorer votre expérience d\'utilisation')}.{" "}
                                    <span style={{ fontSize: "10px" }}></span>
                                </CookieConsent>

                            </section>
                        </div>


                    </>

            </section>
        </Layout>
    )
}