import Logo from '../asset/img/okurensiologo.svg';
import Loginimg from '../asset/img/loginimg.png';
import { Form } from "react-router-dom";
import Error from "../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import { Navbar } from 'flowbite-react';
import Lang from '../component/lang.jsx'
import  Meta  from "../component/Meta";


import Img1 from '../asset/img/architecture-2260836_640.jpg';
import Img2 from '../asset/img/hot-air-balloons-4561267_640.jpg';
import Img3 from '../asset/img/language-2345801_640.jpg';
import Img4 from '../asset/img/qrcode.jpg';
import Demo1 from '../asset/img/demo1.png';
import Demo2 from '../asset/img/demo2.png';
import Visit from '../asset/img/visit.png';
import Coeur from '../asset/img/coeur.png';
import CookieConsent from "react-cookie-consent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRight, faBars,
    faCheck,
    faCircle,
    faCircleArrowRight, faCircleCheck,
    faInfo,
    faQuestion,
    faTree
} from "@fortawesome/free-solid-svg-icons";
import mixpanel from "mixpanel-browser/src/loader-module";
import DetectLang from "./detectLang";

export default function Tarif() {


    const { t } = useTranslation();
    mixpanel.init(global.config.mixpanel, {debug: true, track_pageview: true, persistence: 'localStorage'});
    const font = {
        fontFamily: "'Noto Sans', sans-serif",
    }

    const meta = {
        title: t('Créer un un livret de bienvenue numérique pour vos locations saisonnières | tarifs'),
        description: t('Créer un un livret de bienvenue numérique pour vos locations saisonnières | tarifs' ),
        image: Demo1,
        font: "Noto+Sans:ital,wght@0,300;0,900;1,400"
    }

    const onCalculprice = (event) => {
        var quantity = event.currentTarget.value;

            // Vérification de l'entrée
            if (quantity <= 0) {

                document.getElementById("calculPricing").innerHTML = 0;
            }

            // Déterminer le prix par tranche
            let pricePerUnit;

            if (quantity >= 1 && quantity <= 4) {
                pricePerUnit = 5.00;
            } else if (quantity >= 5 && quantity <= 8) {
                pricePerUnit = 4.80;
            } else if (quantity >= 9 && quantity <= 15) {
                pricePerUnit = 4.50;
            } else if (quantity >= 16 && quantity <= 40) {
                pricePerUnit = 4.10;
            } else if (quantity >= 41 && quantity <= 70) {
                pricePerUnit = 3.90;
            } else if (quantity >= 71 && quantity <= 100) {
                pricePerUnit = 3.70;
            } else if (quantity >= 101) {
                pricePerUnit = 3.40;
            } else {
                quantity = 0;
                pricePerUnit = 5;
                //document.getElementById("calculPricing").innerHTML = 0;
            }

            // Calculer le prix total
            const totalPrice = quantity * pricePerUnit;

            document.getElementById("calculPricing").innerHTML = pricePerUnit;

            var total =  totalPrice.toFixed(2); // Retourner le prix avec deux décimales
        document.getElementById("totalPricing").innerHTML = total + " € ht / "+t("mois");

    }

    useEffect(() => {
        var sc = document.createElement("script");
        sc.setAttribute("defer",true);
        sc.setAttribute("src","https://dbwx2z9xa7qt9.cloudfront.net/bundle.js?cachebust=1706685231023");
        sc.setAttribute("theme","light");
        sc.setAttribute("footer", "true");
        sc.setAttribute("widget-type","carousel");
        sc.setAttribute("token","65b892009e2a72f323300085");
        sc.setAttribute('apiurl', "https://server.onlinereviews.tech/api/v0.0.9");
        sc.setAttribute('stats',"true");
        sc.setAttribute('addReview',"true");
        sc.setAttribute('profile-pic',"true");
        sc.setAttribute('review-name',"true");
        sc.setAttribute('wl', "true");
        sc.setAttribute('wlndig', "https://go.gestion-avis.fr/okurensio");
        sc.setAttribute('lang', "us");
        sc.setAttribute('brandStyle', "%7B%22sidebar_background%22%3A%22%23004AAD%22%2C%22sidebar_text%22%3A%22white%22%2C%22brand_button_text_color%22%3A%22white%22%2C%22brand_main_color%22%3A%22%23000000%22%2C%22brand_button_border_radius%22%3A%2220px%22%2C%22brand_button_hover%22%3A%22rgb(0%2C%200%2C%200)%22%2C%22brand_button_active%22%3A%22rgb(0%2C%200%2C%200)%22%2C%22brand_main_color_opacity%22%3A%22%230000001a%22%2C%22brand_font%22%3A%22Montserrat%22%2C%22star_color%22%3A%22%23128c7e%22%2C%22brand_main_background%22%3A%22%23F6F8F7%22%2C%22brand_card_background%22%3A%22white%22%2C%22poweredByLink%22%3A%22https%3A%2F%2Fgestion-avis.fr%22%2C%22poweredicon%22%3A%22https%3A%2F%2Frecensioni-io-static-folder.s3.eu-central-1.amazonaws.com%2Fpublic_onlinereviews%2Fapp.gestion-avis.fr%2Ftopbar.png%22%7D");
        document.getElementById("wid_1706685231023").appendChild(sc);

    }, []);

    const openMobileMenu = () => {
        const mobileMenu = document.getElementById('mobile-menu-2');
        if (mobileMenu.style.display === 'block') {
            mobileMenu.style.display = 'none';
        } else {
            mobileMenu.style.display = 'block';
        }
    }

    return (
       <>
           <Meta meta={meta} />
           <DetectLang />
           <header>
               <nav class="bg-white border-gray-200 dark:bg-gray-900 fixed w-full shadow">
                   <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 ">
                       <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                           <img src={Logo} className="h-8" alt="okurensio Logo"/>
                           <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white text-gray-700">Okurensio</span>
                       </a>
                       <button data-collapse-toggle="mobile-menu-2" type="button" onClick={openMobileMenu}
                               className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                               aria-controls="navbar-default" aria-expanded="false">
                           <span class="sr-only">Open main menu</span>
                           <FontAwesomeIcon icon={faBars} />
                       </button>
                       <div class="hidden w-full md:block md:w-auto" id="mobile-menu-2">
                           <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                               <li>
                                   <a href="/"
                                      className="block py-2 px-3 text-white bg-cyan-700 rounded md:bg-transparent md:text-cyan-700 md:p-0 dark:text-white md:dark:text-cyan-500"
                                      aria-current="page">{t('Accueil')}</a>
                               </li>
                               <li>
                                   <a href="/tarif"
                                      className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cyan-700 md:p-0 dark:text-white md:dark:hover:text-cyan-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">{t('Tarif')}</a>
                               </li>
                               <li>
                                   <a href="/login"
                                      className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cyan-700 md:p-0 dark:text-white md:dark:hover:text-cyan-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">{t('Mon compte')}</a>
                               </li>
                               <li>
                                   <a href="/register"
                                      className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cyan-700 md:p-0 dark:text-white md:dark:hover:text-cyan-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">{t('Inscription')}</a>
                               </li>
                               <li>
                                   <a href="/articles"
                                      className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cyan-700 md:p-0 dark:text-white md:dark:hover:text-cyan-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">{t('Guides')}</a>
                               </li>
                               <li>
                                   <Lang/>
                               </li>
                           </ul>
                       </div>
                   </div>
               </nav>
           </header>
           <section class="bg-white dark:bg-gray-900  min-h-screen  justify-center items-center flex flex-col gap-5">
               <section class="bg-white dark:bg-gray-900">
                   <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                       <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                           <h1 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                               {t('Créer votre livret d\'accueil pour vos locations saisonnières')}
                           </h1>
                           <p class="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
                               {t('Vous payer uniquement le nombre de livrets créez ou actif chaque mois')}
                           </p>
                       </div>
                       <div className={"flex flex-row flex-wrap gap-10 items-center justify-center"}>
                           <div className={"flex flex-col gap-2 justify-center items-center"}>
                               <ul role="list" class="mb-8 space-y-4 text-left">
                                   <li className="flex items-center space-x-3 text-xl text-gray-700">

                                       <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                       <span>{t('Génération automatique')}</span>
                                   </li>
                                   <li className="flex items-center space-x-3 text-xl text-gray-700">
                                       <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                       <span className={"text-green-500"}>{t('Régles d\entrée et de sortie')}</span>
                                   </li>
                                   <li className="flex items-center space-x-3 text-xl text-gray-700">
                                       <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                       <span>{t('Wifi et portail Captif')}</span>
                                   </li>
                                   <li className="flex items-center space-x-3 text-xl text-gray-700">
                                       <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                       <span>{t('Code de la boîte à clef')}</span>
                                   </li>
                                   <li className="flex items-center space-x-3 text-xl text-gray-700">



                                       <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                       <span className={"text-green-500"}>{t('Génération affiche A4 QRCode')}</span>
                                   </li>
                                   <li className="flex items-center space-x-3 text-xl text-gray-700">


                                       <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                       <span>{t('Catégories en illimitées')}</span>
                                   </li>
                                   <li className="flex items-center space-x-3 text-xl text-gray-700">


                                       <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                       <span>{t('Contenu illimités')}</span>
                                   </li>
                                   <li className="flex items-center space-x-3 text-xl text-gray-700">


                                       <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                       <span>{t('Statistiques')}</span>
                                   </li>
                                   <li className="flex items-center space-x-3 text-xl text-gray-700">


                                       <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                       <span>{t('QrCode pour les notices techniques')}</span>
                                   </li>
                                   <li className="flex items-center space-x-3 text-xl text-gray-700">


                                       <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                       <span className={"text-green-500"}>{t('Dupliquer un livret')}</span>
                                   </li>
                                   <li className="flex items-center space-x-3 text-xl text-gray-700">


                                       <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                       <span>{t('Livret avec theme couleur')}</span>
                                   </li>
                                   <li className="flex items-center space-x-3 text-xl text-gray-700">


                                       <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                       <span className={"text-green-500"}>{t('Traduction en plusieurs langues')}</span>
                                   </li>
                                   <li className="flex items-center space-x-3 text-xl text-gray-700">


                                       <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500"} />
                                       <span>{t('Voyageurs illimités')}</span>
                                   </li>

                               </ul>

                           </div>
                           <div className={"flex flex-col gap-2 justify-center items-center"}>
                               <div class={"flex flex-row items-center justify-center text-2xl text-gray-700 font-extrabold"}>
                                   <span className={"text-7xl"} id={"calculPricing"}>5</span> € ht / {t('mois')} {t('le livret')} <sup className={"text-gray-600"}>*</sup>
                               </div>
                               <div className={"text-gray-500 font-semibold flex items-center justify-center text-xl mt-5"}>
                                  {t(" Tarif dégressif selon le nombre de livrets")}
                               </div>
                               <div className={"flex flex-col gap-5 items-center justify-center bg-gray-100 rounded p-5"}>
                                   <span className={"text-gray-600 text-xl"}>{t('Estimez votre tarif en fonction de vos besoins et utlisation')}</span>
                                   <input  name={"nbLivret"} onChange={onCalculprice} type={"number"} step={1}  defaultValue={1} className={" shadow border-gray-200 border rounded p-5 text-2xl text-gray-500"} placeholder={"Nombre de livrets"}/>
                                    <span className={"text-gray-600 text-2xl font-extrabold"} id={"totalPricing"}></span>
                                   <a href="/register"
                                      className="text-white bg-cyan-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-2xl px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900">
                                       {t('Créer un livret')}</a>
                               </div>
                           </div>
                       </div>
                   </div>
                   <div className={"text-gray-500 mt-5 container mx-auto  flex flex-col gap-5"}>
                       <div>
                           <sup>*</sup>{t('Tarification en fonction des livrets créer au cour du mois et eaxistant chaque mois')}<br/>
                       </div>

                       <table className="table-auto w-full border-collapse border border-gray-300">
                           <thead>
                           <tr class="bg-gray-200">
                               <th className="border border-gray-300 px-4 py-2 text-left text-gray-700">{t("Tranche de Quantité")}
                               </th>
                               <th className="border border-gray-300 px-4 py-2 text-left text-gray-700">{t("Prix par livret (€) Ht")}
                               </th>
                           </tr>
                           </thead>
                           <tbody>
                           <tr class="odd:bg-white even:bg-gray-50">
                               <td className="border border-gray-300 px-4 py-2">1 - 4</td>
                               <td className="border border-gray-300 px-4 py-2">5,00 €</td>
                           </tr>
                           <tr class="odd:bg-white even:bg-gray-50">
                               <td className="border border-gray-300 px-4 py-2">5 - 8</td>
                               <td className="border border-gray-300 px-4 py-2">4,80 €</td>
                           </tr>
                           <tr class="odd:bg-white even:bg-gray-50">
                               <td className="border border-gray-300 px-4 py-2">9 - 15</td>
                               <td className="border border-gray-300 px-4 py-2">4,50 €</td>
                           </tr>
                           <tr class="odd:bg-white even:bg-gray-50">
                               <td className="border border-gray-300 px-4 py-2">16 - 40</td>
                               <td className="border border-gray-300 px-4 py-2">4,10 €</td>
                           </tr>
                           <tr class="odd:bg-white even:bg-gray-50">
                               <td className="border border-gray-300 px-4 py-2">41 - 70</td>
                               <td className="border border-gray-300 px-4 py-2">3,90 €</td>
                           </tr>
                           <tr class="odd:bg-white even:bg-gray-50">
                               <td className="border border-gray-300 px-4 py-2">71 - 100</td>
                               <td className="border border-gray-300 px-4 py-2">3,70 €</td>
                           </tr>
                           <tr class="odd:bg-white even:bg-gray-50">
                               <td className="border border-gray-300 px-4 py-2">101 ou plus</td>
                               <td className="border border-gray-300 px-4 py-2">3,40 €</td>
                           </tr>
                           </tbody>
                       </table>
                   </div>
               </section>
           <CookieConsent
               location="bottom"
               buttonText={t('Accepter')}
               cookieName="okurensio"
               style={{ background: "#2B373B" }}
               buttonStyle={{ background: "#0097B2" , color: "#ffffff", fontSize: "13px" }}
               expires={150}
           >
               {t('Nous utilisons des cookies pour améliorer votre expérience d\'utilisation')}.{" "}
               <span style={{ fontSize: "10px" }}></span>
           </CookieConsent>

           </section>
           <section className={"container mx-auto"}>
               <div id={"wid_1706685231023"}></div>

           </section>
           <div className={"bg-cyan-700 w-full mt-5"}>
                <div className={"container mx-auto justify-center items-center flex p-5"}>
                    <span className={"text-white font-semibold text-xl"}>Okurensio</span>
                </div>
           </div>
       </>
    );
}