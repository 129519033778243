import React, {useState}  from 'react';
import {render} from "@testing-library/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "flowbite-react";
import {useTranslation} from "react-i18next";
import OpenModal from "./ModalDelete";
export default function DeletePath(props) {

    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);

    console.log(props);
    const updateSetter = ()  =>{
        //props.setter.media=null;
        //props.setter({...props.setter});
        //on retire l'élément supprimé
        props.setter(oldValues => {
            return oldValues.filter(el => el.uuid !== props.uuid)
        })
    }

    return (
        <>
            <Tooltip content={t('Supprimer')}>
                <a href={"#"} className={"bg-red-600 text-white text-xs p-2 rounded"}  onClick={ () => {setOpenModal(true); } }  >
                    <FontAwesomeIcon icon={faTrash} className={""}></FontAwesomeIcon>
                </a>
            </Tooltip>
            {openModal === true ?
                <OpenModal path={props.path}  setOpenModal={setOpenModal} callbackFunction={updateSetter} />
                : null}
        </>
    )

}