import React,{Component}  from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCircleCheck, faCircleExclamation, faInfo} from "@fortawesome/free-solid-svg-icons";

function Error(props)
{
    const type = props.typeError;

    if (type === '') {
        return (
            <div></div>
        )
    }
    /*if (type === '') {
        return (
            <div></div>
        )
    }*/
    if (type === 'warning') {
        return (
            <div className="py-4 lg:px-4 ">
                <div className="p-2 bg-amber-600 text-white leading-none  rounded"
                     role="alert">
                    <span
                        className="font-semibold  m-1 text-sm text-justify"><FontAwesomeIcon icon={faCircleExclamation} />  {props.message}</span>
                </div>
            </div>
        )
    }

    if (type === 'danger') {
        return (
            <div className="py-4 lg:px-4 ">
                <div className="p-2 bg-red-600 text-white leading-none   rounded"
                     role="alert">
                    <span
                        className="font-semibold  m-1 text-sm text-justify"><FontAwesomeIcon icon={faCircleExclamation} />  {props.message}</span>
                </div>
            </div>
        )
    }

    if (type === 'info') {
        return (
            <div className="py-4 lg:px-4 ">
                <div className="p-2 bg-blue-600 text-white leading-none   rounded"
                     role="alert">
                    <span
                        className="font-semibold  m-1 text-sm text-justify"><FontAwesomeIcon icon={faInfo} />  {props.message}</span>
                </div>
            </div>
        )
    }

    if (type === 'success') {
        return (
            <div className="py-4 lg:px-4 ">
                <div className="p-2 bg-lime-600 text-white leading-none   rounded"
                     role="alert">
                    <span
                        className="font-semibold  m-1 text-sm text-justify"><FontAwesomeIcon icon={faCircleCheck} />  {props.message}</span>
                </div>
            </div>
        )
    }


}

export default Error;