import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faEdit,
    faHome,
    faCircleInfo, faLocationDot, faSearch, faAdd
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {Form, useParams} from "react-router-dom";
import i18n from "../../../component/i18n";
import  Meta  from "../../../component/Meta";
import {Breadcrumb, FileInput, Label} from "flowbite-react";
import DiscountCodeNew from './discountCode/new';
import DiscountCodeEdit from './discountCode/edit';
import BreadCrumb from "./BreadCrumb";
import Layout from "../Layout";
import UploadFile from "./uploadFile";


export default function DiscountCode(props) {
    const MAX_FILE_SIZE = 5120 // 5MB
    let { guideBookUuid, uuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const [contentType, setContentType] = useState('');

    const { t } = useTranslation();
    const meta = {
        title: t('Ajouter un code de promotion')
    }
    const [discountCodeMode, setDiscountCodeMode] = useState('');
    const [discountCode, setDiscountCode] = useState([]);
    const [discountCodeMedia, setDiscountCodeMedia] = useState({media : {}});

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(global.config.uriAPI+'/app/discountcode/show/'+uuid, requestOptions)
            .then((response) =>  response.json() )
            .then((data) => {

                if (data.error === 0) {
                    setDiscountCode(data);
                    discountCodeMedia.media = data.media;
                    setDiscountCodeMedia( {...discountCodeMedia});
                    console.log(discountCodeMedia);
                    //console.log(discountCodeMedia);
                    setDiscountCodeMode('update');
                }else {
                    setDiscountCodeMode('add');
                }

            })
            .catch((err) => {
                setDiscountCodeMode('add');
            });
    }, []);


    const fileRefUpload = React.createRef()
    const [upload, setUpLoad] = useState(0);
    const [eventUpload, setEventUpload] = useState(null);
    const[reload, setReload] = useState(1);
    const  contentUploadFile = (event) => {
        setUpLoad(1);
        setEventUpload(event);
    }
    const changeRefUpload = () => {
        fileRefUpload.current.click();
    }

    console.log(discountCodeMedia);

    return (
        upload === 1 ?
            <UploadFile event={eventUpload}  setUpload = {setUpLoad}  setReload={setReload}  setGuideBook={setDiscountCodeMedia} guideBook={discountCodeMedia}  title={t('Image du bon de réduction')}  url={"uploadContentImg"}/>
            :
        <Layout>
            <div class="container mx-auto">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900 first-letter:uppercase">

                        </h1>
                        <div class="flex items-center gap-2 text-sm font-normal text-gray-700">
                            <BreadCrumb links={[{name: t("Revenir en arrière"), url: document.referrer}]} />
                        </div>
                    </div>
                </div>
            </div>

            <section className={"container mx-auto"}>

            <div className={"mt-5 shadow rounded border border-gray-200 w-full bg-white p-5"}>
                {  discountCodeMode === "add" ? <DiscountCodeNew  topicUuid={uuid} guideBookUuid={guideBookUuid}  discountCodeMedia={discountCodeMedia} setDiscountCodeMedia={setDiscountCodeMedia} fileRefUpload={fileRefUpload}  contentUploadFile={contentUploadFile} changeRefUpload={changeRefUpload}></DiscountCodeNew>: <></>}
                {  discountCodeMode === "update" ? <DiscountCodeEdit  topicUuid={uuid} guideBookUuid={guideBookUuid} discountCodeMedia={discountCodeMedia} setDiscountCodeMedia={setDiscountCodeMedia} fileRefUpload={fileRefUpload}  contentUploadFile={contentUploadFile} changeRefUpload={changeRefUpload}></DiscountCodeEdit>: <></>}
            </div>
        </section>
        </Layout>
    )
}