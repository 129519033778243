import Error from "../../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../../asset/img/loginimg.png';
import Lang from '../../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faEdit,
    faHome,
    faCircleInfo, faLocationDot, faSearch, faAdd, faCalendar
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {Form, useParams} from "react-router-dom";
import i18n from "../../../../component/i18n";
import  Meta  from "../../../../component/Meta";
import {FileInput, Label, Tooltip} from "flowbite-react";
import FontPicker from "react-fontpicker-ts";
import {TwitterPicker} from "react-color";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import MyCustomUploadAdapterPlugin from "../../../../component/PluginUploader";
import Tags from "./tags";
import BgNew from "../../../../asset/img/bg-4.png";
import DeletePath from "../function/DeleteByPath";


export default function TopicEditPlace(props) {
    const MAX_FILE_SIZE = 5120 // 5MB
    const lng = i18n.language;
    const [infoPlace, setInfoPlace] = useState(null);
    let { guideBookUuid, subCategoryUuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const [contentType, setContentType] = useState('content');
    const [button, setButton] = useState({disabled : '', loader: ''});

    const [loadData, setLoadData] = useState(false);
    const [summary, setSummary] = useState(props.topic.content);
    const [contentOwner, setContentOwner] = useState(props.topic.contentOwner);
    const { t } = useTranslation();
    const meta = {
        title: t('Ajouter un contenu')
    }
    const [inputs, setInputs] =  useState({ logo: '', name: props.topic.title});

    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { errors },
    } = useForm();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }
    const [iconButton, setIconButton] = useState({icon : <FontAwesomeIcon icon={faAdd} />});
    const [tagsSelected, setTagsSelected] = useState(props.topic.tagsCode);
    const [event, setEvent] = useState([]);
    const onSubmitContent = (data) => {

        setButton(previousState => {
            return { ...previousState, disabled: 'disabled' }
        });
        setIconButton(previousState => {
            return { ...previousState, icon:  <FontAwesomeIcon icon={faSpinner} spin/>}
        });

        const myHeaders = new Headers();
        //myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);
        //myHeaders.append('Content-Type', 'Multipart/form-data');

        let formData = new FormData()

       /* let fileLogo = document.querySelector('#logo').files[0];
        if (fileLogo) {
            const fileSizeKiloBytesLogo = fileLogo.size / 1024
            const extensionLogo = fileLogo.type;
            console.log(fileLogo.width);

            if (['image/png', 'image/jpg', 'image/jpeg'].includes(extensionLogo) === false) {
                setError('logo', { type: 'error', message: t('Le fichier doit être au format png, jpg ou jpeg') });
                return;
            }

            if(fileSizeKiloBytesLogo > MAX_FILE_SIZE){
                setError('logo', { type: 'size', message: t('Le fichier est trop volumineux') });
                return
            }
        }*/

        setLoadData(false);

       // formData.append('logo', fileLogo);
        formData.append('title', inputs.name);
        formData.append('content', summary);
        formData.append('contentOwner', contentOwner);
        formData.append("tags", tagsSelected)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI + '/app/topicplace/update/'+props.topic.uuid, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                window.location.href = document.referrer;
                return;
            })
            .catch((err) => {
            });



    }

    useEffect(() => {

        const myHeaders = new Headers();
        //myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI + '/app/event/view/'+props.topic.uuid+"/topic", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setEvent(data);
            })
            .catch((err) => {
            });
    }, []);


    const validLogo = () => {
        let fileData = document.querySelector('#logo').files[0];
        const extension = fileData.type;

        if (['image/png', 'image/jpg', 'image/jpeg'].includes(extension) === false) {
            setError('logo', { type: 'error', message: t('Le fichier doit être au format png, jpg ou jpeg') });
            return
        }
        setError('logo', null);
    }

    return (
        <>
        <Form action="" method="post" onSubmit={handleSubmit(onSubmitContent)} noValidate id="formCreate" className={"flex flex-col gap-5 mt-5"}>
            <b><span className={"first-letter:uppercase text-gray-700"}>{inputs.name}</span> </b>
            <div id="fileUpload" className="w-full">
                <div className="mb-2 block">
                    <Label htmlFor="file" className={"block text-sm mb-2 text-gray-700 font-semibold"} value={t('Liste des images')} />
                </div>
                {props.topic?.mediaLibrary !== undefined ? <div className={"flex gap-2 flex-row"}>
                    {props.topic?.mediaLibrary.map((media) =>
                        <>
                            <img src={global.config.uriAPI+"/"+media.url} className={"w-20 h-20 object-cover rounded"} />
                        </>
                    )}
                </div>: <></>}
            </div>
            <div className="mt-3 w-full">
                <label htmlFor="name" className={ errors.name ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2 text-gray-700 font-semibold"}>{t('Le nom')}</label>
                <input type="text" id="name"
                       name ="name"
                       aria-invalid={errors.name ? "true" : "false"}
                       defaultValue={inputs.name|| ""}
                       {...register("name", { onChange: handleChange, required: true})}
                       className= { errors.name ?
                           "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                           "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                       }
                       placeholder={t("Nom de la catégorie")}/>
                {errors.name && errors.name.type === "required" && (
                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                )}
            </div>
            <div className="mt-3 overflow-auto max-h-96">
                <label className={ errors.summary ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2 text-gray-700 font-semibold"}>{t('La description du lieu')}</label>
                <CKEditor
                    editor={ ClassicEditor }
                    config = {{
                        extraPlugins: [ MyCustomUploadAdapterPlugin ],
                        placeholder: t('Description'),

                    }}
                    type="text"
                    name="summary"
                    data={summary}
                    onChange={ ( event, editor ) => { setSummary(editor.getData())} }
                />
                {errors.summary && errors.summary.type === "required" && (
                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                )}
            </div>
            <div className="mt-3 bg-cyan-600 rounded p-2 ">
                <label className={  "block text-sm mb-2  text-white font-semibold"}>{t('Vos conseils / Astuces / Bon plans')}</label>
                <CKEditor
                    editor={ ClassicEditor }
                    config = {{
                        extraPlugins: [ MyCustomUploadAdapterPlugin ],
                        placeholder: t('Description'),

                    }}
                    type="text"
                    name="contentOwner"
                    data={contentOwner}
                    onChange={ ( event, editor ) => { setContentOwner(editor.getData())} }
                />
            </div>
            <div className={"flex flex-col gap-5"}>
                <span className={"text-xs"}><b className={"text-gray-600 font-semibold"}>{t('Adresse')}</b> :  {props.topic.address} </span>
                <span className={"text-xs"}>{props.topic.uri !== '' ? <div><b className={"text-gray-600 font-semibold"}>{t('Site internet')}</b> : {props.topic.uri}</div>: <></>}</span>
                <span className={"text-xs"}> {props.topic.phoneNumber !== '' ? <div><b className={"text-gray-600 font-semibold"}>{t('Télèphone')}</b> : {props.topic.phoneNumber}</div>: <></>}</span>
            </div>
            <Tags tags={props.tags}  tagsSelected={tagsSelected} setTagsSelected={setTagsSelected}/>


            <button type="submit" disabled={button.disabled}
                    className="border-2 border-cyan-700 bg-cyan-500 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-cyan-700 font-semibold">
                {iconButton.icon}&nbsp;&nbsp; {t('Modifier')}
            </button>
        </Form>
            <div className={"mt-5"}>
                <a href={"/app/guidebook/addevent/topic/"+props.topic.uuid+"/"+guideBookUuid}
                   className={"shadow rounded border border-dashed border-cyan-400 w-full bg-white p-5  h-full flex flex-col items-center justify-center"}
                   style={{
                       backgroundImage: `url(${BgNew})`,
                       backgroundSize: 'cover',
                       backgroundPosition: 'center',
                   }}>
                    <div
                        className="relative size-[50px] shrink-0">
                        <svg class="w-full h-full stroke-1 stroke-cyan-600 fill-cyan-50" fill="none"
                             height="48" viewBox="0 0 44 48" width="44"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
			18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
			39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z"
                                  fill="">
                            </path>
                            <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
			18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
			39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z"
                                  stroke="">
                            </path>
                        </svg>
                        <div
                            class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 cursor-pointer">
                            <FontAwesomeIcon icon={faCalendar} className={"text-cyan-600"}/>
                        </div>
                    </div>
                    <div
                        className={"first-letter:uppercase text-lg font-medium text-gray-600 hover:text-primary-active mb-px mt-5"}>
                        {t('Ajouter un évènement')}
                    </div>
                </a>
            </div>
            <div className={"mt-5"}>
                {event.map((item) =>
                    <div className={"mt-2 shadow rounded border border-gray-200 w-full bg-white p-5 flex flex-row justify-between gap-2"}>
                        <div className={"flex flex-col gap-2"}>
                            <div className={"flex flex-row gap-2 items-center"}>
                                <Tooltip content={ t('Modifier')}>
                                    <a href={"/app/guidebook/updateEvent/"+item.uuid+"/"+guideBookUuid} className={"bg-gray-200 text-gray-700 text-sm p-2 rounded"}><FontAwesomeIcon icon={faEdit} /></a>
                                </Tooltip>
                                <h2 className={"font-bold text-gray-700 text-sm first-letter:uppercase"}>{item.name}</h2>
                            </div>

                            <div className={"flex flex-row gap-2 text-gray-600 text-xs items-center"}>
                                <span className={"text-gray-600 text-sm"}>{item.dateStart}</span> / <span className={"text-gray-600 text-sm"}>{item.dateEnd}</span>
                            </div>
                        </div>

                        <DeletePath  path={"/app/event/delete/"+item.uuid} setter={setEvent}  uuid={item.uuid}/>
                    </div>
                )}
            </div>
            </>
    )

}