
import React, {useState, useEffect} from "react";

import {useTranslation} from "react-i18next";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import heic2any from "heic2any";
import {faImage, faClose, faCircleCheck
} from "@fortawesome/free-solid-svg-icons";

import Cropper from 'react-easy-crop'
import axios from "axios";
import Compressor from 'compressorjs';
export const createImage = url =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', error => reject(error));
        image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
    });

export function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation);

    return {
        width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)
    };
}

const getCroppedImg = async (
    imageSrc,
    pixelCrop = { x: 0, y: 0 },
    rotation = 0,
    flip = { horizontal: false, vertical: false }
) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        return null;
    }

    const rotRad = getRadianAngle(rotation);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0);

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise(resolve => {
        canvas.toBlob(file => {
            resolve({ file, url: URL.createObjectURL(file) });
        }, 'image/jpeg');
    });
};

export default function UploadFile(props) {

    const [event, setEvent] = useState(props.event);
    const { t } = useTranslation();
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const fileRefUpload = React.createRef()
    const [cropSize, setCropSize] = useState({ width: 800, height: 300 })
    const [loadImage, setLoadImage] = useState(0);
    const [image, setImage] = useState(null);
    const [sizeImage , setSizeImage] = useState(null);
    const [uploadInState, setUploadInState] = useState(0);
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const [croppedArea, setCroppedArea] = useState(null);
    const [progress, setProgress] = useState(0);
    const close = () => {

        props.setUpload(0);

    }
    const changeRefUpload = () => {
        fileRefUpload.current.click();
    }

    const uploadImage = () => {
        setUploadInState(1);

        // on envoi la demande d'upload fu fichier
        const myHeaders = new Headers();
        //myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);

        getCroppedImg(image, croppedArea, 0).then((newImageCrop) => {

            let formData = new FormData()

            var fileSize = newImageCrop.file.size;  // Taille du fichier en octets (bytes)
            var fileSizeInKB = fileSize / 1024;

            if (fileSizeInKB > 2000) {

                new Compressor(newImageCrop.file, {
                    quality: 0.6,
                    convertSize: 2000000,
                    // The compression process is asynchronous,
                    // which means you have to access the `result` in the `success` hook function.
                    success(result) {
                       // const formData = new FormData();
                        // The third parameter is required for server
                        //formData.append('file', result, result.name);
                        formData.append('image', result, 'image.png');
                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formData,
                            redirect: 'follow'
                        };

                        if (props.uuid === undefined ) {
                            const   url = global.config.uriAPI + '/app/media/'+props.url;
                            axios.post(
                                url,
                                formData,
                                {
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                        'Authorization': 'Bearer ' + token,
                                    },
                                    onUploadProgress: (progressEvent) => {
                                        const percentCompleted = Math.round(
                                            (progressEvent.loaded * 100) / progressEvent.total
                                        );
                                        setProgress(percentCompleted);
                                    }
                                }
                            ).then((response) => {

                                props.guideBook.media=response.data;

                                props.setGuideBook({...props.guideBook});
                                setTimeout(() => {  close() }, 1500);
                                //close();
                            });
                        } else {
                            const  url = global.config.uriAPI + '/app/media/'+props.url+'/'+props.uuid;
                            axios.post(
                                url,
                                formData,
                                {
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                        'Authorization': 'Bearer ' + token,
                                    },
                                    onUploadProgress: (progressEvent) => {
                                        const percentCompleted = Math.round(
                                            (progressEvent.loaded * 100) / progressEvent.total
                                        );
                                        setProgress(percentCompleted);
                                    }
                                }
                            ).then((response) => {

                                props.guideBook.media=response.data;

                                props.setGuideBook({...props.guideBook});
                                setTimeout(() => {  close() }, 1500);
                                //close();
                            });
                        }
                    },
                    error(err) {
                        //console.log(err.message);
                    },
                });
            } else {

                formData.append('image', newImageCrop.file, 'image.png');
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formData,
                    redirect: 'follow'
                };

                if (props.uuid === undefined ) {
                    const   url = global.config.uriAPI + '/app/media/'+props.url;
                    axios.post(
                        url,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Authorization': 'Bearer ' + token,
                            },
                            onUploadProgress: (progressEvent) => {
                                const percentCompleted = Math.round(
                                    (progressEvent.loaded * 100) / progressEvent.total
                                );
                                setProgress(percentCompleted);
                            }
                        }
                    ).then((response) => {

                        props.guideBook.media=response.data;

                        props.setGuideBook({...props.guideBook});
                        setTimeout(() => {  close() }, 1500);
                        //close();
                    });
                } else {
                    const  url = global.config.uriAPI + '/app/media/'+props.url+'/'+props.uuid;
                    axios.post(
                        url,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Authorization': 'Bearer ' + token,
                            },
                            onUploadProgress: (progressEvent) => {
                                const percentCompleted = Math.round(
                                    (progressEvent.loaded * 100) / progressEvent.total
                                );
                                setProgress(percentCompleted);
                            }
                        }
                    ).then((response) => {

                        props.guideBook.media=response.data;

                        props.setGuideBook({...props.guideBook});
                        setTimeout(() => {  close() }, 1500);
                        //close();
                    });
                }
            }





        });
    }

    useEffect(() => {
        /*var file = event.target.files[0];
        var fileSize = file.size;  // Taille du fichier en octets (bytes)
        var fileSizeInKB = fileSize / 1024;

        if (fileSizeInKB > 2000) {

        }*/
        if (event.target.files && event.target.files.length > 0) {
            setLoadImage(1);

            if(event.target.files[0] && event.target.files[0].name.includes(".HEIC")){
                // get image as blob ur
                var blob = event.target.files[0];
                heic2any({
                    blob: blob,
                    toType: "image/png",
                })
                    .then(function (resultBlob) {

                        const reader = new FileReader();
                        reader.readAsDataURL(resultBlob);
                        reader.addEventListener("load", () => {
                            setLoadImage(0);
                            setImage(reader.result);
                        });
                    })
                    .catch(function (x) {
                    });
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.addEventListener("load", () => {
                    setLoadImage(0);
                    setImage(reader.result);
                });
                var img = new Image();
                img.src = window.URL.createObjectURL( event.target.files[0]);
                img.onload = function()
                {
                    var width = this.naturalWidth,
                        height = this.naturalHeight;

                    if (width > 0) {
                        if (width < 800 || height < 400) {
                            setSizeImage(1);

                        } else {
                            setSizeImage(0);
                        }
                    }

                };
            }
        }
    }, [event]);

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    }



    return (
        uploadInState === 1 ?
            <div className={"flex justify-center items-center min-h-screen p-10 flex-col gap-5"}>
                {progress < 100 ? <><span className={"text-gray-700 font-semibold text-xl"}>{t('Enregistrement de l\'image en cours')}</span>
                    <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                        <div class="bg-cyan-600 h-2.5 rounded-full" style={{
                            width: progress + "%"
                        }}></div>
                    </div></>:
                <div className={"flex flex-col gap-5 justify-center items-center"}>
                    <FontAwesomeIcon icon={faCircleCheck} className={"text-green-500 fa-4x"}/>
                    <span className={"text-gray-700 font-semibold text-2xl"}>{t('Votre fichier à bien été enregistré')}</span>
                    <span className={"text-gray-600 text-xs"}>{t('Vous allez être redirigé automatiquement')}</span>
                </div>
                }
            </div>

            :
            <div className={"animate-fade delay-0 duration-150"}>
                <div className={"flex justify-center items-center flex-col top-0 right-0 left-0  bottom-0 min-h-screen" }>
                    <div className={"absolute top-0 right-20 flex items-center "}>
                        <div className={"flex  items-center gap-10"}>
                            <span className={"text-gray-700 font-semibold right-15"}>{props.title}</span>
                            <FontAwesomeIcon icon={faClose} onClick={close} className={"rounded-full flex items-center justify-center bg-gray-300 text-gray-500 p-2 cursor-pointer"}/>
                        </div>

                    </div>
                    <div className={" absolute top-10 right-0 left-0 bottom-[80px] "}>
                        {loadImage === 1 ?
                            <div className={"flex justify-center items-center flex-col top-52 mt-10"}>
                                <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                </svg>
                                <h2 className={"text-gray-700"}>{t('Chargement de l\'image, merci de patienter')}</h2>
                            </div>
                            :
                            <>

                                <Cropper
                                    image={image}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={800 / 400}
                                    zoomWithScroll = {false}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}

                                />
                            </>
                        }


                    </div>
                    <div className={"absolute bottom-[20px] flex justify-between items-center"}>
                        <input type={"file"} hidden={true} ref={fileRefUpload} onChange={setEvent} />
                        <FontAwesomeIcon icon={faImage} onClick={changeRefUpload} className={"text-gray-600 mr-20 cursor-pointer"}/>
                        <input
                            type="range"
                            value={zoom}
                            min={-5}
                            max={5}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e) => {
                                setZoom(e.target.value)
                            }}
                            className={"  accent-cyan-700 "}
                        />
                        {sizeImage === 1 ?
                            <div className={"text-red-600 ml-20"}>{t("L'image n'a pas les dimension minimun de 800*400 px")}</div>
                            :
                            loadImage === 0 ?
                                <a href={"#"} onClick={uploadImage} className={"rounded p-2 bg-green-50 text-green-700 text-sm ml-20 border border-green-500"}>{t('Sauvegarder l\'image')}</a>
                                : null
                        }
                    </div>
                </div>
            </div>

    )
}
