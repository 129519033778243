import Layout from '../Layout';
import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import BgCreate from '../../../asset/img/create4.png';
import BgWifi from '../../../asset/img/bg-wifi.png';
import Create from '../../../asset/img/bgcreate.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import thOkurensio from '../../../asset/img/th-okurensio.png';
import thMer from '../../../asset/img/th-mer.png';
import thCampagne from '../../../asset/img/th-campagne.png';
import thMontagne from '../../../asset/img/th-montagne.png';
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faWarning,
    faDoorOpen,
    faInfo,
    faCircleInfo,
    faUser,
    faAt,
    faTrash,
    faKey,
    faPhone,
    faKitchenSet,
    faClock,
    faBox,
    faListCheck,
    faInfoCircle,
    faCheckCircle,
    faBed,
    faComment,
    faArrowRight,
    faUtensils,
    faLandmark,
    faPaw,
    faHospital,
    faStore,
    faWifi,
    faLock,
    faShield,
    faCircleChevronLeft,
    faChampagneGlasses,
    faDog,
    faSmoking,
    faUsers,
    faUpload,
    faSuitcaseRolling,
    faHandSparkles,
    faCircleQuestion,
    faCheck,
    faBan,
    faClose,
    faArrowRightArrowLeft, faPlus, faCircleChevronRight
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {Form, useParams} from "react-router-dom";
import i18n from "../../../component/i18n";
import  Meta  from "../../../component/Meta";

import LogoAnime from "../../../asset/img/logoanime.gif";
import mixpanel from "mixpanel-browser/src/loader-module";

import {Label, Select, ToggleSwitch} from 'flowbite-react';
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import BreadCrumb from "./BreadCrumb";
import { info } from 'autoprefixer';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import MyCustomUploadAdapterPlugin from "../../../component/PluginUploader";
import {CKEditor} from "@ckeditor/ckeditor5-react";

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

export default function AddGuideBook(props) {

    const [switchPortailCaptif, setSwitchPortailCaptif] = useState(false);
    const [switchMenage, setSwitchMenage] = useState(false);
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    //let { guideBookToDuplicateUUID, uuid } = useParams();
   // console.log(guideBookToDuplicateUUID);
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    //const [searchParams] = useSearchParams();
        //console.log(searchParams);
    const duplicateUUID = params.get("guideBookToDuplicateUUID");
    const nameToDuplicate = params.get("name");

    const [checkRule, setCheckRule] = useState([]);
    const [listCheckRule, setListCheckRule] = useState([]);
    const [theme, setTheme] = useState();

    const { t } = useTranslation();
    const [infoPlace, setInfoPlace] = useState(null);
    const [inputs, setInputs] =  useState({address : props.address , contactEmail: props.contactEmail, contactPhone: props.contactPhone, name: props.name, radius: props.radius, clockIn: 16, clockOut: 10, keyDoor: null, ssid:null, password :null});
    const [welcomeSummary, setWelcomeSummary] = useState("");
    const [nbSummary, setNbSummary] = useState(0);
    const lng = i18n.language;
    const [button, setButton] = useState({disabled : '', loader: ''});
    const [contactPhone , setContactPhone] = useState(null);
    const [iconButton, setIconButton] = useState({icon : <FontAwesomeIcon icon={faCircleChevronRight} />});
    const meta = {
        title: t('Créer un un livret de bienvenue ')
    }



    const [prepare, setPrepare] = useState(0);
    const [loaderSpin, setLoaderSpin] = useState(0);
    const [userRule, setUserRule] = useState(null);
    const [ruleInterior, setRuleInterior] = useState([]);
    const [listeRuleInterior, setListeRuleInterior] = useState([]);
    const [textRuleInterior, setTextRuleInterior] = useState(null);
    const [listTheme, setListTheme] = useState([]);
    const [userRuleInterior, setUserRuleInterior] = useState([]);
    const [ruleWifi, setRuleWifi]= useState(null);
    const [textRuleKeyDoor, setTextRuleKeyDoor] = useState(null);

    const LangId = localStorage.getItem('langId');

     const {
        register,
        handleSubmit,
        trigger,
        setError,
        watch,
        formState: { errors },
    } = useForm({mode: 'onBlur'});
    const [guideBookToDuplicate, setGuideBookToDuplicate] = useState(null);

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token)

        var requestOptionsGet = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        if (duplicateUUID != null) {
            // on va chercher le sinfos sur le livret à dupliquer
            fetch(global.config.uriAPI+'/app/guidebook/show/'+duplicateUUID, requestOptionsGet)
                .then((response) => response.json())
                .then((data) => {
                    setGuideBookToDuplicate(data);
                });
        }

        fetch(global.config.uriAPI + '/app/rule/all/rco/'+LangId+'/gite', requestOptionsGet)
            .then((response) => response.json())
            .then((data) => {
                setListCheckRule(data);
            })
            .catch((err) => {
            });

        fetch(global.config.uriAPI + '/app/rule/all/ri/'+LangId+'/gite', requestOptionsGet)
            .then((response) => response.json())
            .then((data) => {
                setListeRuleInterior(data);
            })
            .catch((err) => {
            });

        fetch(global.config.uriAPI + '/app/theme/', requestOptionsGet)
            .then((response) => response.json())
            .then((data) => {
                setListTheme(data);
                listTheme.map((themeL) => {
                  if (themeL.default === true)
                  {
                      setTheme(themeL.code);
                  }
                })
            })
            .catch((err) => {
            });
    },[LangId]);

    const [listeGuideBook, setListeGuideBook] = useState([]);
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }

    const deleteRuleUserInterior = (event) => {
        const idrule = event.currentTarget.dataset.idrule;

        const copy = [...userRuleInterior];
        copy.splice(idrule, 1)
        setUserRuleInterior(copy);
    }

    const addUserRuleInterior = (event) => {
        const nameRuleInterior = document.getElementById('nameRuleInterior');
        if (nameRuleInterior !== "") {
            const descRuleInterior = document.getElementById('descRuleInterior');
            const isCheck = event.currentTarget.dataset.ischeck;
            const type = event.currentTarget.dataset.typerule;

            const myObject = {
                "name": nameRuleInterior.value,
                "description": descRuleInterior.value,
                "isCheck": isCheck,
                'type' : type
            }

            setUserRuleInterior(prevCheckRule => {
                // Vérifie si la règle est déjà dans le tableau

                // Sinon, on l'ajoute
                return [...prevCheckRule, myObject];

            });

            nameRuleInterior.value = '';
            descRuleInterior.value = '';
        }
    }

    const checkRuleState = (event) =>
    {
        let allFields = watch();
        if (prepare === 0) {
           
            let resultat = true;
            if (allFields.name === ""
            ) {
                trigger("name");
                resultat = false;
            }
            if (allFields.contactPhone === ""
            ) {
                trigger("contactPhone");
                resultat = false;
            }
            if (allFields.contactEmail === ""
            ) {
                trigger("contactEmail");
                resultat = false;
            }
            if (allFields.radius === ""
            ) {
                trigger("radius");
                resultat = false;
            }
            if (infoPlace === undefined || infoPlace === null
            ) {
                resultat =  false;
            }

            
            if (errors.name
                || errors.contactPhone
                || errors.contactEmail
            ) {
                resultat = false;
            }

            return resultat;
        }

        if (prepare === 3)
        {
                let resultat = true;
            if (allFields.clockIn === ""
            ) {
                trigger("clockIn");
                resultat = false;
            }
            if (allFields.clockOut === ""
            ) {
                trigger("clockOut");
                resultat = false;
            }

            if (errors.clockIn
                || errors.clockOut
            ) {
                resultat = false;
            }

            return resultat;
        }

        if (prepare === 4)
        {
            let resultat = true;
            if (allFields.ssid === ""
            ) {
                trigger("ssid");
                resultat = false;
            }

            if (!switchPortailCaptif){
                if (allFields.password === ""
                ) {
                    register("password", {required: true});
                    setError("password", { required: true })
                    trigger("password");
                    resultat = false;
                }
            } else {
                if (allFields.password === ""
                )
                {
                    register("password");
                }
            }

            return resultat;
        }

        return true;
    }

    const handleNext = (event) =>
    {
        event.preventDefault();
         if ( checkRuleState(event) ) {
            setLoaderSpin(1);
            setTimeout(() => {
                setLoaderSpin(0);
                setPrepare(prepare+1);
            }, 500);
         }
    }

    const goWifi = (event) => {
        event.preventDefault();
        setPrepare(4);
    }

    const handlePrev = (event) =>  {
        event.preventDefault();
        if (prepare >= 1) {
            setLoaderSpin(1);
            setTimeout(() => {
                setLoaderSpin(0);
                setPrepare(prepare-1);
            }, 500);
        }
    }
    const clickWelcomeSummary = (event) => {
        event.preventDefault();
        setWelcomeSummary(event.currentTarget.innerText);
        setNbSummary(event.currentTarget.dataset.nbsummary);

       // console.log(welcomeSummary);
        //console.log(nbSummary);
        let listWelcomeSummary = document.querySelectorAll(".welcomeSummary");
        listWelcomeSummary.forEach((element) => {
            if (element === event.target) {
                element.classList.add("bg-green-50");
                element.classList.add("border-green-700");
                element.classList.add("text-green-700");
                element.classList.remove("bg-gray-50");
                element.classList.remove("border-gray-700");
                element.classList.remove("text-gray-700");
            } else {
                element.classList.remove("bg-green-50");
                element.classList.remove("border-green-700");
                element.classList.remove("text-green-700");
                element.classList.add("bg-gray-50");
                element.classList.add("border-gray-700");
                element.classList.add("text-gray-700");
            }
        });
    }

    const updateRuleWifi = (event)=> {
        setRuleWifi(event.currentTarget.value);
    }

    const updateRuleKeyDoor = (event)=> {
        setTextRuleKeyDoor(event.currentTarget.value);
    }

    const updateWelcomeSummary = (event) => {
        setWelcomeSummary(event.currentTarget.value);
        setNbSummary(event.currentTarget.dataset.nbsummary);
        let listWelcomeSummary = document.querySelectorAll(".welcomeSummary");
        listWelcomeSummary.forEach((element) => {
            element.classList.remove("bg-green-50");
            element.classList.remove("border-green-700");
            element.classList.remove("text-green-700");
            element.classList.add("bg-gray-50");
            element.classList.add("border-gray-700");
            element.classList.add("text-gray-700");

        });

        if (event.target.value === "") {
            let element = document.querySelector(".welcomeSummary");
            setWelcomeSummary(element.innerText);
            element.classList.add("bg-green-50");
            element.classList.add("border-green-700");
            element.classList.add("text-green-700");
            element.classList.remove("bg-gray-50");
            element.classList.remove("border-gray-700");
            element.classList.remove("text-gray-700");
        }
    }

    const toggleRulecheckOut = (event) => {

        const ruleToToggle = event.currentTarget.dataset.rule;

        setCheckRule(prevCheckRule => {
            // Vérifie si la règle est déjà dans le tableau
            if (prevCheckRule.includes(ruleToToggle)) {
                // Si la règle est présente, on la supprime
                return prevCheckRule.filter(item => item !== ruleToToggle);
            } else {
                // Sinon, on l'ajoute
                return [...prevCheckRule, ruleToToggle];
            }
        });



        event.currentTarget.classList.toggle("bg-green-50");
        event.currentTarget.classList.toggle("border-green-700");
        event.currentTarget.classList.toggle("text-green-700");
        event.currentTarget.classList.toggle("bg-gray-50");
        event.currentTarget.classList.toggle("border-gray-700");
        event.currentTarget.classList.toggle("text-gray-700");

        event.currentTarget.querySelector('svg').classList.toggle('stroke-green-700');
        event.currentTarget.querySelector('svg').classList.toggle('stroke-gray-700');

        event.currentTarget.querySelector('svg').classList.toggle('fill-green-50');
        event.currentTarget.querySelector('svg').classList.toggle('fill-gray-50');
    }

    const toggleTheme = (event) => {
        setTheme(event.currentTarget.dataset.theme);
        //border border-dashed border-green-700 opacity-60 hover:opacity-100
        let list = document.querySelectorAll(".theme");
       /* for (let i = 0; i < list.length; i++) {
            list[i].classList.remove("border-2");
            list[i].classList.remove("border-dashed");
            list[i].classList.remove("border-green-700");
            list[i].classList.add("opacity-60");
            list[i].classList.add("hover:opacity-100");
        }*/
        list.forEach((element) => {

            element.classList.remove("border-2");
            element.classList.remove("border-dashed");
            element.classList.remove("border-green-700");
            element.classList.add("opacity-60");
            element.classList.add("hover:opacity-100");
            console.log(element);
        })

        event.currentTarget.classList.add("border-2");
        event.currentTarget.classList.add("border-dashed");
        event.currentTarget.classList.add("border-green-700");
        event.currentTarget.classList.remove("opacity-60");
        event.currentTarget.classList.remove("hover:opacity-100");
    }

    const addRuleInterior = (event) => {
        const ruleToToggle = event.currentTarget.dataset.ruleinterior;

        setRuleInterior(prevCheckRule => {
            // Vérifie si la règle est déjà dans le tableau
            if (prevCheckRule.includes(ruleToToggle)) {
                // Si la règle est présente, on la supprime
                return prevCheckRule.filter(item => item !== ruleToToggle);
            } else {
                // Sinon, on l'ajoute
                return [...prevCheckRule, ruleToToggle];
            }
        });


        event.currentTarget.classList.toggle("bg-green-50");
        event.currentTarget.classList.toggle("border-green-700");
        event.currentTarget.classList.toggle("text-green-700");
        event.currentTarget.classList.toggle("bg-red-50");
        event.currentTarget.classList.toggle("border-red-700");
        event.currentTarget.classList.toggle("text-red-700");

        event.currentTarget.querySelector('svg').classList.toggle('stroke-green-700');
        event.currentTarget.querySelector('svg').classList.toggle('stroke-red-700');

        event.currentTarget.querySelector('svg').classList.toggle('fill-green-50');
        event.currentTarget.querySelector('svg').classList.toggle('fill-red-50');
    }

    const updateTextRuleInterior = (event) => {
        setTextRuleInterior(event.currentTarget.value);

    }
    const updateUserRule = (event) => {
        setUserRule(event.currentTarget.value);

        // on met les autre rule en gray

        // on vide
        /*setCheckRule([]);

        let listWelcomeSummary = document.querySelectorAll(".rule");
        listWelcomeSummary.forEach((element) => {
            element.classList.remove("bg-green-50");
            element.classList.remove("border-green-700");
            element.classList.remove("text-green-700");
            element.classList.remove("bg-gray-50");
            element.classList.remove("border-gray-700");
            element.classList.remove("text-gray-700");
            element.classList.add("bg-gray-50");
            element.classList.add("border-gray-700");
            element.classList.add("text-gray-700");

            element.querySelector('svg').classList.remove('stroke-green-700');
            element.querySelector('svg').classList.remove('stroke-gray-700');
            element.querySelector('svg').classList.add('stroke-gray-700');

            element.querySelector('svg').classList.remove('fill-green-50');
            element.querySelector('svg').classList.remove('fill-gray-50');
            element.querySelector('svg').classList.add('fill-gray-50');

        });*/
    }

    const onSubmit = (data) => {
        const contactEmail = data.contactEmail;
       
        const radius= data.radius;
        const ssid = data.ssid;
        const password = data.password;
        const portailCaptif =  switchPortailCaptif?1:0;
        const address = infoPlace;
        const name = data.name;
        const clockIn = data.clockIn;
        const clockOut = data.clockOut;
        const keyDoor = data.keyDoor;

        setPrepare(6);

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token);

        let latAddress = '';
        let lngAddress = '';

        setButton(previousState => {
            return { ...previousState, disabled: 'disabled' }
        });
        setIconButton(previousState => {
            return { ...previousState, icon:  <FontAwesomeIcon icon={faSpinner} spin/>}
        });

        geocodeByAddress(address.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) => {

                    latAddress = lat;
                    lngAddress = lng;

                    if (guideBookToDuplicate != null) {
                        var raw = JSON.stringify({"contactEmail": guideBookToDuplicate.contactEmail,"contactPhone": guideBookToDuplicate.contactPhone,
                            "address" :address.label,"radius": radius,"guideBookToDuplicateUUID": duplicateUUID,
                            "ssid": ssid, "password": password, "portailCaptif": portailCaptif,
                            "longitude": lngAddress, 'latitude': latAddress, "name": name, "city": address.label, 'cityCode': 0,
                            'ruleInterior': JSON.stringify(ruleInterior), 'welcomeSummary': welcomeSummary, "theme": theme, "ruleCheckOut": JSON.stringify(checkRule),
                            'clockIn': clockIn, 'clockOut': clockOut, 'keyDoor': keyDoor, 'textRuleWifi' : ruleWifi, "listAddRule": JSON.stringify(userRuleInterior),
                            'textKeyDoor': textRuleKeyDoor, 'genre': 'gite'
                        });
                    } else {
                        var raw = JSON.stringify({"contactEmail": contactEmail,"contactPhone": contactPhone,
                            "address" :address.label,"radius": radius,
                            "ssid": ssid, "password": password, "portailCaptif": portailCaptif,
                            "longitude": lngAddress, 'latitude': latAddress, "name": name, "city": address.label, 'cityCode': 0,
                            'ruleInterior': JSON.stringify(ruleInterior),
                            'welcomeSummary': welcomeSummary, "theme": theme, "ruleCheckOut": JSON.stringify(checkRule),
                            'clockIn': clockIn, 'clockOut': clockOut, 'keyDoor': keyDoor,'textRuleWifi' : ruleWifi, "listAddRule":  JSON.stringify(userRuleInterior),
                            'textKeyDoor': textRuleKeyDoor, 'genre': 'gite'
                        });
                    }

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                fetch(global.config.uriAPI+'/app/guidebook/create', requestOptions)
                    .then((response) => {
                        if(response.status != 200) throw new Error(response.status);
                        else return response.json();
                    })
                    .then((data) => {
                        const uuid = data.uuid;

                        window.location.href = "/app/guidebook/show/"+uuid;
                        return;

                    })
                    .catch((err) => {

                    });
                }
            )

    }

    return (
        <Layout>
            <div class="container mx-auto">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            {t('Créer un livret')}
                        </h1>
                        <div class="flex items-center gap-2 text-sm font-normal text-gray-700">
                            <BreadCrumb links={[{name: t('Créer un livret'), url: '/app/guidebook/add'}]} />
                        </div>
                    </div>
                </div>
            </div>

            <section className={"container mx-auto"}>
                <Meta meta={meta} />
                <div className=" mt-5 shadow rounded border border-gray-200 w-full bg-white p-5 ">
                    <div className={"flex gap-5 justify-end items-center mb-10 flex-wrap"}>
                            <>
                                {prepare === 0 ?
                                    <>
                                        <div class="relative size-[50px] shrink-0">
                                            <svg class="stroke-1 stroke-green-600 fill-green-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                        18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                        39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="" stroke-width={"0"}>
                                                </path>
                                                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                        18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                        39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z"
                                                      stroke-dasharray="150px"
                                                      stroke-dashoffset ="120px"  stroke-width="2px">
                                                </path>
                                            </svg>
                                            <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4  text-green-600 font-semibold flex items-center justify-center">
                                                1/6
                                            </div>
                                        </div>
                                        <div className={"first-letter:uppercase text-gray-600 text-sm flex flex-col gap-1"}>
                                            <b class={"font-semibold text-gray-600"}>{t('Informations')}</b>
                                            <span className={"text-xs text-gray-500"}>{t('Votre location et son adresse')}</span>
                                        </div>
                                    </>: null}
                                {prepare === 1 ?
                                    <>
                                        <div class="relative size-[50px] shrink-0">
                                            <svg class="stroke-1 stroke-green-600 fill-green-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                        18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                        39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="" stroke-width={"0"}>
                                                </path>
                                                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                        18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                        39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z"
                                                      stroke-dasharray="150px"
                                                      stroke-dashoffset ="100px"  stroke-width="2px">
                                                </path>
                                            </svg>
                                            <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4  text-green-600 font-semibold flex items-center justify-center">
                                                2/6
                                            </div>
                                        </div>
                                        <div className={"first-letter:uppercase text-gray-600 text-sm flex flex-col gap-1"}>
                                            <b class={"font-semibold text-gray-600"}>{t('Informations')}</b>
                                            <span className={"text-xs text-gray-500"}>{t('Réglement intérieur')}</span>
                                        </div>
                                    </>
                                    : null}
                                {prepare === 2 ?
                                    <>
                                        <div class="relative size-[50px] shrink-0">
                                            <svg class="stroke-1 stroke-green-600 fill-green-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                        18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                        39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="" stroke-width={"0"}>
                                                </path>
                                                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                        18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                        39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z"
                                                      stroke-dasharray="150px"
                                                      stroke-dashoffset ="90px"  stroke-width="2px">
                                                </path>
                                            </svg>
                                            <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4  text-green-600 font-semibold flex items-center justify-center">
                                                3/6
                                            </div>
                                        </div>
                                        <div className={"first-letter:uppercase text-gray-600 text-sm flex flex-col gap-1"}>
                                            <b class={"font-semibold text-gray-600"}>{t('Informations')}</b>
                                            <span className={"text-xs text-gray-500"}>{t('Votre message de bienvenue')}</span>
                                        </div>
                                    </>: null}
                                {prepare === 3 ?
                                    <>
                                        <div class="relative size-[50px] shrink-0">
                                            <svg class="stroke-1 stroke-green-600 fill-green-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                        18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                        39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="" stroke-width={"0"}>
                                                </path>
                                                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                        18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                        39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z"
                                                      stroke-dasharray="150px"
                                                      stroke-dashoffset ="60px"  stroke-width="2px">
                                                </path>
                                            </svg>
                                            <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4  text-green-600 font-semibold flex items-center justify-center">
                                                4/6
                                            </div>
                                        </div>
                                        <div className={"first-letter:uppercase text-gray-600 text-sm flex flex-col gap-1"}>
                                            <b class={"font-semibold text-gray-600"}>{t('Informations')}</b>
                                            <span className={"text-xs text-gray-500"}>{t('Entrée / Sortie')}</span>
                                        </div>
                                    </>: null}
                                {prepare === 4 ?
                                    <>
                                        <div class="relative size-[50px] shrink-0">
                                            <svg class="stroke-1 stroke-green-600 fill-green-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                        18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                        39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="" stroke-width={"0"}>
                                                </path>
                                                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                        18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                        39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z"
                                                      stroke-dasharray="150px"
                                                      stroke-dashoffset ="45px"  stroke-width="2px">
                                                </path>
                                            </svg>
                                            <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4  text-green-600 font-semibold flex items-center justify-center">
                                                5/6
                                            </div>
                                        </div>
                                        <div className={"first-letter:uppercase text-gray-600 text-sm flex flex-col gap-1"}>
                                            <b class={"font-semibold text-gray-600"}>{t('Wifi')}</b>
                                            <span className={"text-xs text-gray-500"}>{t('Ssid / Mot de passe')}</span>
                                        </div>
                                    </>: null}
                                {prepare === 5 ?
                                    <>
                                        <div class="relative size-[50px] shrink-0">
                                            <svg class="stroke-1 stroke-green-600 fill-green-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                        18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                        39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="" stroke-width={"0"}>
                                                </path>
                                                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                        18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                        39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z"
                                                      stroke-dasharray="150px"
                                                      stroke-dashoffset ="0px"  stroke-width="2px">
                                                </path>
                                            </svg>
                                            <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4  text-green-600 font-semibold flex items-center justify-center">
                                                6/6
                                            </div>
                                        </div>
                                        <div className={"first-letter:uppercase text-gray-600 text-sm flex flex-col gap-1"}>
                                            <b class={"font-semibold text-gray-600"}>{t('Theme')}</b>
                                            <span className={"text-xs text-gray-500"}>{t('Choisir un theme et les couleurs')}</span>
                                        </div>
                                    </>: null}

                            </>
                        

                    </div>
                    <Form action="" method="post" onSubmit={handleSubmit(onSubmit)} noValidate id="formCreate">
                    {loaderSpin === 1 ?
                            <div className={"flex justify-center items-center"}>
                                <FontAwesomeIcon icon={faSpinner} spin className={"text-green-700 text-xl"} size={"2x"} />
                            </div> : 
                            <>
                                                {prepare === 0 ?
                      <div className={""}>
                            {guideBookToDuplicate !=null ?
                                <div className={"bg-yellow-100 p-5 rounded text-yellow-900 mb-2"}><FontAwesomeIcon icon={faWarning} /> {t('Vous allez dupliquer le livret à partir du livret')} : {guideBookToDuplicate.name}</div>
                                : <></> }
                            <div className="flex items-baseline flex-wrap lg:flex-nowrap gap-2.5 ">
                                <label htmlFor="name" className={ errors.name ?  "text-md font-semibold text-red-700 lg:max-w-[25%] w-full" : "text-md font-semibold text-gray-700 lg:max-w-[25%] w-full"}>
                                    {t('Le nom de votre livret')}<span className={"text-red-600"}>*</span></label>
                                <div className={"flex flex-col gap-1 w-full"}>
                                    <div className={"relative"}>
                                        <input type="text" id="name"
                                               name ="name"
                                               aria-invalid={errors.name ? "true" : "false"}
                                               defaultValue={inputs.name|| ""}
                                               {...register("name", { onChange: handleChange, required: true})}
                                               className= { errors.name ?
                                                   "border rounded  h-10  bg-red-50 border-red-300  text-gred-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 pr-4 pl-10" :
                                                   "border rounded  h-10  bg-gray-50 border-gray-300  text-gray-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600  pr-4 pl-10"
                                               }
                                               placeholder={t("Le gite de la côte fleuris")}/>
                                        <div class="absolute inset-y-0 left-0 pl-3
                                        flex items-center
                                        pointer-events-none">
                                            <FontAwesomeIcon icon={faBook} className={"text-gray-300"} />
                                        </div>
                                    </div>
                                    {errors.name && errors.name.type === "required" && (
                                        <div className={"text-red-500 p-1 mt-1 rounded text-xs"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                                    )}
                                </div>

                            </div>
                          {guideBookToDuplicate != null ?  null :
                              <div className="flex items-baseline flex-wrap lg:flex-nowrap gap-2.5  mt-3">
                                  <label htmlFor="email" className={ errors.contactEmail ?  " text-md font-semibold text-red-700 lg:max-w-[25%] w-full" : "text-md font-semibold text-gray-700 lg:max-w-[25%] w-full"}>
                                      {t('Votre adresse email de contact')}<span className={"text-red-600"}>*</span></label>
                                  <div className={"flex flex-col gap-1 w-full"}>
                                      <div className={"relative"}>
                                          <input type="text" id="contactEmail"
                                                 name ="email"
                                                 value={guideBookToDuplicate?.contactEmail}
                                                 aria-invalid={errors.contactEmail ? "true" : "false"}
                                                 defaultValue={inputs.contactEmail|| ""}
                                                 {...register("contactEmail", { onChange: handleChange, required: true , pattern : /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g})}
                                                 className= { errors.contactEmail ?
                                                     "border rounded  h-10  bg-red-50 border-red-300  text-gred-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 pr-4 pl-10" :
                                                     "border rounded  h-10  bg-gray-50 border-gray-300  text-gray-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 pr-4 pl-10"
                                                 }
                                                 placeholder={t("contact@contact.com")}
                                          />
                                          <div class="absolute inset-y-0 left-0 pl-3
                                        flex items-center
                                        pointer-events-none">
                                              <FontAwesomeIcon icon={faAt} className={"text-gray-300"} />
                                          </div>
                                      </div>
                                      {errors.contactEmail && errors.contactEmail.type === "required" && (
                                          <div className={"text-red-500 p-1 mt-1 rounded text-xs"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                                      )}
                                      {errors.contactEmail && errors.contactEmail.type === "pattern" && (
                                          <div className={"text-red-500 p-1 mt-1 rounded text-xs"}> <FontAwesomeIcon icon={faCircleExclamation} /> {t('Mauvais format')}</div>
                                      )}
                                  </div>
                              </div>
                          }
                          {guideBookToDuplicate != null ?  null :
                            <div className="flex items-baseline flex-wrap lg:flex-nowrap gap-2.5  mt-3">
                                <label htmlFor="telephone" className={ errors.contactPhone ?  " text-md font-semibold text-red-700 lg:max-w-[25%] w-full" : "text-md font-semibold text-gray-700 lg:max-w-[25%] w-full"}>
                                    {t('Votre numéro de téléphone')}<span className={"text-red-600"}>*</span></label>
                                <div className={"flex flex-col gap-1 w-full"}>
                                    <div className={"relative"}>
                                        <PhoneInput
                                            name ="contactPhone"
                                            defaultCountry = "FR"
                                            placeholder= {t('Votre numéro de téléphone')}
                                            className= {
                                                "border rounded  h-10  bg-gray-50 border-gray-300  text-gray-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 pr-4 pl-10"
                                            }
                                            value={contactPhone}
                                            onChange={setContactPhone}/>
                                    </div>
                                {errors.contactPhone && errors.contactPhone.type === "required" && (
                                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                                )}
                                </div>

                            </div> }

                            <div className={"flex items-baseline flex-wrap lg:flex-nowrap gap-2.5  mt-3"}>
                                <label htmlFor="adresse" className={ errors.address ?  " text-md font-semibold text-red-700 lg:max-w-[25%] w-full" : "text-md font-semibold text-gray-700 lg:max-w-[25%] w-full"}>
                                    {t('L\'adresse de votre location')}<span className={"text-red-600"}>*</span></label>
                                <div className={"flex flex-col gap-1 w-full"}>
                                <GooglePlacesAutocomplete apiKey={global.config.apiKeyGoogle}
                                                            value = {infoPlace}
                                                          apiOptions={{ language: lng }}
                                                          {...register("address", { onChange: handleChange, required: true})}
                                                          className={"border rounded  h-10  bg-gray-50 border-gray-300  text-gray-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 pr-4 pl-10"}
                                                          selectProps={{
                                                              infoPlace,
                                                              onChange: setInfoPlace,
                                                          }}
                                />
                                <div className={"text-gray-600 text-xs"}>{infoPlace ? infoPlace.label : null}</div>
                                {errors.address && errors.address.type === "required" && (
                                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                                )}
                                </div>
                            </div>
                            {guideBookToDuplicate != null ? <></>:
                                <div className={"flex items-baseline flex-wrap lg:flex-nowrap gap-2.5  mt-3"}>
                                    <Label htmlFor="radius" className={"text-md font-semibold text-gray-700 lg:max-w-[25%] w-full"} value={t('Sélectionnez les restaurants / activités dans un rayon de ? ')} />

                                        <Select id="radius"
                                                className={"   h-10   text-gray-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 "}
                                                {...register("radius", { onChange: handleChange, required: true})}
                                                required>
                                            <option value="10000">10 km</option>
                                            <option value="20000">20 km</option>
                                            <option value="30000"> 30 km </option>
                                            <option value="40000" selected={"selected"}>50 km</option>
                                        </Select>
                                </div>
                            }

                                <div className={"flex  flex-wrap items-center justify-center gap-2 mt-5  "}
                                >
                                    <div className={"flex flex-col gap-2  lg:w-1/4 md:1/4"}>
                                        <div className={"flex flex-row flex-wrap justify-center items-center gap-2"}>
                                            <div class="relative size-[50px] shrink-0">
                                                <svg class="stroke-1 stroke-blue-600 fill-blue-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
			18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
			39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                    </path>
                                                    <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
			18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
			39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                    </path>
                                                </svg>
                                                <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 text-blue-600 font-semibold ">
                                                    IA
                                                </div>
                                            </div>
                                            <span className={"first-letter:uppercase text-gray-700 font-semibold text-xl"}>{t('Remplissage automatique')}</span>
                                        </div>
                                        <div className={"text-gray-700 text-sm text-justify flex flex-col gap-2"}>
                                            <span>{t('Les catégories ci dessous seront automatiquement remplies avec les informations situées dans le rayon sélectionné')} :</span>
                                            <div className={"pl-2 mt-2"}>
                                                     <span className={"flex gap-2 items-center"}>
                                                         <div class="relative size-[25px] shrink-0">
                                                            <svg class="stroke-1 stroke-gray-600 fill-gray-50" fill="none" height="24" viewBox="0 0 44 48" width="22" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                        18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                        39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                                </path>
                                                                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                        18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                        39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                                </path>
                                                            </svg>
                                                            <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 ">
                                                                <FontAwesomeIcon icon={faUtensils} className={"text-gray-500 text-xs"} />
                                                            </div>
                                                        </div>
                                                         <span>{t('Restaurant')} </span>
                                                     </span>

                                                <span className={"flex gap-2 items-center mt-2"}>
                                                         <div class="relative size-[25px] shrink-0">
                                                            <svg class="stroke-1 stroke-gray-600 fill-gray-50" fill="none" height="24" viewBox="0 0 44 48" width="22" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                        18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                        39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                                </path>
                                                                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                        18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                        39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                                </path>
                                                            </svg>
                                                            <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 ">
                                                                <FontAwesomeIcon icon={faPaw} className={"text-gray-500 text-xs"} />
                                                            </div>
                                                        </div>
                                                         <span>{t('Parc d\'attraction, Zoo, Aquaruim')} </span>
                                                     </span>


                                                <span className={"flex gap-2 items-center mt-2"}>
                                                         <div class="relative size-[25px] shrink-0">
                                                            <svg class="stroke-1 stroke-gray-600 fill-gray-50" fill="none" height="24" viewBox="0 0 44 48" width="22" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                        18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                        39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                                </path>
                                                                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                        18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                        39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                                </path>
                                                            </svg>
                                                            <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 ">
                                                                <FontAwesomeIcon icon={faLandmark} className={"text-gray-500 text-xs"} />
                                                            </div>
                                                        </div>
                                                         <span>{t('Monument, Musée ')} </span>
                                                     </span>

                                                <span className={"flex gap-2 items-center mt-2"}>
                                                         <div class="relative size-[25px] shrink-0">
                                                            <svg class="stroke-1 stroke-gray-600 fill-gray-50" fill="none" height="24" viewBox="0 0 44 48" width="22" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                        18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                        39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                                </path>
                                                                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                        18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                        39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                                </path>
                                                            </svg>
                                                            <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 ">
                                                                <FontAwesomeIcon icon={faHospital} className={"text-gray-500 text-xs"} />
                                                            </div>
                                                        </div>
                                                         <span>{t('Hopitaux, Police')} </span>
                                                     </span>

                                                <span className={"flex gap-2 items-center mt-2"}>
                                                         <div class="relative size-[25px] shrink-0">
                                                            <svg class="stroke-1 stroke-gray-600 fill-gray-50" fill="none" height="24" viewBox="0 0 44 48" width="22" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                        18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                        39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                                </path>
                                                                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                        18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                        39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                                </path>
                                                            </svg>
                                                            <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 ">
                                                                <FontAwesomeIcon icon={faStore} className={"text-gray-500 text-xs"} />
                                                            </div>
                                                        </div>
                                                         <span>{t('Supermarché')} </span>
                                                     </span>
                                            </div>
                                        </div>
                                    </div>
                                    <img src={BgCreate} className={"hidden lg:block md:block"}/>
                                </div>


                            <div className="mt-5 flex justify-end ">
                                {guideBookToDuplicate != null ?
                                    <a href={"#"} onClick={goWifi} className={"bg-cyan-600 text-white p-2 rounded hover:bg-cyan-700 flex items-center justify-center gap-2"}>
                                        <FontAwesomeIcon icon={faCircleChevronRight} />
                                        {t('Wifi')}</a>
                                    :
                                    <a href={"#"} onClick={handleNext} className={"bg-cyan-600 text-white p-2 rounded hover:bg-cyan-700 flex items-center justify-center gap-2"}>
                                <FontAwesomeIcon icon={faCircleChevronRight} />
                                {t('Réglement intérieur')}</a>}

                            </div>
                        </div>
                        :
                        null
                    }
                                                {prepare === 1 ?
                                                    <>
                                                        <div
                                                            className="flex  flex-col w-full items-center gap-2.5 justify-center ">
                                                            <label htmlFor="name"
                                                                   className={errors.name ? "text-md font-semibold text-red-700  " : "text-md font-semibold text-gray-700 "}>
                                                                {t('Réglement intérieur')}<span
                                                                className={"text-red-600"}>*</span>
                                                            </label>
                                                            <span
                                                                className={"text-xs text-gray-500"}>{t('Choisir des options')}</span>
                                                        </div>
                                                        <div
                                                            className={"grid lg:grid-cols-2  justify-start gap-2 mt-5 "}>
                                                            {listeRuleInterior.map((rule) => (
                                                                <div onClick={addRuleInterior} data-ruleinterior={rule.code}
                                                                     className={ruleInterior.includes(rule.code) ? "lg:col-span-1   col-span-2 flex items-center justify-start gap-2 border-green-700 border border-dashed p-2 rounded w-full bg-green-50 text-green-700 hover:cursor-pointer rule" : "lg:col-span-1   col-span-2 flex items-center justify-start gap-2 border-red-700 border border-dashed p-2 rounded w-full bg-red-50 text-red-700 hover:cursor-pointer rule"}>
                                                                    <div class="relative size-[50px] shrink-0">
                                                                        <svg
                                                                            className={ruleInterior.includes(rule.code) ? "stroke-1 stroke-green-600 fill-green-50" : "stroke-1 stroke-red-700 fill-red-50"}
                                                                            fill="none" height="48" viewBox="0 0 44 48"
                                                                            width="44" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                        18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                        39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z"
                                                                                  fill="">
                                                                            </path>
                                                                            <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                        18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                        39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z"
                                                                                  stroke="">
                                                                            </path>
                                                                        </svg>
                                                                        <div
                                                                            class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 ">
                                                                            <i className={rule.icon+" text-md"}></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className={"flex flex-col gap-1"}>
                                                                    <span
                                                                        className={"text-xs font-semibold  first-letter:uppercase"}>{rule.name}</span>
                                                                        <span className={"text-xs first-letter:uppercase"}>
                                                                        {ruleInterior.includes(rule.code) ? rule.description : rule.descNotCheck}
                                                                    </span>

                                                                    </div>
                                                                </div>

                                                            ))}


                                                        </div>

                                                        <div class="flex items-center gap-2 mt-5 mb-5">
                                       <span class="border-t border-gray-200 w-full">
                                       </span>
                                                            <span class="text-sm text-gray-300 uppercase oswald-font">
                                            {t('ou')}
                                       </span>
                                                            <span class="border-t border-gray-200 w-full">
                                       </span>
                                                        </div>
                                                        <div className="flex  flex-col  gap-2.5 items-center ">
                                                            <span
                                                                className={"text-xs text-gray-500 mt-3"}>{t('Ajouter votre Réglement Intérieur en plus des régle ci-dessus')}</span>
                                                            <div className={"flex flex-col gap-1 w-full mt-5"}>
                                                                <div className={"relative p-2 rounded border border-gray-200"} id={"addFormRuleInterior"}>
                                                                    <div className="flex items-baseline flex-wrap lg:flex-nowrap gap-2.5  mt-3">
                                                                        <label htmlFor="telephone" className={  "text-md font-semibold text-gray-700 lg:max-w-[25%] w-full first-letter:uppercase"}>
                                                                            {t('Nom de la regle')}<span className={"text-red-600"}>*</span></label>
                                                                        <div className={"flex flex-col gap-1 w-full"}>
                                                                            <div className={"relative"}>
                                                                                <input type="text" id="nameRuleInterior"
                                                                                       name ="nameRuleInterior"


                                                                                       className= { "border rounded  h-10  bg-gray-50 border-gray-300  text-gray-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 pr-4 pl-10"
                                                                                       }
                                                                                       placeholder={t("Organiser une fete")}/>
                                                                                <div class="absolute inset-y-0 left-0 pl-3
                                        flex items-center
                                        pointer-events-none">
                                                                                    <FontAwesomeIcon icon={faCircleQuestion} className={"text-gray-300"} />
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                    <div className="flex items-baseline flex-wrap lg:flex-nowrap gap-2.5  mt-3">
                                                                        <label htmlFor="telephone" className={  "text-md font-semibold text-gray-700 lg:max-w-[25%] w-full first-letter:uppercase"}>
                                                                            {t('Description')}<span className={"text-red-600"}>*</span></label>
                                                                        <div className={"flex flex-col gap-1 w-full"}>
                                                                            <div className={"relative"}>
                                                                                <input type="text" id="descRuleInterior"
                                                                                       name ="descRuleInterior"


                                                                                       className= { "border rounded  h-10  bg-gray-50 border-gray-300  text-gray-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 pr-4 pl-10"
                                                                                       }
                                                                                       placeholder={t("Vous pouvez organiser une fête")}/>
                                                                                <div class="absolute inset-y-0 left-0 pl-3
                                        flex items-center
                                        pointer-events-none">
                                                                                    <FontAwesomeIcon icon={faCircleQuestion} className={"text-gray-300"} />
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                   <div className={"flex justify-end gap-5 items-center mt-5"}>
                                                                       <div onClick={addUserRuleInterior} data-ischeck={1}  data-typerule={'ri'}  className={"first-letter:uppercase p-2 rounded border-2 border-dashed border-gray-700  text-gray-500 text-sm hover:cursor-pointer  " +
                                                                           "hover:border-green-700 hover:bg-gray-50 hover:text-green-500"}><FontAwesomeIcon icon={faCheck} />&nbsp;{t('Autorisé')}</div>
                                                                       <div onClick={addUserRuleInterior} data-ischeck={0} data-typerule={'ri'} className={"first-letter:uppercase p-2 rounded border-2 border-dashed border-gray-700 text-gray-500 text-sm hover:cursor-pointer hover:border-red-700 hover:bg-red-50 hover:text-red-500"}><FontAwesomeIcon icon={faBan} />&nbsp;{t('Interdire')}</div>
                                                                   </div>

                                                                </div>
                                                            </div>
                                                            <div className={"grid lg:grid-cols-2  justify-start gap-2 mt-5 w-full "}>
                                                                {userRuleInterior.map((rule, index) => (
                                                                    <>
                                                                        {rule.type === 'ri' ?
                                                                            <div
                                                                                className={rule.isCheck == 1 ? "lg:col-span-1   col-span-2 flex items-center justify-start gap-2 border-green-700 border border-dashed p-2 rounded w-full bg-green-50 text-green-700 hover:cursor-pointer rule" : "lg:col-span-1   col-span-2 flex items-center justify-start gap-2 border-red-700 border border-dashed p-2 rounded w-full bg-red-50 text-red-700 hover:cursor-pointer rule"}>
                                                                                <div class="relative size-[50px] shrink-0">
                                                                                    <svg
                                                                                        className={rule.isCheck == 1? "stroke-1 stroke-green-600 fill-green-50" : "stroke-1 stroke-red-700 fill-red-50"}
                                                                                        fill="none" height="48" viewBox="0 0 44 48"
                                                                                        width="44" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                        18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                        39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z"
                                                                                              fill="">
                                                                                        </path>
                                                                                        <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                        18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                        39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z"
                                                                                              stroke="">
                                                                                        </path>
                                                                                    </svg>
                                                                                    <div
                                                                                        class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 ">
                                                                                        <FontAwesomeIcon icon={faCircleQuestion} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className={"flex flex-col gap-1 w-full" }>
                                                                            <span
                                                                                className={"text-xs font-semibold  first-letter:uppercase"}>{rule.name}</span>
                                                                                    <span className={"text-xs first-letter:uppercase"}>
                                                                                {rule.description}
                                                                            </span>
                                                                                    <div class={"text-xs text-gray-700  flex justify-end gap-2 items-center  "}>
                                                                                        <FontAwesomeIcon icon={faTrash}  className={"cursor-pointer"}  data-idrule={index} onClick={deleteRuleUserInterior}/>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                            : <></>}
                                                                    </>
                                                                ))}
                                                            </div>


                                                        </div>
                                                        <div className="mt-5 flex  justify-between items-center">
                                                            <a href={"#"} onClick={handlePrev}>
                                                                <FontAwesomeIcon icon={faCircleChevronLeft} className={"text-gray-700"}/>
                                                            </a>
                                                            <a href={"#"} onClick={handleNext}
                                                               className={"bg-cyan-600 text-white p-2 rounded hover:bg-cyan-700 flex items-center justify-center gap-2"}>
                                                                <FontAwesomeIcon icon={faCircleChevronRight}/>
                                                                {t('Message de bienvenue')}</a>
                                                        </div>
                                                    </> : null }

                            {prepare == 2 ?
                                <div className={""}>
                                    <div className="flex  flex-col w-full items-center gap-2.5 justify-center ">
                                        <label htmlFor="name" className={ errors.name ?  "text-md font-semibold text-red-700  " : "text-md font-semibold text-gray-700 "}>
                                            {t('Votre message de bienvenue')}<span className={"text-red-600"}>*</span>
                                        </label>
                                        <span className={"text-xs text-gray-500"}>{t('Choisir un texte')}</span>
                                    </div>
                                    <div className={"flex flex-col items-center justify-center"}>
                                        <div data-nbsummary={1} className={nbSummary == 1 ? "border border-green-700 border-dashed p-5 bg-green-50 text-green-700 rounded mt-5 hover:cursor-pointer welcomeSummary hover:bg-green-50  hover:border-green-700  hover:text-green-700": "border border-gray-700 border-dashed p-5 bg-gray-50 text-gray-700 rounded mt-5 hover:bg-green-50 hover:border-green-700  hover:text-green-700 hover:cursor-pointer welcomeSummary" } onClick={clickWelcomeSummary}>
                                            {t('Bienvenue, Nous sommes enchantés de vous accueillir et espérons que vous passerez un séjour des plus agréables')}<br/>
                                            {t('Ce livret numérique contient toutes les informations nécessaires pour vous aider à tirer le meilleur parti de votre séjour')}<br/>
                                            {t('N’hésitez pas à le consulter pour découvrir les équipements, les règles de la maison, ainsi que des recommandations locales')} <br />
                                            {t('Passez un excellent séjour !')}
                                        </div>
                                        <div data-nbsummary={2} className={nbSummary == 2 ? "border border-green-700 border-dashed p-5 bg-green-50 text-green-700 rounded mt-5 hover:cursor-pointer welcomeSummary hover:bg-green-50  hover:border-green-700  hover:text-green-700": "border border-gray-700 border-dashed p-5 bg-gray-50 text-gray-700 rounded mt-5 hover:bg-green-50 hover:border-green-700  hover:text-green-700 hover:cursor-pointer welcomeSummary" }
                                        onClick={clickWelcomeSummary}
                                        >
                                            {t('Bonjour et bienvenue')}<br />
                                            {t('C’est un plaisir de vous recevoir')}.<br />
                                            {t('Nous avons préparé ce livret numérique pour vous fournir toutes les informations utiles sur votre logement et les alentours')}.<br />
                                            {t('Vous y trouverez des détails sur les équipements, les règles à suivre, et des conseils pour profiter pleinement de votre séjour')}.<br />
                                            {t('Nous vous souhaitons un séjour agréable et reposant')}
                                        </div>
                                    </div>

                                    <div class="flex items-center gap-2 mt-5 mb-5">
                                       <span class="border-t border-gray-200 w-full">
                                       </span>
                                                        <span class="text-sm text-gray-300 uppercase oswald-font">
                                            {t('ou')}
                                       </span>
                                                        <span class="border-t border-gray-200 w-full">
                                       </span>
                                    </div>
                                    <div className="flex  flex-col  gap-2.5 items-center ">
                                        <span className={"text-xs text-gray-500 mt-3"}>{t('Ecrire votre texte')}</span>
                                        <div className={"flex flex-col gap-1 w-full mt-5"}>
                                            <div className={"relative"}>
                                                <textarea
                                                    data-nbsummary={3}
                                                    name ="welcomeSummaryTextare"
                                                    rows= "5"
                                                    onChange={updateWelcomeSummary}

                                                    className= {nbSummary == 3 ? "border rounded    bg-green-50 border-green-700  text-green-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600  p-2 " :"border rounded    bg-gray-50 border-gray-300  text-gray-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600  p-2 "
                                                    }
                                                    placeholder={t("Bienvenue ...")}

                                                >
                                                    {nbSummary == 3 ? welcomeSummary : null}
                                                    </textarea>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="mt-5 flex  justify-between items-center">
                                        <a href={"#"} onClick={handlePrev}>
                                            <FontAwesomeIcon icon={faCircleChevronLeft} className={"text-gray-700"}/>
                                        </a>
                                        <a href={"#"} onClick={handleNext} className={"bg-cyan-600 text-white p-2 rounded hover:bg-cyan-700 flex items-center justify-center gap-2"}>
                                            <FontAwesomeIcon icon={faCircleChevronRight} />
                                            {t('Entrée /Sortie')}</a>
                                    </div>
                                </div>
                                : null }
                    {prepare == 3 ?
                        <>
                            <div className={"flex items-baseline flex-wrap lg:flex-nowrap gap-2.5  mt-3"}>
                                <Label htmlFor="radius" className={"text-md font-semibold text-gray-700 lg:max-w-[25%] w-full"} value={t('Vos voyageurs peuvent arriver à partir de ?  ')} />
                                <div className={"flex flex-col gap-1 w-full"}>
                                    <div className={"relative"}>
                                        <input type="text" id="clockIn"
                                               name ="clockIn"
                                               aria-invalid={errors.clockIn ? "true" : "false"}
                                               defaultValue={inputs.clockIn|| ""}
                                               {...register("clockIn", { onChange: handleChange, required: true})}
                                               className= { errors.clockIn ?
                                                   "border rounded  h-10  bg-red-50 border-red-300  text-gred-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 pr-4 pl-10" :
                                                   "border rounded  h-10  bg-gray-50 border-gray-300  text-gray-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 pr-4 pl-10"
                                               }
                                               placeholder={t("16")}/>
                                        <div class="absolute inset-y-0 left-0 pl-3
                                        flex items-center
                                        pointer-events-none">
                                            <FontAwesomeIcon icon={faClock} className={"text-gray-300"} />
                                        </div>
                                        <div class="absolute inset-y-0 right-0 pr-3 text-gray-300
                                        flex items-center
                                        pointer-events-none">
                                            {t('heure')}
                                        </div>
                                    </div>
                                        {errors.clockIn && errors.clockIn.type === "required" && (
                                            <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                                        )}
                                </div>
                            </div>
                            <div className={"flex items-baseline flex-wrap lg:flex-nowrap gap-2.5  mt-3"}>
                                <Label htmlFor="radius" className={"text-md font-semibold text-gray-700 lg:max-w-[25%] w-full"} value={t('Ils doivent partir avant ?  ')} />
                                <div className={"flex flex-col gap-1 w-full"}>
                                    <div className={"relative"}>
                                        <input type="text" id="clockOut"
                                               name ="clockOut"
                                               aria-invalid={errors.clockOut ? "true" : "false"}
                                               defaultValue={inputs.clockOut|| ""}
                                               {...register("clockOut", { onChange: handleChange, required: true})}
                                               className= { errors.clockOut ?
                                                   "border rounded  h-10  bg-red-50 border-red-300  text-red-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 pr-4 pl-10" :
                                                   "border rounded  h-10  bg-gray-50 border-gray-300  text-gray-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 pr-4 pl-10"
                                               }
                                               placeholder={t("10")}/>
                                        <div class="absolute inset-y-0 left-0 pl-3
                                        flex items-center
                                        pointer-events-none">
                                            <FontAwesomeIcon icon={faClock} className={"text-gray-300"} />
                                        </div>
                                        <div class="absolute inset-y-0 right-0 pr-3 text-gray-300
                                        flex items-center
                                        pointer-events-none">
                                            {t('heure')}
                                        </div>
                                    </div>
                                    {errors.clockOut && errors.clockOut.type === "required" && (
                                        <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                                    )}
                                </div>
                            </div>
                            <div className={"flex items-baseline flex-wrap lg:flex-nowrap gap-2.5  mt-3"}>
                                <Label htmlFor="radius" className={"text-md font-semibold text-gray-700 lg:max-w-[25%] w-full"} value={t('Le code de la boîte à clef est le ')} />
                                <div className={"flex flex-col gap-1 w-full"}>
                                    <div className={"relative"}>
                                        <input type="text" id="keyDoor"
                                               name ="keyDoor"
                                               aria-invalid={errors.keyDoor ? "true" : "false"}
                                               defaultValue={inputs.keyDoor|| ""}
                                               {...register("keyDoor", { onChange: handleChange})}
                                               className= { errors.keyDoor ?
                                                   "border rounded  h-10  bg-red-50 border-red-300  text-red-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 pr-4 pl-10" :
                                                   "border rounded  h-10  bg-gray-50 border-gray-300  text-gray-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 pr-4 pl-10"
                                               }
                                               placeholder={t("1056")}/>
                                        <div class="absolute inset-y-0 left-0 pl-3
                                        flex items-center
                                        pointer-events-none">
                                            <FontAwesomeIcon icon={faBox} className={"text-gray-300"} />
                                        </div>

                                    </div>
                                    {errors.keyDoor && errors.keyDoor.type === "required" && (
                                        <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                                    )}

                                    <textarea
                                        data-nbsummary={3}
                                        name ="ruleKeyDoor"
                                        rows= "5"
                                        onChange={updateRuleKeyDoor}

                                        className= {"border rounded    bg-gray-50 border-gray-300  text-gray-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600  p-2  mt-4"
                                        }
                                        placeholder={t("Régles pour lla boite à clef")}
                                    >
                                                    { textRuleKeyDoor}
                                                    </textarea>
                                </div>
                            </div>
                            <div className={"flex items-center flex-wrap lg:flex-nowrap gap-2.5  mt-5"}>
                                <Label htmlFor="radius" className={"text-md font-semibold text-gray-700 lg:max-w-[25%] w-full"} value={t('Avant de partir ils doivent ')} />
                                <div className={"grid lg:grid-cols-2  justify-start gap-2 "}>
                                    {listCheckRule.map((rule) => (
                                        <div onClick={toggleRulecheckOut} data-rule={rule.code}
                                             className={checkRule.includes(rule.code)? "lg:col-span-1   col-span-2 flex items-center justify-start gap-2 border-green-700 border border-dashed p-2 rounded w-full bg-green-50 text-green-700 hover:cursor-pointer rule":"lg:col-span-1   col-span-2 flex items-center justify-start gap-2 border-gray-700 border border-dashed p-2 rounded w-full bg-gray-50 text-gray-700 hover:cursor-pointer rule"}>
                                            <div class="relative size-[50px] shrink-0">
                                                <svg className={checkRule.includes(rule.code)?"stroke-1 stroke-green-700 fill-green-50": "stroke-1 stroke-gray-700 fill-gray-50"} fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                        18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                        39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                    </path>
                                                    <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                        18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                        39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                    </path>
                                                </svg>
                                                <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 ">
                                                    <i className={rule.icon+" text-md"}></i>
                                                </div>
                                            </div>
                                            <div className={"flex flex-col gap-1"}>
                                                <span className={"text-xs font-semibold first-letter:uppercase"}>{rule.name}</span>
                                                <span className={"text-xs first-letter:uppercase"}>{rule.description}</span>
                                            </div>
                                        </div>

                                    ))}

                                    <div className={"col-span-2"}>
                                        <div class="flex items-center gap-2 mt-5 mb-5">
                                       <span class="border-t border-gray-200 w-full">
                                       </span>
                                            <span class="text-sm text-gray-300 uppercase oswald-font">
                                            {t('ou')}
                                       </span>
                                            <span class="border-t border-gray-200 w-full">
                                       </span>
                                        </div>
                                        <div className={"flex flex-col gap-1 w-full mt-5"}>
                                            <div className={"relative p-2 "} id={"addFormRuleInterior"}>
                                                <div className="flex items-baseline flex-wrap lg:flex-nowrap gap-2.5  mt-3">
                                                    <label htmlFor="telephone" className={  "text-md font-semibold text-gray-700 lg:max-w-[25%] w-full first-letter:uppercase"}>
                                                        {t('Nom de la regle')}<span className={"text-red-600"}>*</span></label>
                                                    <div className={"flex flex-col gap-1 w-full"}>
                                                        <div className={"relative"}>
                                                            <input type="text" id="nameRuleInterior"
                                                                   name ="nameRuleInterior"


                                                                   className= { "border rounded  h-10  bg-gray-50 border-gray-300  text-gray-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 pr-4 pl-10"
                                                                   }
                                                                   placeholder={t("Regle de depart")}/>
                                                            <div class="absolute inset-y-0 left-0 pl-3
                                        flex items-center
                                        pointer-events-none">
                                                                <FontAwesomeIcon icon={faArrowRightArrowLeft} className={"text-gray-300"} />
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="flex items-baseline flex-wrap lg:flex-nowrap gap-2.5  mt-3">
                                                    <label htmlFor="telephone" className={  "text-md font-semibold text-gray-700 lg:max-w-[25%] w-full first-letter:uppercase"}>
                                                        {t('Description')}<span className={"text-red-600"}>*</span></label>
                                                    <div className={"flex flex-col gap-1 w-full"}>
                                                        <div className={"relative"}>
                                                            <input type="text" id="descRuleInterior"
                                                                   name ="descRuleInterior"


                                                                   className= { "border rounded  h-10  bg-gray-50 border-gray-300  text-gray-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 pr-4 pl-10"
                                                                   }
                                                                   placeholder={t("Description")}/>
                                                            <div class="absolute inset-y-0 left-0 pl-3
                                        flex items-center
                                        pointer-events-none">
                                                                <FontAwesomeIcon icon={faArrowRightArrowLeft} className={"text-gray-300"} />
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className={"flex justify-end gap-5 items-center mt-5"}>
                                                    <div onClick={addUserRuleInterior} data-ischeck={1}  data-typerule={'rco'} className={"first-letter:uppercase p-2 rounded  border border-gray-700  text-gray-500 text-sm hover:cursor-pointer  " +
                                                        "hover:border-gray-700 hover:bg-gray-50 hover:text-gray-500"}><FontAwesomeIcon icon={faPlus} />&nbsp;{t('Ajouter')}</div>
                                                </div>
                                            </div>
                                            <div className={"grid lg:grid-cols-2  justify-start gap-2 mt-5 w-full "}>
                                                {userRuleInterior.map((rule, index) => (
                                                    <>
                                                        {rule.type === 'rco' ?
                                                            <div
                                                                className={"lg:col-span-1   col-span-2 flex items-center justify-start gap-2 border-green-700 border border-dashed p-2 rounded w-full bg-green-50 text-green-700 hover:cursor-pointer rule"}>
                                                                <div class="relative size-[50px] shrink-0">
                                                                    <svg
                                                                        className={"stroke-1 stroke-green-600 fill-green-50" }
                                                                        fill="none" height="48" viewBox="0 0 44 48"
                                                                        width="44" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                        18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                        39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z"
                                                                              fill="">
                                                                        </path>
                                                                        <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                        18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                        39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z"
                                                                              stroke="">
                                                                        </path>
                                                                    </svg>
                                                                    <div
                                                                        class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 ">
                                                                        <FontAwesomeIcon icon={faArrowRightArrowLeft} />
                                                                    </div>
                                                                </div>
                                                                <div className={"flex flex-col gap-1 w-full" }>
                                                                            <span
                                                                                className={"text-xs font-semibold  first-letter:uppercase"}>{rule.name}</span>
                                                                    <span className={"text-xs first-letter:uppercase"}>
                                                                                {rule.description}
                                                                            </span>
                                                                    <div class={"text-xs text-gray-700  flex justify-end gap-2 items-center  "}>
                                                                        <FontAwesomeIcon icon={faTrash}  className={"cursor-pointer"}  data-idrule={index} onClick={deleteRuleUserInterior}/>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            : <></>}
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="mt-5 flex justify-between items-center">
                                <a href={"#"} onClick={handlePrev}>
                                    <FontAwesomeIcon icon={faCircleChevronLeft} className={"text-gray-700"}/>
                                </a>
                                <a href={"#"} onClick={handleNext} className={"bg-cyan-600 text-white p-2 rounded hover:bg-cyan-700 flex items-center justify-center gap-2"}>
                                    <FontAwesomeIcon icon={faCircleChevronRight} />
                                    {t('Wifi')}</a>
                            </div>
                        </>
                        :null}
                    {prepare == 4 ?
                        <>
                            <div className="flex  flex-col w-full items-center gap-2.5 justify-center ">
                                <label htmlFor="name" className={ errors.name ?  "text-md font-semibold text-red-700  " : "text-md font-semibold text-gray-700 "}>
                                    {t('Wifi')}
                                </label>
                            </div>
                            <div className="flex items-baseline flex-wrap lg:flex-nowrap gap-2.5  mt-3">
                                <label htmlFor="ssid" 
                                className={ errors.ssid ?  " text-md font-semibold text-red-700 lg:max-w-[25%] w-full" : "text-md font-semibold text-gray-700 lg:max-w-[25%] w-full"}>
                                    {t('Nom du réseaux Wifi')}<span className={"text-red-600"}>*</span>
                                    </label>
                                <div className={"flex flex-col gap-1 w-full"}>
                                    <div className={"relative"}>
                                <input type="text" id="ssid"
                                       name ="ssid"
                                       aria-invalid={errors.ssid ? "true" : "false"}
                                       defaultValue={inputs.ssid|| ""}
                                       {...register("ssid", { onChange: handleChange, required: true})}
                                       className= { errors.ssid ?
                                           "border rounded  h-10  bg-red-50 border-red-300  text-gred-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 pr-4 pl-10" :
                                           "border rounded  h-10  bg-gray-50 border-gray-300  text-gray-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 pr-4 pl-10"
                                       }
                                       placeholder={t("Wifi")}/>

                                        <div class="absolute inset-y-0 left-0 pl-3
                                        flex items-center
                                        pointer-events-none">
                                            <FontAwesomeIcon icon={faWifi} className={"text-gray-300"} />
                                        </div>
                                    </div>
                                    {errors.ssid && errors.ssid.type === "required" && (
                                        <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                                    )}
                                </div>
                            </div>

                            <div className="flex items-baseline flex-wrap lg:flex-nowrap gap-2.5  mt-3 items-center">
                                <label htmlFor="" className={" text-md font-semibold text-gray-700 lg:max-w-[25%] w-full"}>{t('Portail captif')}</label>
                                <ToggleSwitch checked={switchPortailCaptif} name="portailCaptif" label="Oui j'ai un portail captif pour gérer mon Wifi" onChange={setSwitchPortailCaptif} />
                            </div>
                            <a href={"https://okurens.io/article/3/quel-sont-les-regles-pour-proposer-du-wifi-dans-mon-logement-en-llocation-courte-dur%C3%A9e"}  className={"mt-5 text-blue-500"} target={"_blank"}>{t('En savoir plus sur la réglementation Wifi')}</a>

                            <div className="flex items-baseline flex-wrap lg:flex-nowrap gap-2.5  mt-3">
                                <label htmlFor="password" className={ errors.password ?  " text-md font-semibold text-red-700 lg:max-w-[25%] w-full" : "text-md font-semibold text-gray-700 lg:max-w-[25%] w-full"}>{t('Mot de passe du Wifi')}</label>
                                <div className={"flex flex-col gap-1 w-full"}>
                                    <div className={"relative"}>
                                <input type="text" id="password"
                                       name ="password"
                                       aria-invalid={errors.password ? "true" : "false"}
                                       defaultValue={inputs.password|| ""}
                                       {...register("password", { onChange: handleChange})}
                                       className= { errors.password ?
                                           "border rounded  h-10  bg-red-50 border-red-300  text-gred-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 pr-4 pl-10" :
                                           "border rounded  h-10  bg-gray-50 border-gray-300  text-gray-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 pr-4 pl-10"
                                       }
                                       placeholder={t("Mot de passe")}/>
                                        <div class="absolute inset-y-0 left-0 pl-3
                                        flex items-center
                                        pointer-events-none">
                                            <FontAwesomeIcon icon={faLock} className={"text-gray-300"} />
                                        </div>
                                    </div>
                                    {errors.password && errors.password.type === "required" && (
                                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                                )}
                                </div>
                                
                            </div>
                            <div className="flex items-baseline flex-wrap lg:flex-nowrap gap-2.5  mt-3  items-center">
                                <div className={"text-md font-semibold text-gray-700 lg:max-w-[25%] w-full"}>{t('Ecrire vos régles pour le wifi')}</div>
                                <div className={"flex flex-col gap-1 w-full mt-5"}>
                                    <div className={"relative"}>
                                                <textarea
                                                    data-nbsummary={3}
                                                    name ="ruleWifi"
                                                    rows= "5"
                                                    onChange={updateRuleWifi}

                                                    className= {"border rounded    bg-gray-50 border-gray-300  text-gray-700  w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600  p-2 "
                                                    }
                                                    placeholder={t("Régles pour le Wifi ...")}
                                                >
                                                    { ruleWifi}
                                                    </textarea>
                                    </div>
                                </div>

                            </div>
                            <div className={""}>
                                <div className={"flex  flex-wrap items-center justify-center gap-2 mt-5  "}
                                >
                                    <div className={"flex flex-col gap-2  lg:w-1/4 md:1/4"}>
                                        <div className={"flex flex-row flex-wrap justify-center items-center gap-2"}>
                                            <div class="relative size-[50px] shrink-0">
                                                <svg class="stroke-1 stroke-blue-600 fill-blue-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
			18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
			39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                    </path>
                                                    <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
			18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
			39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                    </path>
                                                </svg>
                                                <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 text-blue-600 font-semibold ">
                                                    <FontAwesomeIcon icon={faWifi} />
                                                </div>
                                            </div>
                                            <span className={"first-letter:uppercase text-gray-700 font-semibold text-xl"}>{t('Configurer le Wifi')}</span>
                                        </div>
                                        <div className={"text-gray-700 text-sm text-justify flex flex-col gap-2"}>
                                            <div className={"pl-2 mt-2"}>
                                                <span className={"flex gap-2 items-center"}>
                                                         <div class="relative size-[25px] shrink-0">
                                                            <svg class="stroke-1 stroke-gray-600 fill-gray-50" fill="none" height="24" viewBox="0 0 44 48" width="22" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                        18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                        39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                                </path>
                                                                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                        18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                        39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                                </path>
                                                            </svg>
                                                            <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 ">
                                                                <FontAwesomeIcon icon={faLock} className={"text-gray-500 text-xs"} />
                                                            </div>
                                                        </div>
                                                         <span>{t('Pensez à bien changer les mot de passe Admin de votre box internet')} </span>
                                                     </span>
                                                <span className={"flex gap-2 items-center mt-2"}>
                                                         <div class="relative size-[25px] shrink-0">
                                                            <svg class="stroke-1 stroke-gray-600 fill-gray-50" fill="none" height="24" viewBox="0 0 44 48" width="22" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                        18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                        39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                                </path>
                                                                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                        18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                        39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                                </path>
                                                            </svg>
                                                            <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 ">
                                                                <FontAwesomeIcon icon={faBox} className={"text-gray-500 text-xs"} />
                                                            </div>
                                                        </div>
                                                         <span>{t('Ne pas rendre accessible votre box internet et désactiver les paiements avec la box')} </span>
                                                     </span>
                                                <span className={"flex gap-2 items-center mt-2"}>
                                                         <div class="relative size-[25px] shrink-0">
                                                            <svg class="stroke-1 stroke-gray-600 fill-gray-50" fill="none" height="24" viewBox="0 0 44 48" width="22" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                        18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                        39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                                </path>
                                                                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                        18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                        39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                                </path>
                                                            </svg>
                                                            <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 ">
                                                                <FontAwesomeIcon icon={faShield} className={"text-gray-500 text-xs"} />
                                                            </div>
                                                        </div>
                                                         <span>{t('Mettre en place un portail captif pour se protéger du télèchargement illegal')} </span>
                                                     </span>
                                            </div>
                                        </div>
                                    </div>
                                    <img src={BgWifi} className={"hidden lg:block md:block"}/>
                                </div>
                            </div>

                            <div className="mt-5 flex justify-between items-center">
                                <a href={"#"} onClick={handlePrev}>
                                    <FontAwesomeIcon icon={faCircleChevronLeft} className={"text-gray-700"}/>
                                </a>
                                <a href={"#"} onClick={handleNext} className={"bg-cyan-600 text-white p-2 rounded hover:bg-cyan-700 flex items-center justify-center gap-2"}>
                                    <FontAwesomeIcon icon={faCircleChevronRight} />
                                    {t('Theme')}</a>
                            </div>
                        </>
                        :null}

                    {prepare == 5 ?
                        <>
                            <div className={"text-xl font-semibold text-gray-700 flex items-center justify-center"}>
                                {t('Choisir un theme')}
                            </div>
                            <div className={"flex flex-row flex-wrap mt-5 gap-2 justify-center items-center"}>
                                {listTheme.map((themeInfo) => (
                                    <img src={global.config.uriAPI+"/"+themeInfo.pathImgDemo} onClick={toggleTheme} data-theme={themeInfo.code}
                                         className={theme === themeInfo.code ? "rounded border-2 border-dashed border-green-700 shadow hover:cursor-pointer theme": "rounded  shadow hover:cursor-pointer theme opacity-60 hover:opacity-100"} />
                                    ))}
                            </div>
                            <div className="mt-5 flex justify-between items-center">
                                <a href={"#"} onClick={handlePrev}>
                                    <FontAwesomeIcon icon={faCircleChevronLeft} className={"text-gray-700"}/>
                                </a>
                                <button type="submit" disabled={button.disabled}
                                        className="border text-base border-cyan-700 bg-cyan-500 text-white p-2 w-fit rounded hover:bg-transparent hover:text-cyan-700  oswald-font
                                flex justify-center items-center gap-2">
                                    {iconButton.icon}&nbsp;&nbsp;{t('Créer mon livret')}
                                </button>
                            </div>

                        </>
                        :null}
                            </>
                            }
                        {prepare === 6 ?
                            <div className={"flex justify-center items-center"}>
                                <div className={"flex gap-5"}>
                                    <FontAwesomeIcon icon={faSpinner} spin className={"text-green-700 text-xl"} size={"2x"} />
                                    <span className={"first-letter:uppercase text-gray-700 text-sm"}>{t('Création de vote livret en cours, merci de patienter')}</span>
                                </div>
                            </div> : null }
                    </Form>

                </div>
            </section>
        </Layout>

    );
}

