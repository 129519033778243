import React, {useState}  from 'react';
import {render} from "@testing-library/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "flowbite-react";
import {useTranslation} from "react-i18next";
import OpenModal from "./ModalDelete";
export default function DeleteMedia(props) {

    const token = localStorage.getItem('token');
    const uuid = props.uuid;
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);

    const updateSetter = ()  =>{
        //props.setter.media=null;
        //props.setter({...props.setter});
        props.setter.media=[];

        props.setSetter({...props.setter});

    }

    return (
        <>
            <Tooltip content={t('Supprimer')}>
                <a href={"#"} className={"bg-red-600 text-white text-xs p-2 rounded"}  onClick={ () =>setOpenModal(true)}>
                    <FontAwesomeIcon icon={faTrash} className={""}></FontAwesomeIcon>
                </a>
            </Tooltip>
            {openModal === true ?
                <OpenModal path={"/app/media/delete/"+uuid}  setOpenModal={setOpenModal} callbackFunction={updateSetter} />
                : null}
        </>
    )

}