import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import BgNew from '../../../asset/img/bg-4.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faEdit,
    faHome,
    faCircleInfo, faLocationDot, faSearch, faAdd, faImage
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {Form, useParams} from "react-router-dom";
import i18n from "../../../component/i18n";
import  Meta  from "../../../component/Meta";
import {Breadcrumb, FileInput, Label, Select} from "flowbite-react";
import FontPicker from "react-fontpicker-ts";
import {TwitterPicker} from "react-color";
import Layout from '../Layout';
import BreadCrumb from "./BreadCrumb";
import UploadFile from "./uploadFile";
import DeleteMedia from "./function/DeleteMedia";


export default function AddCategory(props) {
    const MAX_FILE_SIZE = 5120 // 5MB
    let { uuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const [button, setButton] = useState({disabled : '', loader: ''});

    const [loadData, setLoadData] = useState(false);

    const [guideBook, setGuideBook] = useState([]);
    const [categorysDuplicate, setCategorysDuplicate] = useState([]);

    const [category, setCategory] = useState({media : {}});

    const fileRefUpload = React.createRef()
    const [upload, setUpLoad] = useState(0);
    const [eventUpload, setEventUpload] = useState(null);
    const[reload, setReload] = useState(1);
    const  contentUploadFile = (event) => {
        setUpLoad(1);
        setEventUpload(event);
    }

    const { t } = useTranslation();
    const meta = {
        title: t('Ajouter une categorie')
    }
    const [inputs, setInputs] =  useState({ logo: '', name: ''});

    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { errors },
    } = useForm();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }
    const [iconButton, setIconButton] = useState({icon : <FontAwesomeIcon icon={faAdd} />});

    const searchIcon = (event) => {
        const val = event.target.value;

        //https://api.freepik.com/v1/icons?term=voiture&family-id=3

        //x-freepik-api-key

        /*const myHeaders = new Headers();
       // myHeaders.append("Content-Type", "application/json");
        myHeaders.append('x-freepik-api-key', global.config.FREEPICK_API)
        myHeaders.append('Access-Control-Allow-Origin', '*');
        myHeaders.append('Access-Control-Allow-Headers','*');
        myHeaders.append('Access-Control-Allow-Methods','*');

        var requestOptions = {
            method: 'GET',
            headers: myHeaders
        };


        fetch('https://api.freepik.com/v1/icons?term='+val+'&family-id=3', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            })
            .catch((err) => {
            });*/


    }

    useEffect(() => {

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(global.config.uriAPI+'/app/guidebook/show/'+uuid, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setGuideBook(data);

                if (data.duplicateUUID !== null) {
                    var raw = JSON.stringify({"page":1 ,"limit": 200, "orderBy": [], 'name':'' });
                    var requestOptions = {
                        method: 'POST',
                        body: raw,
                        headers: myHeaders,
                        redirect: 'follow'
                    };
                    fetch(global.config.uriAPI+'/app/category/'+data.duplicateUUID, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            setCategorysDuplicate(data);
                        })
                        .catch((err) => {
                        });
                }
            })
            .catch((err) => {
            });

    }, []);
    const onSubmit = (data) => {

        setButton(previousState => {
            return { ...previousState, disabled: 'disabled' }
        });
        setIconButton(previousState => {
            return { ...previousState, icon:  <FontAwesomeIcon icon={faSpinner} spin/>}
        });

        const myHeaders = new Headers();
        //myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);
        //myHeaders.append('Content-Type', 'Multipart/form-data');

        let formData = new FormData()
        setLoadData(false);

        formData.append('name', inputs.name);
        formData.append("mediaId", category.media.id);
        formData.append('categoryOverrideUUID', data.overrideUuid==undefined?'':data.overrideUuid);
        formData.append('guideBookUuid', uuid);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI + '/app/category/create', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                    window.location.href = "/app/guidebook/show/"+uuid;
                    return;
            })
            .catch((err) => {
            });
    }

    const changeRefUpload = () => {
        fileRefUpload.current.click();
    }

    return (
        upload === 1 ?
            <UploadFile event={eventUpload}  setUpload = {setUpLoad}  setReload={setReload}  setGuideBook={setCategory} guideBook={category}  title={t('Image de la categorie')}  url={"uploadContentImg"}/>
            :
        <Layout>
            <div class="container mx-auto">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            {t('Ajouter une categorie')}
                        </h1>
                        <div class="flex items-center gap-2 text-sm font-normal text-gray-700">
                            <BreadCrumb links={[{name: guideBook.name, url: "/app/guidebook/show/"+guideBook.uuid}, {name : t("Liste des Categories"), url:"/app/guidebook/catList/"+guideBook.uuid }, {name: t('Ajouter une categorie'), url :null }]} />
                        </div>
                    </div>
                </div>
            </div>

            <section className={"container mx-auto"}>
                <Meta meta={meta} />
                <div className=" mt-5 shadow rounded border border-gray-200 w-full bg-white p-5 ">
                    <Form action="" method="post" onSubmit={handleSubmit(onSubmit)} noValidate id="formCreate" className={"flex flex-col gap-5"}>
                            {category.media.url !== undefined ?
                                <div className={"relative"}>
                                    <img src={global.config.uriAPI+"/"+category.media.url} className={"w-full h-full object-cover rounded"} />
                                    <div className={"absolute top-5 right-5"}>
                                        <DeleteMedia  setSetter={setCategory}  setter={category} uuid={category.media.uuid} />
                                    </div>
                                </div>
                                :
                                <div id="fileUpload" className="w-full">
                                    <input type={"file"} hidden={true} ref={fileRefUpload} onChange={contentUploadFile} />
                                    <div onClick={changeRefUpload} className={"shadow rounded border border-dashed border-green-400 w-full bg-white p-5  h-full flex flex-col items-center justify-center cursor-pointer"}
                                                                   style={{
                                                                       backgroundImage: `url(${BgNew})`,
                                                                       backgroundSize: 'cover',
                                                                   }}>
                                <div class="relative size-[50px] shrink-0">
                                    <svg class="w-full h-full stroke-1 stroke-green-600 fill-green-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                        </path>
                                        <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                        </path>
                                    </svg>
                                    <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4">
                                        <FontAwesomeIcon icon={faImage} className={"text-green-600"} />
                                    </div>
                                </div>
                                <div className={"first-letter:uppercase text-lg font-medium text-gray-900 hover:text-primary-active mb-px mt-5"}>
                                    {t('Ajouter une image')}
                                </div>
                                <span class="text-2sm text-gray-700">
                                        {t(' minimun 800*400px format PNG, JPG , HEIC')}
                                   </span>
                            </div>
                                </div>
                            }


                        <div className="mt-3 w-full">
                            <label htmlFor="name" className={ errors.name ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2 text-gray-700 font-semibold"}>{t('Le nom de la catégorie')}</label>
                            <input type="text" id="name"
                                   name ="name"
                                   aria-invalid={errors.name ? "true" : "false"}
                                   defaultValue={inputs.name|| ""}
                                   {...register("name", { onChange: handleChange, required: true})}
                                   className= { errors.name ?
                                       "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                       "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600 w-full"
                                   }
                                   placeholder={t("Nom de la catégorie")}/>
                            {errors.name && errors.name.type === "required" && (
                                <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                            )}
                        </div>
                        {guideBook.duplicateUUID != null ?
                            <div className="mt-3 w-full">
                                <label htmlFor="name" className={ errors.name ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2"}>{t('Cette catégorie remplace la catégorie du livret parent ?')}</label>
                                <Select
                                    id={"overrideUuid"}
                                    name ="overrideUuid"
                                    {...register("overrideUuid", { onChange: handleChange, required: true})}
                                    aria-invalid={errors.name ? "true" : "false"}
                                >
                                    <option value={""}>{t('Choisir une catégorie')}</option>
                                    {categorysDuplicate.map((category,index) =>
                                        <option value={category.uuid}>{category.name}</option>)}
                                </Select>
                                {errors.overrideUuid && errors.overrideUuid.type === "required" && (
                                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                                )}
                            </div>
                            : <></> }
                        {category.media.id != undefined ?
                            <button type="submit" disabled={button.disabled}
                                    className="border-2 border-cyan-700 bg-cyan-500 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-cyan-700 font-semibold">
                                {iconButton.icon}&nbsp;&nbsp; {t('Enregistrer')}
                            </button>
                            :<div
                                     className="border-2 border-gray-700 bg-gray-500 text-white py-1 w-full rounded-md flex items-center justify-center p-5">
                                {iconButton.icon}&nbsp;&nbsp; {t('Enregistrer')}
                            </div> }

                    </Form>
                </div>
            </section>
        </Layout>

    )

}