import React, {useState} from 'react';
import {Button, Modal} from "flowbite-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";


const  OpenModal = (props) =>  {

    const [openModal, setOpenModal] = useState(true);
    const [pathDelete, setPathDelete] = useState(props.path);
    const [inDelete, setInDelete] = useState(0);
    const { t } = useTranslation();
    const token = localStorage.getItem('token');

    const deleteCall = () => {

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)
        setInDelete(1);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+pathDelete, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setInDelete(0);
                setOpenModal(false);
                props.setOpenModal(false);
                props.callbackFunction();
               // setReload(1);
            })
            .catch((err) => {
            });
    }

    const initValue = () => {
        setOpenModal(false);
        props.setOpenModal(false);
    }



    return (
        <Modal show={openModal} size="md" onClose={initValue} popup >
            <Modal.Header />
            <Modal.Body>
                <div className="text-center">
                    <FontAwesomeIcon icon={faCircleExclamation} className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        {t('Vous confirmer vouloir supprimer cette élèment ?')}
                    </h3>
                    {inDelete === 0 ?
                        <div className="flex justify-center gap-4">
                            <a href={"#"}
                               data-uuid = {""}
                               className={" border-2 border-red-700  text-red-700 red text-xs rounded p-1 flex items-center justify-center hover:bg-red-700 hover:text-white"} color="failure"
                               onClick={() => deleteCall()}>
                                {t('Oui, supprimer')}
                            </a>
                            <Button color="gray" onClick={initValue}>
                                {t('Non')}
                            </Button>
                        </div>
                        :
                        <div>
                            <FontAwesomeIcon icon={faSpinner} spin size="2x"/> {t('Suppression en cours')}
                        </div>
                    }

                </div>
            </Modal.Body>
        </Modal>
    )

}

export default  OpenModal;