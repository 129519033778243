import { Form, useParams } from "react-router-dom";
import Error from "../../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../asset/img/okurensiologo.svg';
import Loginimg from '../../asset/img/loginimg.png';
import Lang from '../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowAltCircleDown, faArrowLeft, faArrowRight, faArrowRightArrowLeft, faBook, faChartLine, faChevronLeft,
    faCircleExclamation,
    faCircleInfo, faEllipsisVertical, faEnvelope, faEye,
    faHome,
    faLocationDot, faMap, faPercent, faPhone,
    faSearch, faTrash
} from "@fortawesome/free-solid-svg-icons";
import {faArrowRightToBracket} from "@fortawesome/free-solid-svg-icons";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import {Navigate} from "react-router-dom";
import Meta from "../../component/Meta";
import LangFront from "./langFront";
import {Avatar, Dropdown, Tooltip} from 'flowbite-react';
import PowerBy from "./powerby";
import LogoAnime from "../../asset/img/logoanime.gif";
import NBar from "./nbar";
import ModalTopic from "./modalTopic";
import {faFilter} from "@fortawesome/free-solid-svg-icons/faFilter";
import TopicView from "./topicView";
import {faSquareWhatsapp, faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input'

function adjust(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

function hexToRGB(hex, alpha) {
    console.log(hex);
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export default function SubCategory(props) {
    let { uuid, categoryUuid } = useParams();

    const [categorys, setCategorys] = useState([]);
    const [loadData, setLoadData] = useState(false);
    const { t } = useTranslation();
    const [guideBook, setGuideBook] = useState([]);
    const [langage, setLangage] = useState([]);
    const langId = localStorage.getItem('langId');
    const [styles, setStyles] = useState({overlay: '', item: '', itemActive: '', navigationBar: ''});
    const [meta, setMeta] = useState({title: '', font: '', description: '', image: ''});

    const [hoveredItems, setHoveredItems] = useState([]);
    const [topicList, setTopicList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [nbView, setNbView] = useState(7);

    const [topicViewList, setTopicViewList] = useState([]);
    const [topicTmpList, setTopicTmpList] = useState([]);
    const [filterSubCategory, setFilterSubCategory] = useState([]);
    const [filterTag, setFilterTag] = useState([]);
    const [loadFilter, setLoadFilter] = useState(false);
    const [topicSelected, setTopicSelected] = useState();
    const [listTag, setListTag] = useState([]);

    useEffect(() => {

        fetch(global.config.uriAPI+'/langage')
            .then((response) => response.json())
            .then((data) => {
                setLangage(data);
            })
            .catch((err) => {
            });

        fetch(global.config.uriAPI+'/guidebook/'+uuid+"/"+langId, )
            .then((response) => response.json())
            .then((data) => {
                setGuideBook(data);


                const meta = {
                    title: data.name,
                    font: data.settings.mainFont,
                    description: data.name,
                    image: global.config.uriAPI+'/'+data.media.url
                }
                setMeta(meta);
                /*updateStyles.item[':hover']= {
                    backgroundColor: adjust(data.settings.navigationBarColor, -40)
                }*/
                fetch(global.config.uriAPI+'/guidebook/subcategory/'+categoryUuid+"/"+langId+"/"+uuid, )
                    .then((response) => response.json())
                    .then((data) => {
                        setCategorys(data);
                        setLoadData(true);
                        setTopicList(data.topicList)
                        setTopicTmpList(data.topicList);
                        setTopicViewList(data.topicList.slice(0, nbView));
                        //setTopicList([...topicList, data.subCategorys.topics]);

                    })
                    .catch((err) => {
                    });
            })
            .catch((err) => {
            });
    }, [langId]);

    const handleHover = (index) => {
        return () => {
            setHoveredItems(previousState => {
                return {...previousState, [index]: !previousState[index]}
            })
        }
    };


    const handleScroll = () => {
        if (document.body.scrollHeight - 300 < window.scrollY + window.innerHeight) {
            setLoading(true);
        }
    };

    useEffect(() => {
        if (loading == true) {
            setNbView((nbView) => nbView + 7);
            setTopicViewList(topicTmpList.slice(0, nbView));
            setLoading(false);
        }
    }, [loading]);

    function debounce(func, delay) {
        let timeoutId;
        return function (...args) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    // adding debounce to the eventListner
    window.addEventListener("scroll", debounce(handleScroll, 500));

    const clickSubCategory = (subCategoryId) => {

        //const subCategoryId = parseInt(event.currentTarget.dataset.id);
        if(filterSubCategory.includes(subCategoryId)){
            setFilterSubCategory(filterSubCategory.filter(item => item !== subCategoryId));
        }else{
            setFilterSubCategory([...filterSubCategory, subCategoryId]);
        }
        setLoadFilter(true);
    }

    const clickFilterTag = (tagCode) => {
        if (filterTag.includes(tagCode)) {
            setFilterTag(filterTag.filter(item => item !== tagCode));
        } else {
            setFilterTag([...filterTag, tagCode]);
        }
        setLoadFilter(true);
    }

    useEffect(() => {
        const filteredList = topicList.filter(topic => {
            return filterSubCategory.includes(topic.sub_category_id);
        });
        // on doit metre à jour topicViewList avec les
        setTopicTmpList(filteredList);
        if (filterSubCategory.length === 0) {
            setTopicTmpList(topicList);
        }

        if (filterTag.length > 0) {
            const filteredListTag = topicTmpList.filter(topic => {
                return Array.isArray(topic.tagsCode) ? topic.tagsCode.some(tag => filterTag.includes(tag)) : false;
            });
            setTopicTmpList(filteredListTag);
        }


        // on doit aussi regarder pour les tags
    }, [filterSubCategory, filterTag]);

    useEffect(() => {
        setNbView(7);

        setTopicViewList(topicTmpList.slice(0, nbView));
        setLoadFilter(false)
    }, [topicTmpList]);


    return (
        <div className={"min-h-screen"} style={{
            backgroundColor: "#F5F5F5",
        }}>
            {loadData ? <>
            <div  className={"container  max-w-screen-md mx-auto  flex flex-col gap-5"} >
                <Meta meta={{
                    title: guideBook.name,
                    font: guideBook.settings.mainFont,
                    description: guideBook.name,
                    image: global.config.uriAPI+'/'+guideBook.media.url,
                    themeColor: guideBook.settings.secondaryColor
                }} />
                <header className={"flex flex-col justify-between  w-full min-h-[250px] rounded-b-2xl shadow-lg"}
                    style={{
                        backgroundImage: categorys.category.module_name === "logement" ?  `linear-gradient(
                            rgba(255, 255, 255, 0),
                            rgba(255, 255, 255, 0)
                        ), url(${global.config.uriAPI+'/'+guideBook.media.url})` :  `linear-gradient(
                             rgba(255, 255, 255, 0),
                            rgba(255, 255, 255, 0)
                        ), url(${global.config.uriAPI+'/'+categorys.category.media.url})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                >
                    <div className={"flex flex-row justify-between items-start"}>
                        <img src={"/themelogo/"+guideBook.settings.code+".svg"}  width={"10%"} className={"m-5"}/>
                        <LangFront langage={langage} settings={guideBook.settings} />
                    </div>

                </header>
                <div className={" flex justify-between items-center pr-5 pl-5"}>
                    <div className={"flex flex-row gap-2"}>
                        <a href={document.referrer} className={"rounded text-sm p-1 w-5 flex justify-center items-center"}
                           style = {{
                               backgroundColor: guideBook.settings.secondaryColor,
                               color: guideBook.settings.primaryColor
                           }}
                        ><FontAwesomeIcon icon={faChevronLeft} /></a>
                        <h1 class={"text-xl text-gray-700 font-bold first-letter:uppercase oswald-font"}>{categorys.category.name}</h1>
                    </div>

                    <div className={"flex flex-row gap-5"}>

                        <Dropdown
                            arrowIcon={false}
                            inline
                            label={<FontAwesomeIcon icon={faFilter} className={"text-gray-400 rounded p-2"}/>}
                            placement="left"

                        >

                        {categorys.subCategorys.map((subCategory, index) =>
                            <Dropdown.Item
                                data-id={subCategory.id}
                                onClick={()=> clickSubCategory(subCategory.id)}
                                className={"flex flex-row gap-2 items-center"}
                                style = {{
                                    backgroundColor: filterSubCategory.includes(subCategory.id) ? guideBook.settings.primaryColor : ""
                                }}
                            >
                                <i className={subCategory.icon} style={{
                                    color: guideBook.settings.secondaryColor,

                                }} />
                                <span className={"first-letter:uppercase"}>{subCategory.name}</span>
                            </Dropdown.Item>
                            )}
                            <Dropdown.Divider />

                            {categorys.tags.map((tag, index) =>
                                    <Dropdown.Item
                                        onClick={() => clickFilterTag(tag.code)}
                                        className={"flex flex-row gap-2 items-center"}
                                        style = {{
                                            backgroundColor: filterTag.includes(tag.code) ? guideBook.settings.primaryColor : ""
                                        }}
                                    >
                                        <i className={tag.icon} style={{
                                            color: guideBook.settings.secondaryColor,
                                        }} />
                                        <span className={"first-letter:uppercase"}>{tag.name}</span>
                                    </Dropdown.Item>
                                )}
                        </Dropdown>
                    </div>
                </div>
                {categorys.category.module_name === "logement" ?
                    <div className={"p-5 lg:p-0"}>
                        <div className={"bg-white rounded p-5 text-gray-600 mt-5 flex flex-col gap-3"}>
                            <div><b>{t('L\'adresse du logement')}</b> : {guideBook.address}</div>
                            <div className={"text-gray-600 text-sm"}>{t('Latitude')} : {guideBook.longitude} / {t('Longitude')} : {guideBook.latitude}</div>
                            <div>
                                <a href={`https://www.google.com/maps/dir/?api=1&destination=${guideBook.latitude},${guideBook.longitude}`}
                                   className={"text-sm font-semibold"}
                                   style={{
                                       color: guideBook.settings.secondaryColor
                                   }}
                                   target={"_blank"}><FontAwesomeIcon icon={faMap} /> {t("Voir l'itinéraire")}</a>
                            </div>
                            <p className={"text-gray-600 text-sm"}>{t('Vous avez une question , vous pouvez me contactez avant ou pendant votre séjour')}</p>
                            <div className={"flex gap-2 text-gray-600 items-center mt-2 flex-wrap"}>
                                <a href={"https://wa.me/"+guideBook.contactPhone+"?text="+t('Question concernant la location')+" "+guideBook.name}
                                   style={{
                                       backgroundColor: "#25d366",
                                   }}
                                   target="_blank" className={"flex flex-col  gap-2 items-center rounded p-1 text-white justify-center shadow"}>
                                    <div className={"flex gap-2 items-center"}>
                                        <FontAwesomeIcon icon={faWhatsapp} />{formatPhoneNumberIntl(guideBook.contactPhone)}
                                    </div>
                                </a>
                                <div className={"flex gap-2 items-center"}><FontAwesomeIcon icon={faEnvelope} />{guideBook.contactEmail}</div>
                            </div>
                        </div>
                        <div className={" w-full mt-5"} id={"rule"}>
                            <h2 className={"text-gray-700 first-letter:uppercase font-semibold lato-regular"}>{t("Le reglement Intérieur à respecter durant votre séjour")}</h2>
                            {guideBook.ruleInterior.map((rule) =>
                                <div className={"flex flex-col gap-2 rounded  p-2  bg-white border  mt-2"}>
                                    <div className={"flex gap-2 items-center"}>
                                        <div className={rule.isCheck === true ? "relative size-[50px] shrink-0 text-green-700": "relative size-[50px] shrink-0 text-red-700"}>
                                            <svg className={rule.isCheck === true ?"stroke-1 stroke-green-700 fill-green-50 text-green-700": "stroke-1 stroke-red-700 fill-red-50 text-red-700"} fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                            18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                            39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                </path>
                                                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                            18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                            39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                </path>
                                            </svg>
                                            <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 ">
                                                {rule.icon === "" ? <i className={"fa-solid fa-circle-question text-md"}></i>: <i className={rule.icon+" text-md"}></i>}

                                            </div>
                                        </div>
                                        <div className={"flex flex-col gap-2"}>
                                                                <span className={"first-letter:uppercase font-semibold text-xs text-gray-700 oswald-font" }>
                                                            {rule.name}</span>
                                            <span className={"text-gray-600 text-sm lato-regular"}> {rule.isCheck === true ? rule.description: rule.descNotCheck }</span>
                                        </div>

                                    </div>

                                </div>
                            )}
                        </div>

                        <div className="mt-5 shadow rounded border border-gray-200 w-full bg-white p-5" id={"wifi"}>
                            <h2 className={"text-gray-700 first-letter:uppercase font-semibold oswald-font"}>{t("Wifi")}</h2>
                            <div className={"flex gap-5 w-full flex-wrap  justify-between text-sm"}>
                                <div className={"flex flex-col gap-2"}>
                                    <div className={"mt-3 p-2 rounded"} style={{
                                        backgroundColor: guideBook.settings.secondaryColor
                                    }}>
                                        <b className={"text-white font-semibold lato-regular text-sm"}>{t('Nom du réseaux Wifi')}</b> : <span className={"text-white"}>{guideBook.wifi.ssid}</span>
                                        <div className={"text-white text-xs lato-regular"}>{t('Scanner le QrCode pour vous connecter')}</div>
                                    </div>
                                    {guideBook.wifi.portailCaptif === 1 ? <>

                                    </>: <>
                                    {guideBook.wifi.password !== "" ?
                                        <div className={"mt-3  p-2 rounded"} style={{
                                            backgroundColor: guideBook.settings.secondaryColor
                                        }}>
                                            <b className={"text-white font-semibold lato-regular text-sm"}>{t('Mot de passe')}</b> : <span className={"text-white"}>{guideBook.wifi.password}</span>
                                        </div> : null }
                                    </> }

                                    {guideBook.wifi.content !== "" ?
                                        <div className={"text-yellow-600 p-2 bg-yellow-100 rounded w-full lato-regular text-sm"} dangerouslySetInnerHTML={{__html: guideBook.wifi.content}}></div>
                                        : <></>}

                                </div>

                                    <img src={global.config.uriAPI+'/qrcode/wifi-'+guideBook.uuid+'.png'} className={"w-1/4"} />

                            </div>
                        </div>

                        <div className="mt-5 shadow rounded border border-gray-200 w-full bg-white p-5">
                                <h2 className={"text-gray-700 first-letter:uppercase font-semibold oswald-font"}>{t('Informations de départ et d\'arrivé')}</h2>
                                <div className={"mt-5 flex flex-col gap-2"}>
                                    <p className={"text-sm text-gray-600 lato-regular"}>{t('Lors de votre séjour vous pouvez arriver à partir de')} <span className={"font-bold underline "} style={{color:guideBook.settings.secondaryColor }}>{guideBook.clockIn} h</span> {t('et vous devrez rendre le logement avant ')} <span className={"font-bold underline "} style={{color:guideBook.settings.secondaryColor }}>{guideBook.clockOut} h</span> {t('le dernier jour')}</p>
                                    {guideBook.keyDoor ?
                                        <p className={"text-sm text-gray-600 lato-regular"}>{t('Le code pour la boite à clef est le')} <span className={"font-bold underline "} style={{color:guideBook.settings.secondaryColor }}>{guideBook.keyDoor}</span></p>
                                        : <></>}
                                    {guideBook.kd.content !== "" ?
                                        <p className={"text-sm text-white rounded p-2 lato-regular"}
                                           style={{
                                               backgroundColor: guideBook.settings.secondaryColor
                                           }}
                                        >
                                            { guideBook.kd.content }
                                        </p>
                                        : <></>}
                                </div>
                        </div>
                        <div>
                                <div className={"mt-5"}>
                                    <h2 className={"text-gray-700 font-semibold mb-2 oswald-font"}>{t('Avant de partir merci de bien vérifier les points suivant svp')}</h2>
                                    <div className={"grid lg:grid-cols-2 grid-cols-1 gap-2"}>
                                        {guideBook.ruleCheckout.map((rule) =>
                                            <div className={"flex flex-col gap-2 rounded  p-2  bg-white border border-gray-200"}>
                                                <div className={"flex gap-2 items-center"}>
                                                    <div className={ "relative size-[50px] shrink-0 text-gray-700"}>
                                                        <svg className={"stroke-1 stroke-gray-700 fill-gray-50 text-gray-700"} fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                            18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                            39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                            </path>
                                                            <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                            18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                            39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                            </path>
                                                        </svg>
                                                        <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 ">
                                                            {rule.icon === ""? <FontAwesomeIcon  icon={faArrowRightArrowLeft} className={"text-md"}/> :  <i className={rule.icon+" text-md"}></i>}

                                                        </div>
                                                    </div>
                                                    <div className={"flex flex-col gap-2"}>
                                                                <span className={"first-letter:uppercase font-semibold text-sm oswald-font text-gray-700"}>
                                                            {rule.name}</span>
                                                        <span className={"text-gray-600 text-sm lato-regular"}> {rule.description }</span>
                                                    </div>

                                                </div>

                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                    </div>
                    :<></>
                }
            </div>
                <div className={"container max-w-screen-md mx-auto p-5 md:p-0 lg:p-0 mt-5"}>
                    {loadFilter === false ?
                        <div className={"flex flex-col gap-2 items-stretch"}>
                            {topicViewList.map((topic, index) =>
                                    <TopicView guideBook={guideBook} topic={topic} setTopicSelected={setTopicSelected} />
                                )}
                        {loading && <h1 className={"mt-5 text-gray-600 font-semibold"}>{t('Chargement...')}</h1>}
                            <ModalTopic topic={topicSelected} setTopicSelected={setTopicSelected} guideBook={guideBook} />
                        </div>
                        : <>
                            <FontAwesomeIcon icon={faSpinner} spin={true} />
                        </> }

                </div>
                <div className={"text-gray-600  container max-w-screen-md mx-auto p-5  justify-center items-center flex text-xs mt-10 mb-20 gap-5"}>
                    <span> @{t('Copyright by Okurensio')}</span> <a href={"https://okurens.io"} target={"_blank"}>https://okurens.io</a>
                </div>
                    <div></div>
                <NBar styles={guideBook.settings} guideBook={guideBook}  active={"accueil"}/>
            </>
                :
                <img src={LogoAnime}
                     style={{
                         width: 80,
                         $height: 80
                     }}
                     className={"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"}/>
            }

        </div>
    )
}