import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faEdit,
    faHome,
    faCircleInfo, faLocationDot, faSearch, faAdd
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {Form, useLocation, useParams} from "react-router-dom";
import i18n from "../../../component/i18n";
import  Meta  from "../../../component/Meta";
import {Breadcrumb, FileInput, Label} from "flowbite-react";
import FontPicker from "react-fontpicker-ts";
import {TwitterPicker} from "react-color";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import MyCustomUploadAdapterPlugin from "../../../component/PluginUploader";
import TopicContent from "./topic/topicContent";
import TopicPlace from "./topic/topicPlace";
import TopicElectrical from "./topic/topicElectrical";
import TopicWifi from "./topic/topicWifi";
import Layout from "../Layout";
import BreadCrumb from "./BreadCrumb";
import UploadFile from "./uploadFile";


export default function AddTopic(props) {
    const MAX_FILE_SIZE = 5120 // 5MB
    let { guideBookUuid, subCategoryUuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const [contentType, setContentType] = useState('');

    const { t } = useTranslation();
    const meta = {
        title: t('Ajouter un contenu')
    }
    const langId = localStorage.getItem('langId');
    const [topic, setTopic] =  useState({media : {}});
    const[reload, setReload] = useState(1);
    const  contentUploadFile = (event) => {
        setUpLoad(1);
        setEventUpload(event);
    }
    const fileRefUpload = React.createRef()
    const [upload, setUpLoad] = useState(0);
    const [eventUpload, setEventUpload] = useState(null);
    const changeRefUpload = () => {
        fileRefUpload.current.click();
    }

    const [redirectUrl, setRedirectUrl] = useState(null);
    //faut l'info sur la categori ou la sous categorie

    const [tags, setTags] = useState([]);


    useEffect(() => {
        const myHeaders = new Headers();
        //myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,

            redirect: 'follow'
        };

        fetch(global.config.uriAPI + '/app/tags/'+langId, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setTags(data)
            })
            .catch((err) => {
            });
    }, [])



        //faut l'info sur la categori ou la sous categorie

    return (
        upload === 1 ?
            <UploadFile event={eventUpload}  setUpload = {setUpLoad}  setReload={setReload}  setGuideBook={setTopic} guideBook={topic}  title={t('Image du contenu')}  url={"uploadContentImg"}/>
            :
        <Layout>
            <div class="container mx-auto">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900 first-letter:uppercase">
                            {t('Ajouter un contenu')}
                        </h1>
                        <div class="flex items-center gap-2 text-sm font-normal text-gray-700">
                            <BreadCrumb links={[{name: t("Livret"), url: "/app/guidebook/catSubList/"+guideBookUuid+"/"+subCategoryUuid}]} />
                        </div>
                    </div>
                </div>
            </div>

            <section className={"container mx-auto"}>
                <Meta meta={meta} />
            <div className=" sm:container mx-auto w-full">
                <div className={"p-10 rounded bg-cyan-600 mt-10"}>
                    <label htmlFor="countries" className="block mb-2 text-sm dark:text-white font-bold text-white">
                        {t('Choissisez un type de contenu')}
                    </label>
                    <select id="countries" onChange={(e) => setContentType(e.target.value)}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value={""}>{t('Choisir une option de contenu')}</option>
                        <option value={"content"}>{t('Ajouter une description')}</option>
                        <option value={"place"}>{t('Ajouter une adresse (restaurant, zoo etc)')}</option>
                        <option value={"electrical"}>{t('Ajouter une notice d\'utilisation (éléctoménager, baignoire etc)')}</option>
                    </select>
                </div>
                <div className=" mt-5 shadow rounded border border-gray-200 w-full bg-white p-5 ">
                    {contentType === '' ?
                        <></>
                        : <></> }
                    {contentType === 'content' ?
                        <TopicContent tags={tags} guideBookUuid={guideBookUuid} subCategoryUuid={subCategoryUuid} topic={topic} setTopic={setTopic} fileRefUpload={fileRefUpload} contentUploadFile={contentUploadFile} changeRefUpload={changeRefUpload}/>
                        : <></> }
                    {contentType === 'place' ? <TopicPlace tags={tags} guideBookUuid={guideBookUuid} subCategoryUuid={subCategoryUuid} topic={topic} setTopic={setTopic} fileRefUpload={fileRefUpload} contentUploadFile={contentUploadFile} changeRefUpload={changeRefUpload}/> : <></> }
                    {contentType === 'electrical' ? <TopicElectrical tags={tags} guideBookUuid={guideBookUuid} subCategoryUuid={subCategoryUuid} topic={topic} setTopic={setTopic} fileRefUpload={fileRefUpload} contentUploadFile={contentUploadFile} changeRefUpload={changeRefUpload}/> : <></> }
                </div>
            </div>
        </section>
        </Layout>
    )

}