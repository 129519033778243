import React, {useEffect, useState} from 'react';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import {useTranslation} from "react-i18next";

// You need to add the entire icon set
library.add(fas)

let fasArray = Object.keys(library.definitions.fas)

const IconSearch = (props) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedIcon, setSelectedIcon] = useState('');

    const [filteredIcons , setFilteredIcons] = useState([]);
    const { t } = useTranslation();
    const handleIconClick = (event) => {
        setSelectedIcon(event.currentTarget.dataset.icon);
        props.setIcon( "<i class='fa-solid fa-"+event.currentTarget.dataset.icon+" fa-2x' /> ");
        props.setIconSelected('fa-solid fa-'+event.currentTarget.dataset.icon);
        // Copie le code d'importation complet
    };

    // Fonction pour convertir le nom de l'icône en format d'affichage
    const getIconDisplay = (iconName) => {
        // Simule visuellement l'icône avec le nom
        return (

                <i className={"fa-solid fa-"+iconName+" fa-2x"} />

        );
    };

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);

        const searchTerm = e.target.value
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') // Supprime les accents
            .replace(/[^a-z0-9]/g, ''); // Supprime les tirets et autres caractères spéciaux

        const filtered = fasArray
            .filter(icon =>
                icon
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '') // Supprime les accents
                    .replace(/[^a-z0-9]/g, '') // Supprime les tirets et autres caractères spéciaux
                    .includes(searchTerm)
            )
            .sort((a, b) => {
                // Trie les résultats en fonction de l'emplacement du terme recherché (plus proche du début = meilleur match)
                const aIndex = a.toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .replace(/[^a-z0-9]/g, '')
                    .indexOf(searchTerm);
                const bIndex = b.toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .replace(/[^a-z0-9]/g, '')
                    .indexOf(searchTerm);

                return aIndex - bIndex;
            })
            .slice(0, 30); // Limite à 15 résultats
        setFilteredIcons(filtered);
    };

    return (
        <div className="">
            <div className="mt-5">
                <input
                    type="text"
                    placeholder={t("Rechercher une icône")}
                    value={searchTerm}
                    onChange={handleInputChange}
                    className="w-full mb-2 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
                {filteredIcons.map((iconName) => (
                    <div
                        key={iconName}
                        data-icon={iconName}
                        onClick={handleIconClick}
                        className="p-4 border rounded-lg hover:bg-gray-100 cursor-pointer flex flex-col items-center transition-colors text-sm text-gray-600"
                    >
                        {getIconDisplay(iconName)}
                    </div>
                ))}
            </div>

            {filteredIcons.length === 0 && (
                <div className="text-center text-gray-500 mt-4">
                    {t('Aucune icône trouvée pour')} "{searchTerm}"
                </div>
            )}

            <div className="mt-4 text-sm text-gray-500">
                {filteredIcons.length} {t('icônes trouvées')}
            </div>
        </div>
    );
};

export default IconSearch;