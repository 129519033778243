import i18n from "./i18n";
import React, {Component, useEffect, useState} from 'react';
import FrFlag from '../asset/img/fr.jpg';
import EnFlag from '../asset/img/en.jpg';
import {changeLanguage} from "i18next";

function Lang(props) {
    const lng = i18n.language;
    const langId = localStorage.getItem('langId');
    function onChangeLang (langStr, langId) {
        i18n.changeLanguage(langStr);
        localStorage.setItem('langId', langId);
        localStorage.setItem('langStr', langStr);
    };

    console.log(lng);
    console.log(localStorage.getItem('langStr'))
    //if (lng != localStorage.getItem('langStr')) {
      //  i18n.changeLanguage(localStorage.getItem('langStr'));
    //}


    if (localStorage.getItem('langId') === null) {
        localStorage.setItem('langId', 1);
    }

    const [langage, setLangage] = useState([]);
    useEffect(() => {
        fetch(global.config.uriAPI+'/langage')
            .then((response) => response.json())
            .then((data) => {
                setLangage(data);
            })
            .catch((err) => {
            });
    }, []);

    return (
        <div className={"flex  gap-2 justify-center items-center"}>
            {langage.map((lang) =>
                <a href="#" onClick={() => onChangeLang(lang.lang, lang.id)} className={"" +
                    `${lng === lang.lang ? "border-2 rounded-full border-gray-300": "rounded-full hover:rounded-full hover:border-gray-300  hover:border-2"}`}
                >
                    <img src={global.config.uriAPI+"/"+lang.flag} className={"w-4 h-4 rounded-full shadow-lg  m-1"} /></a>
            )
            }
        </div>
    )
}

export default Lang;


