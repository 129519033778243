import React, {useState} from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrows, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "flowbite-react";
import {useTranslation} from "react-i18next";
import DeleteByPath from "./function/DeleteByPath";
export default function  CategoryShow(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: props.id});
    const { t } = useTranslation();

    const [hoverIndex, setHoverIndex] = useState(null);

    return (
        props.category.module_name === 'logement' ?

                <div ref={setNodeRef}  {...attributes} {...listeners} key={props.category.id} id={props.category.id} className={"shadow rounded border border-gray-200 w-full bg-white p-5  h-full flex flex-col min-h-[300px]  bottom-5 justify-between"}
                     style={{
                         backgroundImage: `url(${global.config.uriAPI+'/'+props.guideBook.media.url})`,
                         backgroundSize: 'cover',
                         backgroundPosition: 'center',
                         transform: CSS.Transform.toString(transform),
                         transition,
                         touchAction: "none",
                     }}
                >
                    {props.guideBook.hasDuplicate === true? null :   <><div className={"flex justify-between items-center flex-row"}>
                        <FontAwesomeIcon icon={faArrows} className={"bg-gray-100 rounded p-2 text-gray-600"}/>
                        <a href={"/app/guidebook/editcategory/"+props.guideBook.uuid+"/"+props.category.uuid} className={"bg-gray-100 rounded p-2 text-gray-600"}><FontAwesomeIcon icon={faEdit} /></a>
                    </div>
                        </>
                    }
                    <a href={"/app/guidebook/catSubList/"+props.guideBook.uuid+"/"+props.category.uuid} className={"rounded p-5 text-white font-bold flex justify-center items-center text-lg first-letter:uppercase cursor-pointer"}
                       onMouseEnter={() => setHoverIndex(props.category.id)} // Définit l'index survolé
                       onMouseLeave={() => setHoverIndex(null)}
                       style={{ backgroundColor:  hoverIndex === props.category.id ? props.guideBook.settings.primaryColor: props.guideBook.settings.secondaryColor }}
                    ><span className={"first-letter:uppercase"}>{props.category.name}</span></a>
                </div>

                :
                <div  ref={setNodeRef}  {...attributes} {...listeners}  key={props.category.id} id={props.category.id}  className={"shadow rounded border border-gray-200 w-full bg-white p-5  h-full flex  min-h-[300px]  bottom-5 justify-between flex-col "}
                      style={{
                          backgroundImage: `url(${global.config.uriAPI+'/'+props.category.media_url})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          transform: CSS.Transform.toString(transform),
                          transition,
                          touchAction: "none",
                      }}
                >
                    {props.guideBook.hasDuplicate === true? null :
                        <>
                            <div className={"flex justify-between items-stretch flex-row"}>

                                <FontAwesomeIcon icon={faArrows} className={"bg-gray-100 rounded p-2 text-gray-600"}/>
                                <div className={"flex flex-row gap-5"}>
                                    {props.category.module_name === null ?
                                        <DeleteByPath path={"/app/category/remove/"+props.category.uuid} setter={props.setter}  uuid={props.category.uuid}/>:  <></>}
                                    <a href={"/app/guidebook/editcategory/"+props.guideBook.uuid+"/"+props.category.uuid} className={"bg-gray-100 rounded p-2 text-gray-600"}><FontAwesomeIcon icon={faEdit} /></a>
                                </div>
                            </div>

                        </>
                        }
                    <a href={"/app/guidebook/catSubList/"+props.guideBook.uuid+"/"+props.category.uuid} className={"rounded p-5 text-white font-bold flex justify-center items-center text-lg first-letter:uppercase cursor-pointer"}
                       onMouseEnter={() => setHoverIndex(props.category.id)} // Définit l'index survolé
                       onMouseLeave={() => setHoverIndex(null)}
                       style={{ backgroundColor:  hoverIndex === props.category.id ? props.guideBook.settings.primaryColor: props.guideBook.settings.secondaryColor }}
                    ><span className={"first-letter:uppercase"}>{props.category.name}</span></a>
                </div>



    );
}