import { Form, useParams } from "react-router-dom";
import Error from "../../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDays, faChevronLeft, faLocationDot} from "@fortawesome/free-solid-svg-icons";
import ModalEvent from "./modalEvent";
import Meta from "../../component/Meta";
import LangFront from "./langFront";
import LogoAnime from "../../asset/img/logoanime.gif";
import Event from "./event";
import NBar from "./nbar";

export default function Events(props) {
    const {t} = useTranslation();
    let { guideBookUuid } = useParams();
    const langId = localStorage.getItem('langId');
    const [langage, setLangage] = useState([]);
    const [loadData, setLoadData] = useState(false);
    const [guideBook, setGuideBook] = useState(null);
    const [events, setEvents] = useState([]);
    const [meta, setMeta] = useState({title: '', font: '', description: '', image: ''});
    const [loading, setLoading] = useState(false);
    const [eventsView, setEventsView] = useState([]);
    const [nbView , setNbView] = useState(7);
    useEffect(() => {
        fetch(global.config.uriAPI+'/langage')
            .then((response) => response.json())
            .then((data) => {
                setLangage(data);
            })
            .catch((err) => {
            });

        fetch(global.config.uriAPI+'/guidebook/'+guideBookUuid+"/"+langId, )
            .then((response) => response.json())
            .then((data) => {
                setGuideBook(data);


                const meta = {
                    title: data.name,
                    font: data.settings.mainFont,
                    description: data.name,
                    image: global.config.uriAPI+'/'+data.media.url
                }
                setMeta(meta);
                /*updateStyles.item[':hover']= {
                    backgroundColor: adjust(data.settings.navigationBarColor, -40)
                }*/
                fetch(global.config.uriAPI+'/guidebook/events/'+guideBookUuid+"/"+langId , )
                    .then((response) => response.json())
                    .then((data) => {
                        setLoadData(true);
                        setEvents(data)
                        setEventsView(data.slice(0, nbView));

                        //setTopicList([...topicList, data.subCategorys.topics]);

                    })
                    .catch((err) => {
                    });
            })
            .catch((err) => {
            });
    }, [langId])


    const handleScroll = () => {
        if (document.body.scrollHeight - 300 < window.scrollY + window.innerHeight) {
            setLoading(true);
        }
    };

    function debounce(func, delay) {
        let timeoutId;
        return function (...args) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    useEffect(() => {
        if (loading == true) {
            setNbView((nbView) => nbView + 7);
            setEventsView(events.slice(0, nbView));
            setLoading(false);
        }
    }, [loading]);

    // adding debounce to the eventListner
    window.addEventListener("scroll", debounce(handleScroll, 500));


    return (
        <div className={"min-h-screen"} style={{
            backgroundColor: "#F5F5F5",
        }}>
            {loadData ? <>
                <div  className={"container  max-w-screen-md mx-auto  flex flex-col gap-5"} >
                    <Meta meta={{
                        title: guideBook.name,
                        font: guideBook.settings.mainFont,
                        description: guideBook.name,
                        image: global.config.uriAPI+'/'+guideBook.media.url,
                        themeColor: guideBook.settings.secondaryColor
                    }} />
                    <header className={"flex flex-col justify-between  w-full min-h-[250px] rounded-b-2xl shadow-lg"}
                            style={{
                                backgroundImage:  `linear-gradient(
                             rgba(255, 255, 255, 0.2),
                            rgba(255, 255, 255, 0.2)
                        ), url(${global.config.uriAPI+'/'+guideBook.media.url})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}
                    >
                        <div className={"flex flex-row justify-between items-start"}>
                            <img src={"/themelogo/"+guideBook.settings.code+".svg"}  width={"10%"} className={"m-5"}/>
                            <LangFront langage={langage} settings={guideBook.settings} />
                        </div>

                    </header>
                    <div className={" flex justify-between items-center pr-5 pl-5"}>
                        <div className={"flex flex-col gap-2"}>
                            <div className={"flex flex-row gap-2"}>
                                <a href={document.referrer} className={"rounded text-sm p-1 w-5 flex justify-center items-center"}
                                   style = {{
                                       backgroundColor: guideBook.settings.secondaryColor,
                                       color: guideBook.settings.primaryColor
                                   }}
                                ><FontAwesomeIcon icon={faChevronLeft} /></a>
                                <h1 class={"text-xl text-gray-700 font-bold first-letter:uppercase oswald-font"}>{t('Liste des évènements')}</h1>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Event  calendars={eventsView} guideBook={guideBook} border={"shadow rounded border border-gray-200 w-full bg-white p-2 "}/>
                        {loading && <h1 className={"mt-5 text-gray-600 font-semibold"}>{t('Chargement...')}</h1>}
                    </div>
                </div>
                    <div className={"text-gray-600  container max-w-screen-md mx-auto p-5  justify-center items-center flex text-xs mb-20 gap-5"}>
                        <span> @{t('Copyright by Okurensio')}</span> <a href={"https://okurens.io"} target={"_blank"}>https://okurens.io</a>
                    </div>
                    <NBar styles={guideBook.settings} guideBook={guideBook}  active={"accueil"}/>
                </>
                :
                <img src={LogoAnime}
                     style={{
                         width: 80,
                         $height: 80
                     }}
                     className={"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"}/>
                }
                </div>
    )
}