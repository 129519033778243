import Error from "../../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../../asset/img/loginimg.png';
import Lang from '../../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faEdit,
    faHome,
    faCircleInfo, faLocationDot, faSearch, faAdd, faTrash, faImage
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {Form, useParams} from "react-router-dom";
import i18n from "../../../../component/i18n";
import  Meta  from "../../../../component/Meta";
import {FileInput, Label} from "flowbite-react";
import FontPicker from "react-fontpicker-ts";
import {TwitterPicker} from "react-color";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import MyCustomUploadAdapterPlugin from "../../../../component/PluginUploader";
import DeleteMedia from "../function/DeleteMedia";
import BgNew from "../../../../asset/img/bg-4.png";



export default function Tags(props) {
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';

    const { t } = useTranslation();



    const handleTagClick = (event) => {
        let tag = event.currentTarget.dataset.tag;
        if(props.tagsSelected.includes(tag)){
            props.setTagsSelected(props.tagsSelected.filter(item => item !== tag));
        }else{
            props.setTagsSelected([...props.tagsSelected, tag]);
        }
    }

    return (
        <>
            <div className={"mt-5"}>
                <label className={  "block text-sm mb-2 font-semibold text-gray-700"}>{t('Tags')}</label>
                <span className={"text-xs text-gray-500"}>{t('Choissiez un ou plusieurs tags pour permettre à vos voyageurs de mieux savoir à qui s\'adresse votre contenu')}</span>
            </div>
            <div class={"flex flex-row gap-2 mt-1"}>
                {props.tags.map((tag) =>

                    <div
                        onClick={handleTagClick}
                        data-tag={tag.code}
                        className={props.tagsSelected.includes(tag.code)?"rounded hover:bg-gray-200 hover:text-gray-600 p-2 flex flex-row gap-2 items-center  hover:cursor-pointer text-xs bg-cyan-200 text-cyan-600" : "rounded bg-gray-200 text-gray-600 p-2 flex flex-row gap-2 items-center hover:cursor-pointer text-xs hover:bg-cyan-200 hover:text-cyan-600"}>
                        <i class={tag.icon}/>
                        <span className={"text-gray-700 first-letter:uppercase"}>{tag.name}</span>
                    </div>
                )}
            </div>
        </>
    )
}