import { Form, useParams } from "react-router-dom";
import Error from "../../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDays, faLocationDot} from "@fortawesome/free-solid-svg-icons";
import ModalEvent from "./modalEvent";

export default function Event(props) {
    const { t } = useTranslation();

    const langId = localStorage.getItem('langId');

    const calendars = props.calendars;
    const guideBook = props.guideBook;
    const [eventSelected , setEventSelected] = useState(null);

    return (
        <>
            {calendars.length === 0 ?
                <div className={"text-gray-600 text-sm"}>{t('Aucun évènement aujourd\'hui')}</div>
                : null}
            {calendars.map((calendar, index) =>
            <div className={"flex flex-row gap-5 items-stretch w-full mt-2 "+props.border}>
                <div className={"rounded w-32"} style={{
                    backgroundImage: `url(${global.config.uriAPI+'/'+calendar.event.media.url})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}>
                </div>
                <div className={"flex flex-col gap-2 w-full"}>
                    <div className={"text-sm font-semibold text-gray-700 first-letter:uppercase"}>{calendar.event.name}</div>
                    {calendar.event.type === "topic" ?
                        <div className={"text-xs font-semibold text-gray-700 first-letter:uppercase"}>{calendar.event.topic.name}</div>
                        : <></>}
                    <span className={"text-sm text-gray-600 flex flex-row gap-2 items-center"}>
                                        <FontAwesomeIcon icon={faLocationDot} style={{
                                            color: guideBook.settings.secondaryColor
                                        }}/>

                                        <span>{calendar.event.address}</span>
                                    </span>
                    <span className={"text-xs text-gray-600 flex flex-row gap-1 items-center"}>
                                        <FontAwesomeIcon icon={faCalendarDays}  style={{
                                            color: guideBook.settings.secondaryColor
                                        }}/>
                                        <span className={"first-letter:uppercase"}>{t('De')}</span>
                                        <span className={"text-gray-700"}>{calendar.event.hourStart.replace(":","H")}</span>
                                        <span>{t('a')}</span>
                                        <span className={"text-gray-700"}>{calendar.event.hourEnd.replace(':','H')}</span>
                                        <span className={"text-gray-700"}>{t('le')} {calendar.dataDay.day} {calendar.dataDay.number} {calendar.dataDay.month} {calendar.dataDay.year}</span>
                                    </span>
                    <span className={"flex justify-end w-full"}>
                                        <a href={"#"} className={"text-sm "}
                                           style={{
                                               color: guideBook.settings.secondaryColor
                                           }}
                                           onClick={() => setEventSelected(calendar.event)}
                                        >{t('En savoir plus')}</a>
                                    </span>
                </div>

            </div>
            )}
            <ModalEvent event={eventSelected} guideBook={guideBook}/>
        </>

    )
}