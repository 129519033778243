import React from "react";
import Menu from './menu'

const Layout = ({ children }) => {

    return (
            <div class="relative min-h-screen md:flex" >
                <Menu />
                <div className={"flex-1 p-10 bg-gray-50 overflow-y-auto h-screen" } id={"content"} >
                    <main>{children}</main>
                </div>
            </div>
    )
}

export default Layout