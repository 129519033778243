import Error from "../../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../../asset/img/loginimg.png';
import Lang from '../../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faEdit,
    faHome,
    faCircleInfo, faLocationDot, faSearch, faAdd
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {Form, useLocation, useParams} from "react-router-dom";
import i18n from "../../../../component/i18n";
import  Meta  from "../../../../component/Meta";
import {FileInput, Label} from "flowbite-react";
import FontPicker from "react-fontpicker-ts";
import {TwitterPicker} from "react-color";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import MyCustomUploadAdapterPlugin from "../../../../component/PluginUploader";
import Tags from "./tags";


export default function TopicPlace(props) {
    const MAX_FILE_SIZE = 5120 // 5MB
    const lng = i18n.language;
    const [infoPlace, setInfoPlace] = useState(null);
    let { guideBookUuid, subCategoryUuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const [contentType, setContentType] = useState('content');
    const [button, setButton] = useState({disabled : '', loader: ''});


    const [loadData, setLoadData] = useState(false);
    const [summary, setSummary] = useState('');
    const [contentOwner, setContentOwner] = useState('');
    const { t } = useTranslation();
    const meta = {
        title: t('Ajouter un contenu')
    }
    const [inputs, setInputs] =  useState({ name: '', address: ''});

    const {
        register,
        handleSubmit,
        setError,
        watch,
        trigger,
        formState: { errors },
    } = useForm();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }
    const [tagsSelected, setTagsSelected] = useState([]);
    const [iconButton, setIconButton] = useState({icon : <FontAwesomeIcon icon={faAdd} />});
    const onSubmitContent = (data) => {

        if (infoPlace != null) {
            setButton(previousState => {
                return {...previousState, disabled: 'disabled'}
            });
            setIconButton(previousState => {
                return {...previousState, icon: <FontAwesomeIcon icon={faSpinner} spin/>}
            });

            const myHeaders = new Headers();
            //myHeaders.append("Content-Type", "application/json");
            myHeaders.append('Authorization', 'Bearer ' + token);
            //myHeaders.append('Content-Type', 'Multipart/form-data');

            let formData = new FormData()

            /* let fileLogo = document.querySelector('#logo').files[0];
             if (fileLogo) {
                 const fileSizeKiloBytesLogo = fileLogo.size / 1024
                 const extensionLogo = fileLogo.type;

                 if (['image/png', 'image/jpg', 'image/jpeg'].includes(extensionLogo) === false) {
                     setError('logo', { type: 'error', message: t('Le fichier doit être au format png, jpg ou jpeg') });
                     return;
                 }

                 if(fileSizeKiloBytesLogo > MAX_FILE_SIZE){
                     setError('logo', { type: 'size', message: t('Le fichier est trop volumineux') });
                     return
                 }
             }*/

            setLoadData(false);

            // formData.append('logo', fileLogo);
            formData.append('title', inputs.name);
            formData.append('content', summary);
            formData.append('contentOwner', contentOwner);
            formData.append("tags", tagsSelected)
            formData.append("guideBookUuid", guideBookUuid)


            let latAddress = '';
            let lngAddress = '';
            geocodeByAddress(infoPlace.label)
                .then(results => getLatLng(results[0]))
                .then(({lat, lng}) => {

                        latAddress = lat;
                        lngAddress = lng;

                        formData.append('longitude', lng);
                        formData.append('latitude', lat);
                        formData.append('address', infoPlace.label);
                        formData.append('icon', '');
                        formData.append('googleId', infoPlace.value.place_id);

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formData,
                            redirect: 'follow'
                        };

                        fetch(global.config.uriAPI + '/app/topicplace/create/' + subCategoryUuid, requestOptions)
                            .then((response) => response.json())
                            .then((data) => {

                                window.location.href = document.referrer;
                                return;
                            })
                            .catch((err) => {
                            });
                    }
                )

        }

    }


    const validLogo = () => {
        let fileData = document.querySelector('#logo').files[0];
        const extension = fileData.type;

        if (['image/png', 'image/jpg', 'image/jpeg'].includes(extension) === false) {
            setError('logo', { type: 'error', message: t('Le fichier doit être au format png, jpg ou jpeg') });
            return
        }
        setError('logo', null);
    }

    useEffect(() => {

        if (infoPlace != null) {
            console.log("iiii");
            const name = "name";

            const value = infoPlace.value.structured_formatting.main_text;
            setInputs(values => ({...values, [name]: value}))

            document.getElementById("name").val = value;

            //setInputs({name: value});
        }

    },[infoPlace])


    return (
        <Form action="" method="post" onSubmit={handleSubmit(onSubmitContent)} noValidate id="formCreate" className={"flex flex-col gap-5 mt-5"}>
            <h2 className={"block text-sm mb-2 text-gray-700 font-semibold"}>{t('Ajouter un lieu')} </h2>
            <div className="mt-3">
                <div className={"bg-blue-300 p-2 mt-2  mb-2 text-xs rounded"}><FontAwesomeIcon icon={faCircleInfo} /> {t('Il faut indiquer le nom du lieu et son adresse pour bien récupérer toute les informations')}</div>
                <label htmlFor="adresse" className={ errors.address ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2 text-gray-700 font-semibold"}>{t('L\'adresse / nom du lieu')}</label>
                <GooglePlacesAutocomplete apiKey={global.config.apiKeyGoogle}
                                          apiOptions={{ language: lng }}
                                          selectProps={{
                                              infoPlace,
                                              onChange: setInfoPlace
                                          }}
                />
                {errors.address && errors.address.type === "required" && (
                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                )}
            </div>
            <div className="mt-3 w-full">
                <label htmlFor="name" className={ errors.name ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2 text-gray-700 font-semibold"}>{t('Le nom du lieu')}</label>
                <input type="text" id="name"
                       name ="name"
                       aria-invalid={errors.name ? "true" : "false"}
                       defaultValue={inputs.name|| ""}
                       {...register("name", { onChange: handleChange, required: false})}
                       className= { errors.name ?
                           "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                           "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                       }
                       placeholder={t("Nom du lieu")}/>
                {errors.name && errors.name.type === "required" && (
                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                )}
            </div>
            <div className="mt-3">
                <label className={ errors.summary ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2 text-gray-700 font-semibold"}>{t('La description du lieu')}</label>
                <CKEditor
                    editor={ ClassicEditor }
                    config = {{
                        extraPlugins: [ MyCustomUploadAdapterPlugin ],
                        placeholder: t('Description'),

                    }}
                    type="text"
                    name="summary"
                    onChange={ ( event, editor ) => { setSummary(editor.getData())} }
                />
                {errors.summary && errors.summary.type === "required" && (
                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                )}
            </div>
            <div className="mt-3 bg-cyan-600 rounded p-2 ">
                <label className={  "block text-sm mb-2  text-white font-semibold"}>{t('Vos conseils / Astuces / Bon plans')}</label>
                <CKEditor
                    editor={ ClassicEditor }
                    config = {{
                        extraPlugins: [ MyCustomUploadAdapterPlugin ],
                        placeholder: t('Description'),

                    }}
                    type="text"
                    name="contentOwner"
                    onChange={ ( event, editor ) => { setContentOwner(editor.getData())} }
                />
            </div>
            <Tags tags={props.tags}  tagsSelected={tagsSelected} setTagsSelected={setTagsSelected}/>
            <button type="submit" disabled={button.disabled}
                    className="border-2 border-cyan-700 bg-cyan-500 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-cyan-700 font-semibold">
                {iconButton.icon}&nbsp;&nbsp; {t('Enregistrer')}
            </button>
        </Form>
    )

}