import i18n from "../../component/i18n";
import React,{Component}  from 'react';
import FrFlag from '../../asset/img/fr.jpg';
import EnFlag from '../../asset/img/en.jpg';
import {changeLanguage} from "i18next";
function LangFront(props) {
    const lng = i18n.language;

    const langId = localStorage.getItem('langId');
    const lngSelect = localStorage.getItem('lngSelect');
    /*if (lng !== lngSelect) {
        i18n.changeLanguage(lngSelect).then(r =>
            console.log(r)
        );
        const lng = i18n.language;
    }*/


    function onChangeLang (langStr, langId) {
        localStorage.setItem('lngSelect', langStr);
        i18n.changeLanguage(langStr).then(r =>
            console.log(r)
        );;
        localStorage.setItem('langId', langId);
    };

    if (localStorage.getItem('langId') === null) {
        localStorage.setItem('lngSelect', "fr");
        localStorage.setItem('langId', 1);
    }

    return (
        <div className={"flex gap-2 justify-start items-center p-5"}>
            {props.langage.map((langage) =>
                    <a href="#" onClick={() => onChangeLang(langage.lang, langage.id)} className={"" +
                        `${lng === langage.lang ? "border-2 rounded-full border-gray-300": "rounded-full hover:rounded-full hover:border-gray-300  hover:border-2"}`}

                    >
                        <img src={global.config.uriAPI+"/"+langage.flag} className={"w-4 h-4 rounded-full shadow-lg  m-1"} /></a>
                )
            }

        </div>
    )
}

export default LangFront;